import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { getWorklistTransitions, submitTask } from "./api";

export default function TakeAction({
  selectedRows,
  setSelectedRows,
  getDataCb,
}) {
  const [open, setOpen] = useState(false);
  const [transitions, setTransitions] = useState([]);
  const [selectedTransition, setSelectedTransition] = useState({});
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(
    async function () {
      setOpen(true);
      const { transitions } = await getWorklistTransitions(
        selectedRows.map((e) => e.taskStepId)
      );
      setTransitions(transitions);
    },

    [selectedRows]
  );
  const handleChoose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let transition = transitions[parseInt(e.currentTarget.dataset.i)];
    setSelectedTransition(transition);
  };

  const handleSubmitTasks = useCallback(
    async function () {
      setLoading(true);
      try {
        await submitTask(selectedRows, selectedTransition.id);
        setLoading(false);
        setOpen(false);
        setSelectedRows([]);
        getDataCb();
      } catch (err) {
        console.log(err);
        setLoading(false);
        setOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, selectedTransition]
  );

  return (
    <>
      <Grid item xs={2} justifyContent="flex-end">
        <Button
          color="secondary"
          variant="contained"
          onClick={(e) => handleClick()}
        >
          Take Action
        </Button>
        <Dialog
          classes={{ paper: "modal-content" }}
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title2"
          style={{ borderRadius: "6px", width: "85%", margin: "auto" }}
        >
          <DialogContent className="p-0">
            <div>
              <div className="text-center pb-5 pl-5 pr-5 pb-4 pt-4">
                <div className="card-header d-block bg-white pt-4 pb-4">
                  <div className="text-primary text-center h3 mb-5">
                    <p className="modal-title">
                      {/* fixMe: Localization */}
                      <strong> Choose your next action </strong>
                    </p>
                  </div>
                  <Grid container alignItems="center" justifyContent="center">
                    {transitions?.map((t, i) => (
                      <Grid item xs={6} key={i}>
                        <Button
                          className="btn btn-transition-none mr-2 mb-2"
                          size="large"
                          color="primary"
                          hidden={t.hidden}
                          style={{
                            boxShadow: "none",
                            border: "2px solid",
                            borderRadius: "5px",
                            width: "86%",
                            height: "41px",
                          }}
                          disableFocusRipple
                          disableRipple
                          variant={
                            selectedTransition.id === t.id
                              ? "contained"
                              : "outlined"
                          }
                          key={t.id}
                          data-i={i}
                          onClick={handleChoose}
                        >
                          {t?.style?.icon?.position === "right" && (
                            <FontAwesomeIcon
                              icon={["fa", t?.style?.icon?.name]}
                              className="ml-1"
                            />
                          )}
                          <b>{t.name}</b>
                          {t?.style?.icon?.position == "left" && (
                            <FontAwesomeIcon
                              icon={["fa", t?.style?.icon?.name]}
                              className="mr-1"
                            />
                          )}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                <Button
                  className="mx-1 px-5 py-2"
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ borderRadius: "5px" }}
                  onClick={handleSubmitTasks}
                  disabled={!selectedTransition.id}
                >
                  <span className="btn-wrapper--label ml-2 font-size">
                    Submit
                  </span>
                  <ClipLoader color={"var(--primary)"} loading={loading} />
                </Button>
              </div>
            </div>
            <div></div>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}
