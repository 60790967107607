import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AccordionSummary,
  Accordion,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { fetchSubmissions } from "weflow_configs/newIclaim/components/Inquiry/api";
import { LAYOUT_STRUCTURE } from "./constants";
import Table from "components/Table";

function OldSubmissionsAccordion(props) {
  const [data, setData] = useState([]);
  const [layoutStructure, setLayoutStructure] = useState(LAYOUT_STRUCTURE);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const getSubmissions = useCallback(
    async function (oldSubmissionId) {
      const include = [
        { association: "checklist" },
        { association: "patient" },
        { association: "breadwinner" },
      ];
      const filters = [{ field: "id", value: oldSubmissionId }];
      const { data } = await fetchSubmissions(include, limit, page, filters);
      setData(data);
    },
    [limit, page]
  );

  useEffect(() => {
    getSubmissions(props.task.submission.oldSubmissionId);
  }, [props.task.submission.oldSubmissionId, getSubmissions]);

  return (
    <div className="accordion mb-spacing-6-x2 w-100">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6">Old Submission</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: "d-block" }}>
          <Table
            className="mt-4 w-100"
            layoutStructure={layoutStructure}
            setLayoutStructure={setLayoutStructure}
            layoutData={data?.rows}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            tasksCount={data?.count || 0}
            page={page}
            propsFunctions={{}}
          ></Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

OldSubmissionsAccordion.propTypes = { task: PropTypes.any };

export default OldSubmissionsAccordion;
