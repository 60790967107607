import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import Info from "../Info";
import {
  getLoadMoreEvents,
  getOnchangeEvents,
  getOnInitInitialValue,
} from "utils/FormUtils/Events/EventsHelperFunctions";
import { connect } from "react-redux";

const Selection = ({
  input,
  formik,
  inputValue,
  loadMore,
  onValueChange,
  inputEvents,
  arrayIndexes,
}) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [clickOutSide, setClickOutSide] = useState(false);

  const findEventInput = (type) =>
    inputEvents.find((e) => {
      return (
        e.id === input.id &&
        (type ? e.events.some((ev) => ev.trigger === type) : true)
      );
    });

  const onLoadMore = async (e) => {
    e.stopPropagation();
    setOpen(true);
    const eventInput = findEventInput("onLoadMore");
    if (eventInput) await getLoadMoreEvents(eventInput);
  };
  const getInitialOptions = async () => {
    if (input?.itype === "enum" && input.options.length === 0) {
      input.options = [
        { label: "Yes", value: "true", id: "Yes" },
        { label: "No", value: "false", id: "No" },
      ];
    }
    const eventInput = findEventInput("onInit");
    setOptions(
      eventInput?.options.length ? eventInput.options : input?.options
    );
  };
  const onChange = (ev, value) => {
    if (onValueChange) {
      onValueChange(input, ev.target.value, ev);
    }
    formik.handleChange(ev, value);
    const eventInput = findEventInput("onChange");
    if (eventInput && ev.target.value)
      getOnchangeEvents(eventInput, formik, ev.target.value, arrayIndexes).then(
        () => {
          if (eventInput?.options.length) setOptions(eventInput?.options);
        }
      );
    if (ev.target.value) {
      setOpen(false);
    }
  };

  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickOutSide(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  useEffect(() => {
    getInitialOptions();
  }, []);

  // useEffect(() => {
  //   const eventInput = findEventInput("onInitInitialValues");

  //   if (eventInput)
  //     if (inputValue)
  //       getOnInitInitialValue(
  //         eventInput,
  //         formik,
  //         inputValue,
  //         arrayIndexes
  //       ).then(() => {
  //         if (eventInput?.options.length) setOptions(eventInput?.options);
  //       });
  // }, []);

  useEffect(() => {
    const eventInput = findEventInput();

    setOptions(
      eventInput?.options.length ? eventInput.options : input?.options
    );
  }, [input, inputEvents]);

  return (
    <>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel
          id="demo-simple-select-outlined-label"
          style={input.input_layout?.labelStyle}
        >
          {input.name}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          label={input?.name}
          className="input-border"
          name={input?.key}
          id="demo-simple-select-outlined"
          value={options?.length && inputValue ? inputValue : ""}
          open={open}
          onClose={() => clickOutSide && setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={(ev, value) => onChange(ev, value)}
          ref={ref}
          onBlur={formik.handleBlur}
          disabled={input?.disabled}
          style={{ width: "100%" }}
        >
          {options?.length &&
            options?.map((option, index) => {
              return (
                <MenuItem
                  key={option.id || index}
                  value={option.label || option.id}
                  style={option.disabled ? { pointerEvents: "all" } : {}}
                  disabled={option.disabled ? option.disabled : false}
                  onClick={(e) => {
                    if (option.disabled) {
                      e.stopPropagation();
                      e.defaultPrevented();
                      setOpen(true);
                    }
                  }}
                >
                  {option.disabled ? (
                    <Tooltip title={option.message} arrow placement="right">
                      <span disabled>{option.label || option.name}</span>
                    </Tooltip>
                  ) : (
                    <span>{option.label || option.name}</span>
                  )}
                </MenuItem>
              );
            })}
          {loadMore && input?.optionsParams?.count > options?.length && (
            <MenuItem value="" onClick={(e) => onLoadMore(e)} key="loadMore">
              <b>Load More</b>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      {input.input_layout.displaySelectedData && inputValue && (
        <Info value={inputValue} options={options} input={input} />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({ inputEvents: state.form.inputEvents });
export default connect(mapStateToProps)(Selection);
