/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Button, Card } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import "../../style.scss";
import StepInputs from "../StepInputs";
import { handleClick } from "../../utils";
import Assign from "../Assign";
import { getUsers } from "reducers/details/detailsAction";
import { getValue } from "utils/GetObjectValues";
import { connect } from "react-redux";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import { useHistory } from "react-router-dom";
const submissionInfoConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/submissionInfo`);
const SubmissionInfo = (props) => {
  const {
    task,
    partIndex,
    currentUser,
    takeActionFlag,
    getUsers,
    attachmentsUnderReview,
    getTaskDetails,
    getTimeline,
    uploadedAttachments,
    saveSubmissionsValuesLoader,
  } = props;

  const [stepInputFlag, setStepInputFlag] = useState(false);
  const [qrCode, setQrCode] = useState();
  const [componentSections, setComponentsSections] = useState([]);
  const history = useHistory();
  const qrCodeRef = useRef();
  const actionsProperties = {
    ...props,
    handleAssignClick: () => {
      props.setModalInfo({
        question: "Do you want to assign yourself  this request?",
        message: "If you assign yourself on it, you should review it",
      });
      props.setConfirmationModal(true);
    },
    openTakeActionModal: function () {
      props.openModal({ open: true });
    },
    openEditForm: function () {
      history.push(
        `/programs/${task.submission.form_id}/submissions/${task.submission.id}/edit`
      );
    },
    openSlotModal: function () {
      props.setSlotsModal(true);
    },
    formRedirect: () => {
      history.push(
        `/programs/${task.submission.workflow.id + 1}/${task.submission.id}`
      );
    },
    handleRedirect: () => {
      history.push(`/drugnames/${task.submission.id}/${task.id}`);
    },
    downloadQrCode: () => {
      qrCodeRef.current.click();
    },
    redirectToSoftFilesForm: () => {
      history.push(`/programs/2/${task.submission.id}`);
    },
  };

  useEffect(() => {
    if (task) {
      let filteredUploadedAttachments = Object.entries(uploadedAttachments);
      filteredUploadedAttachments = filteredUploadedAttachments.filter(
        ([key, value]) => (value ? !("document" in value) : false)
      );
      filteredUploadedAttachments = Object.fromEntries(
        filteredUploadedAttachments
      );
      let configs = submissionInfoConfig
        .config({
          task: {
            ...task,
            submission: {
              ...task.submission,
              attachments: task.submission.attachments?.filter(
                (attachment) => !attachment.document.isComment
              ),
            },
          },
          currentUser: currentUser,
          taskUsers: task?.users,
          takeActionFlag,
          attachmentsUnderReview,
          uploadedAttachments: filteredUploadedAttachments,
          stepInputFlag: stepInputFlag,
          saveSubmissionsValuesLoader,
        })
        .sections.map((section) => {
          section.actions = section.actions.map((action) => {
            if (action.constraints) {
              return {
                ...action,
                constraints: applyDetailsConstraints(action.constraints),
              };
            } else {
              return action;
            }
          });
          section.attributes = section.attributes.map((attribute) => {
            if (attribute.constraints) {
              return {
                ...attribute,
                constraints: applyDetailsConstraints(attribute.constraints),
              };
            } else {
              return attribute;
            }
          });
          return section;
        });
      setComponentsSections(configs);
    }
  }, [
    task,
    attachmentsUnderReview,
    takeActionFlag,
    stepInputFlag,
    uploadedAttachments,
    saveSubmissionsValuesLoader,
  ]);
  useEffect(() => {
    if (
      task?.task_status === "Pending" &&
      task?.submission.status === "Pending" &&
      task?.step?.assignees?.length > 0
    )
      getUsers({
        assignees: task.step.assignees,
        submission_id: task.submission.id,
      });

    if (task)
      setQrCode(
        task.submission.attachments.find(
          (attachment) => attachment.document.id === 117
        )
      );
  }, [task]);
  const actions = {
    assign: (action) => (
      <>
        <Assign
          action={action}
          getTaskDetails={() =>
            getTaskDetails({ submission_id: task?.submission.id })
          }
          getTimeline={() =>
            getTimeline({ submission_id: task?.submission.id })
          }
        />
      </>
    ),
  };
  return (
    <Card key={partIndex} className="card-box">
      {componentSections?.map((section) => (
        <Grid item xs={12} className="contentInfo" key={section.id}>
          <Grid container className="containerStyle p-2" spacing={2}>
            {/* basic Information attributes */}
            {task &&
              section?.attributes.map((column, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  md={column.style.gridSize}
                  style={{
                    display: column?.hasOwnProperty("constraints")
                      ? column.constraints.display
                        ? true
                        : "none"
                      : true,
                  }}
                >
                  <Grid className="listTitle">{column.name} </Grid>
                  <span
                    style={{ padding: "0 4px 1px 4px" }}
                    className={`listTitleDate ${
                      column.classes ? column.classes : ""
                    }`}
                  >
                    <b className={`${column?.contentClass}`}>
                      {getValue(task, column.key, column.type)}{" "}
                    </b>
                  </span>
                </Grid>
              ))}
            {<StepInputs task={task} setStepInputFlag={setStepInputFlag} />}

            {/* basic Information actions */}
            {section?.actions.map((action, index) => {
              let ButtonIcon = action.icon;
              return (
                action.constraints.display &&
                (action.type !== "component" ? (
                  <Grid
                    item
                    md={action.gridSize || 2}
                    xs={2}
                    key={index}
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      xs={3}
                      className={action.classes}
                      disabled={action.constraints.disable}
                      onClick={(e) => handleClick(e, action, actionsProperties)}
                    >
                      {action.label}
                      {action.icon && <ButtonIcon />}
                    </Button>
                    <a
                      ref={qrCodeRef}
                      className="d-none"
                      href={`${process.env.REACT_APP_SERVER_URL}attachment/download?id=${qrCode?.id}`}
                      download
                    />
                  </Grid>
                ) : (
                  <React.Fragment key={index}>
                    {actions[action.name](action)}
                  </React.Fragment>
                ))
              );
            })}
            {task?.customComponents?.map((comp) => (
              <Grid
                item
                md={2}
                xs={2}
                key={comp.url}
                style={{
                  paddingTop: "15px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  xs={3}
                  variant="contained"
                  className="assignButton"
                  onClick={() =>
                    history.push(
                      `/${comp.url}/${task.submission.id}/${task.id}`
                    )
                  }
                >
                  {comp.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Card>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    users: state.details.users,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
    uploadedAttachments: state.details.uploadedAttachments,
    saveSubmissionsValuesLoader: state.details.saveSubmissionsValuesLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(getUsers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionInfo);
