// material-ui
import { Grid, Typography, Container } from "@mui/material";

import {
  TextField,
  InputLabel,
  OutlinedInput,
  Button,
  FormControl,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import Tiles from "../../assets/images/tiles.svg";
import logoWhite from "../../assets/images/Asset_9.png";
import UHIALogoWhite from "../../assets/images/new_iclaim_newLogo2.png";
import IApproveLogo from "../../assets/images/iAgree.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./style.scss";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { login, setIsLoggedIn } from "reducers/auth";
import BlockUi from "react-block-ui";
import { SyncLoader } from "react-spinners";
import { connectSocket, send, receive } from "reducers/socket";
import { useParams, useLocation } from "react-router-dom";
import { useState } from "react";
// project imports
// import AuthWrapper2 from "./AuthWrapper2";
// import AuthLogin from "./AuthLogin";

// assets

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "white",
    textIndent: "10px",
  },
  sizeAvatar: {
    width: "300px",
    borderRadius: 0,
    height: "auto",
    margin: "auto",
  },
}));

const Login = ({ history, login, connectSocket, auth }) => {
  let { redirectUrl, loading } = auth;
  let [errorMessage, setErrorMessage] = useState("");
  let [blocking, setBlocking] = useState(false);
  const location = useLocation();
  const afterLoginRedirect = location.state?.from;

  const handleLogin = () => {
    if (redirectUrl && !loading) {
      history.push(redirectUrl);
    }
  };

  const { companyType } = useParams();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    //localization to do//////
    validationSchema: Yup.object({
      email: Yup.string().required("This field is required"),
      password: Yup.string()
        .min(1, "Password must be more than 1 characters")
        .required("This field is required"),
    }),
    onSubmit(values) {
      setBlocking(true);
      login({ ...values, company_type: companyType })
        .then((response) => {
          setBlocking(false);
          connectSocket();
          if (afterLoginRedirect) history.push(afterLoginRedirect);
        })
        .catch((error) => {
          console.log(error);
          setBlocking(false);
          setErrorMessage(error.response && error.response.data.message);
        });
    },
  });
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        maxWidth: "100vw",
        overflowX: "hidden",
        backgroundColor: { xs: "#0066CC", lg: "#fff" },
      }}
    >
      <Grid
        item
        container
        xs={12}
        sm={6}
        lg={6}
        pl={{ xs: 5, lg: 15 }}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: { xs: "100vh", lg: "30vh" },
          backgroundColor: { xs: "#fff", lg: "#fff" },
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={12}
          alignItems="center"
          spacing={1}
        >
          <Grid item mb={3}>
            <img alt="iApprove logo white" src={IApproveLogo} width={"40%"} />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} lg={12} container direction="column">
          <form
            onSubmit={formik.handleSubmit}
            sx={{ mr: "auto", minWidth: "50%" }}
          >
            <p className="text-danger ">{errorMessage ? errorMessage : ""}</p>
            <Grid item xs={12} sm={12} lg={12} container>
              <FormControl className="mb-3" style={{ width: "75%" }}>
                {/* <span className="label"> Username</span> */}
                <TextField
                  name="email"
                  id="email"
                  label="Username"
                  variant="outlined"
                  // type="email"
                  autoFocus={true}
                  className="InputStyle"
                  error={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  inputProps={{
                    className: classes.input,
                  }}
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger mt-1" align="left">
                    {formik.errors.email}
                  </div>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} container>
              <FormControl
                className="mt-2"
                style={{ width: "75%" }}
                variant="outlined"
              >
                {/* <span className="label"> Password</span> */}
                <InputLabel htmlFor="password">Password</InputLabel>

                <OutlinedInput
                  name="password"
                  id="password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  error={
                    formik.touched.password && formik.errors.password
                      ? true
                      : false
                  }
                  className="InputStyle"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                  inputProps={{
                    className: classes.input,
                    autoComplete: "off",
                  }}
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-danger mt-1" align="left">
                    {formik.errors.password}
                  </div>
                ) : null}
              </FormControl>
            </Grid>

            <Grid item container sx={{ mt: 3 }}>
              <Button
                // sx={{mx: "auto"}}
                className={`loginBtn`}
                type="submit"
                onClick={handleLogin}
              >
                {" Login "}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        lg={6}
        sx={{ display: { xs: "none", lg: "block" } }}
      >
        <Container
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#0066CC",
            backgroundImage: `url(${Tiles})`,
            backgroundSize: "cover",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item container direction="row" justifyContent="space-around">
              <Grid item container xs={8} lg={8} direction="column">
                <Typography
                  color="white"
                  align="right"
                  variant="h4"
                  sx={{ fontFamily: "Montserrat" }}
                >
                  المنصة الالكترونية للموافقات المسبقة
                </Typography>

                <Typography
                  color="white"
                  align="right"
                  variant="h4"
                  sx={{ fontFamily: "Montserrat" }}
                >
                  الهيئه العامة للتامين الصحي الشامل
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={4}
                lg={4}
                justifyContent="flex-end"
                pr={{ md: 5, lg: 5, xl: 10 }}
                sx={{ mt: -2 }}
              >
                <img
                  alt="UHIA logo white"
                  src={UHIALogoWhite}
                  width={"90%"}
                  height={"90%"}
                />
              </Grid>
            </Grid>

            <Typography
              color="white"
              align="right"
              variant="h6"
              p={{ md: 5, lg: 5, xl: 10 }}
            >
              هدفنا أن يكون لنا تأثير في تحسين صحة الشعب المصري بتأمين العلاج
              المناسب بجودة عالية وبشكل مستدام
            </Typography>
            <Grid
              item
              container
              xs={4}
              lg={4}
              pl={{ md: 10, lg: 10, xl: 15 }}
              justifyContent="flex-start"
            >
              <img
                alt="iApprove logo white"
                src={logoWhite}
                width={100}
                height={50}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

// export default Login;
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    emit: (eventName, message) => dispatch(send(eventName, message)),
    connectSocket: (eventName, message) =>
      dispatch(connectSocket(eventName, message)),
    setIsLoggedIn: (data) => dispatch(setIsLoggedIn(data)),
    receive: (eventName, message) => dispatch(receive(eventName, message)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
