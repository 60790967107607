import store from "../reducers/store";
import { setErrorPage, setErrorMessage } from "reducers/error/errorActions";

export const apiConfig = {
  // TODO : change baseUrl
  baseUrl: process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : `${window.location.protocol}//${window.location.host}/api/`,
  //                        http://localhost:3000/api/
  // should end with a slash
};

export const getUrl = (config) => {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, "");
  }
  return config.url;
};

export const handleError = (error) => {
  if (error.response) {
    if ([505].includes(error.response.status)) {
      let errorPage = {
        pathname: `/PageError${error.response.status}`,
      };
      store.dispatch(setErrorPage(errorPage));
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return errorPage;
    } else if (error.response.status === 401) {
      let errorPage = {
        pathname: "/login",
      };
      store.dispatch(setErrorPage(errorPage));
      localStorage.removeItem("user");
      store.dispatch({ type: "RESET_STORE", payload: null });
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return errorPage;
    }
    // Generic Internal Server Error
    else if (error.response.status === 500) {
      store.dispatch(setErrorMessage("Something went wrong! Error Code: 500"));
      return "Something went wrong! Error Code: 500";
    } else {
      store.dispatch(
        setErrorMessage(
          (typeof error.response.data.message === "object"
            ? error.response.data.message.message
            : error.response.data.message || error.response.statusText) ||
          "Something went wrong, but we can not tell what exactly."
        )
      );
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return error.response.data.message || error.response.statusText;
    }
  } else if (error.request) {
    store.dispatch(setErrorMessage("Problem with request"));
    return "Problem with request";
  } else {
    store.dispatch(setErrorMessage(error.message));
    return error.message;
  }
};
