export const documentContentConfig = {
  config: (mapper) => [
    {
      tabId: 2,
      sections: [
        {
          title: "",
          id: 1,
        },
      ],
    },
    {
      tabId: 3,
      sections: [
        {
          title: "",
          id: 1,
        },
      ],
    },
    {
      tabId: 4,
      sections: [
        {
          title: "",
          id: 1,
        },
      ],
    },
  ],
};
