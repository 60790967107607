import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import client from "api/apiAuth/guestClient";
import { Button, Grid } from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import _ from "lodash";

export default function WorkingDays({ id }) {
  const [initialValues, setInitialValues] = useState({});
  const [allSteps, setAllSteps] = useState([]);
  const [validationSchema, setValidationSchema] = useState({});
  const [submissionType, setSubmissionType] = useState();
  let formik;
  formik = useFormik({
    validationSchema: yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  const getAllSteps = async () => {
    const res = await client.get("/settings/getSettingSteps", {
      params: {
        workflow_id: id,
      },
    });

    setAllSteps(res.data.steps);
  };
  useEffect(() => {
    getAllSteps();
  }, []);


  return (
    <>
      <FormikProvider value={formik}>
        <h6
          style={{
            paddingBottom: 15,
            textShadow: "2px 2px 8px rgba(0,0,0,.2)",
            color: "#003655",
          }}
        >
          Working Days :
        </h6>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              className="mr-5"
              // onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormikProvider>
    </>
  );
}
