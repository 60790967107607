import client from "api/apiAuth/guestClient";

export const getTransitions = async function (step_id, submission, task_id) {
  const { data } = await client.get("/tasks/stepTransitions", {
    params: {
      step_id,
      submission_id: submission?.id,
      task_id,
    },
  });
  return data;
};

export const submitTask = async function (
  transition_id,
  submission_id,
  form_id,
  taskId,
  comment,
  submission_edit,
  meta,
  assignedUser
) {
  await client.post("workflow/submittask", {
    transition_id,
    submission_id,
    form_id,
    tasks: [
      {
        taskId,
        comment: comment,
        submission_edit,
      },
    ],
    meta,
    assignedUser,
    assignTasksBeforeSubmit: false,
  });
};

export const getTargetStepUsers = async function (stepId, submissionId) {
  const { data } = await client.get("workflow/getStepUsers", {
    params: { stepId, submissionId },
  });
  return data;
};
