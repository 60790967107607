import {
  UPLOAD_FILE,
  SAVE_VALUES,
  START_ENGINE,
  ENABLE_FORM_SUBMIT_BUTTON,
  SAVE_FILES,
  GET_FORM_LAYOUT,
  SET_REQUIRED_DOCUMENTS,
  SAVE_DRAFT,
  SET_SUBMISSION,
  GET_DRAFT,
  UPDATE_SUBMISSION,
  SET_FORM_NAME,
  HANDLE_DRAFT_ASSIGNMENT,
  SET_EVENT_CALLED,
  SET_EVENTS_INPUTS,
  GET_SUBMISSION,
  SUBMIT_UPDATE_TASK,
  CHECKLIST_INPUTS,
  SET_DEPENDENT_INPUT_OPTIONS,
  ADD_TO_INITIAL_VALUES,
  SET_VIEW_SELECT_INPUTS,
  RENEW_REQUEST,
  RESET_FORM,
} from "./formActionTypes";
import client from "api/apiAuth/guestClient";
import { DELETE_FILE } from ".";
// import { VALIDATION_SCHEMA, SET_EVENT_CALLED } from ".";

export const uploadFile = function (file) {
  let formData = new FormData();
  formData.append("avatar", file.data);
  return {
    type: UPLOAD_FILE,
    payload: client.post("attachment/uploadFile", formData),
    meta: {
      document_id: file.document_id,
      name: file.name,
      contact_id: file.contact_id,
      original_file_name: file.original_file_name,
      section: file.section,
      sectionName: file.sectionName,
      type: file.type,
      preview: file.preview,
    },
  };
};

// export const setSubmission = function (submissionDetails) {
//   return {
//     type: SET_SUBMISSION,
//     payload: submissionDetails,
//   };
// };

export const saveValues = function (values) {
  return {
    type: SAVE_VALUES,
    payload: values,
  };
};

export const startEngine = function (submission) {
  return {
    type: START_ENGINE,
    payload: client.post("workflow/startEngine", submission),
  };
};
export const submitUpdateRequestTask = function (submission) {
  return {
    type: SUBMIT_UPDATE_TASK,
    payload: client.post("workflow/submitUpdateRequestTask", submission),
  };
};

export const enableSubmitButton = function () {
  return {
    type: ENABLE_FORM_SUBMIT_BUTTON,
  };
};

export const saveFiles = function (name, file) {
  return {
    type: SAVE_FILES,
    payload: { name, file },
  };
};

export const getFormLayout = function (params) {
  return {
    type: GET_FORM_LAYOUT,
    payload: client.get("app/FormLayout", {
      params,
    }),
  };
};

export const setRequiredDocuments = function (document_id) {
  return {
    type: SET_REQUIRED_DOCUMENTS,
    payload: document_id,
  };
};

export const saveDraft = function (submission) {
  return {
    type: SAVE_DRAFT,
    payload: client.post("submission/savedraft", submission),
  };
};

export const getDraft = function (params) {
  return {
    type: GET_DRAFT,
    payload: client.get("submission/getdraft", { params }),
  };
};

export const updateSubmission = function (submission) {
  return {
    type: UPDATE_SUBMISSION,
    payload: client.put(`/submission/updateSubmission`, submission),
  };
};
export const setFormName = function (name) {
  return {
    type: SET_FORM_NAME,
    payload: name,
  };
};

export const handleDraftAssignment = function (params, user_id) {
  return {
    type: HANDLE_DRAFT_ASSIGNMENT,
    payload: client.post(`/submission/handleDraftAssignment`, user_id, {
      params,
    }),
  };
};

export const setEventCalled = function (event_id, input_id, section_id) {
  return {
    type: SET_EVENT_CALLED,
    payload: { event_id, input_id, section_id },
  };
};

export const deleteFile = function (avatarFd) {
  return {
    type: DELETE_FILE,
    payload: client.post("/attachment/deleteUnlinkedAttachment", { avatarFd }),
  };
};

export const setEventsInputs = function (input, index) {
  return { type: SET_EVENTS_INPUTS, payload: { input, index } };
};

export const setViewSelectInputs = function (input, index) {
  return { type: SET_VIEW_SELECT_INPUTS, payload: { input, index } };
};

export const getSubmission = function (params, isRenew) {
  return {
    type: GET_SUBMISSION,
    payload: client.get("submission/getSubmission", { params }),
    meta: { isRenew },
  };
};

export const getChecklistInputs = function (attributes, sectionId) {
  return (dispatch) => {
    // return the promise so you can chain on it
    return client
      .get("app/checklistInputs", {
        params: attributes,
      })
      .then(function (res) {
        dispatch({
          type: CHECKLIST_INPUTS,
          payload: { inputs: res.data.inputs, sectionId },
        });
      });
  };
};

export const setDependencyInputOptions = function (attributes, sectionId) {
  return async (dispatch) => {
    // return the promise so you can chain on it
    return client
      .post("app/setChecklistInputOptions", attributes)
      .then(function (res) {
        dispatch({
          type: SET_DEPENDENT_INPUT_OPTIONS,
          payload: {
            dependency: res.data.input.dependency,
            options: res.data.input.options,
            sectionId,
            dependentInput: attributes.dependentInput,
          },
        });
      });
  };
};

export const addToInitialValues = function (key, value) {
  return { type: ADD_TO_INITIAL_VALUES, payload: { key, value } };
};

export const resetForm = function () {
  return { type: RESET_FORM };
};
