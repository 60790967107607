import React, { useState, useEffect } from "react";
import TableTabs from "../../components/TableTabs";
import { Grid } from "@material-ui/core";
import Tables13 from "../../components/Table";
import clsx from "clsx";
import SpinnerLoader from "../../components/SpinnerLoader";

function TableWithTabsAndFilters({
  menus,
  totalCount,
  layoutStructure,
  setLayoutStructure,
  layoutData,
  setLayoutData,
  filterColumns,
  getTableData,
  currentTab,
  setCurrentTab,
  redirectKey,
  formId,
}) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState({ type: "", order: "none", orderBy: "" });

  const [submitTaskLoader, setSubmitTaskLoader] = useState(false);
  // useEffect(() => {
  //   setPage(1);
  // }, [filters, limit, sort]);
  useEffect(() => {
    getTableData({ filters, page, limit, sort, currentTab }).catch((err) => {
      console.log("err:", err);
    });
  }, [filters, page, limit, sort, currentTab]);
  return (
    <Grid container>
      <Grid item lg={12} md={12} xs={12} className="mt-1">
        <TableTabs
          setFilters={setFilters}
          setSort={setSort}
          setCurrentTab={setCurrentTab}
          setLayoutData={setLayoutData}
          currentTab={currentTab}
          menus={menus}
        />
        {submitTaskLoader ? (
          <Grid item lg={12} md={12} xs={12}>
            <SpinnerLoader style={{ height: "300px" }} />
            <div style={{ textAlign: "center" }}>
              <small>Please wait</small>
            </div>
          </Grid>
        ) : (
          <div
            className={clsx("tab-item-wrapper", {
              active: true,
            })}
            style={{ overflow: "hidden", borderRadius: "0 20px 0 0" }}
            index={0}
          >
            <Tables13
              className="mt-4"
              layoutStructure={layoutStructure}
              setLayoutStructure={setLayoutStructure}
              layoutData={layoutData}
              currentTab={currentTab}
              setPage={setPage}
              setLimit={setLimit}
              limit={limit}
              setSort={setSort}
              tasksCount={totalCount}
              filters={filters}
              setFilters={setFilters}
              setSubmitTaskLoader={setSubmitTaskLoader}
              page={page}
              filterColumns={filterColumns}
              redirectKey={redirectKey}
              formId={formId}
              getDataCb={() =>
                getTableData({ filters, page, limit, sort, currentTab })
              }
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default TableWithTabsAndFilters;
