import DocumentContent from "pages/EdaDetails/components/DocumentContent";
import SectionContent from "pages/EdaDetails/components/SectionContent";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";

const config = (mapper) => {
  return {
    actions: [
      {
        gridSize: 12,
        label: "Save",
        icon: InsertDriveFileOutlinedIcon,
        classes: "save-btn mr-4 float-right",
        tabs: [1, 2, 3, 4],
        onClick: {
          functionName: "handleSubmit",
        },
        constraints: {
          display: {
            conditions: [
              {
                key: "name",
                checkValue: "Hospital",
                operator: "some",
                functionName: "applyArrayMethods",
                actualValue: mapper.currentUser.roles,
              },
              {
                checkValue: true,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.step.edit_form,
              },
              {
                checkValue: true,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.is_assigned,
              },
              {
                key: "id",
                checkValue: mapper.currentUser?.id,
                operator: "find",
                functionName: "applyArrayMethods",
                actualValue: mapper.taskUsers,
              },

              {
                key: "name",
                checkValue: [
                  "Pre approval reviewer",
                  "Patient Affairs manager",
                  "Beneficiary Affairs manger",
                  "C A B D Reviewer",
                  "Committe Member",
                  "Administrator of C A B D",
                  "Head of C A B D (Signer)",
                ],
                operator: "compare2Arrays",
                functionName: "applyArrayMethods",
                actualValue: mapper.currentUser.roles,
              },
            ],
            conditionsEquation:
              "($0 && $1 && $2 && $3)||($2 && $4 && $3)||($1 && $2 && $3)",
          },
        },
      },
    ],
    tabs: [
      {
        title: "Patient Info",
        tabContent: ["Attributes"],
        component: SectionContent,
        underRevision: false,
        constraints: {
          display: {
            conditions: [
              {
                checkValue: 3,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.submission.form_id,
              },
            ],
            conditionsEquation: "!$0 ",
          },
        },
        id: 1,
      },
      {
        title: "Checklist",
        tabContent: ["Documents"],
        type: "ConditionalForm",
        underRevision: true,
        id: 2,
        component: DocumentContent,
        constraints: {
          display: {
            conditions: [
              {
                checkValue: 3,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.submission.form_id,
              },
            ],
            conditionsEquation: "!$0 ",
          },
        },
      },

      {
        component: DocumentContent,
        id: 3,
        title: "Extra Attachments",
        tabContent: ["Documents"],
        underRevision: false,
        constraints: {
          display: {
            conditions: [
              {
                checkValue: 3,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.submission.form_id,
              },
            ],
            conditionsEquation: "!$0",
          },
        },
      },

      {
        component: DocumentContent,
        id: 4,
        title: "UHIA Attachments",
        tabContent: ["Documents"],
        underRevision: false,
        constraints: {
          display: {
            conditions: [
              {
                checkValue: 3,
                operator: "equal",
                functionName: "applyMathOperators",
                actualValue: mapper.task?.submission.form_id,
              },
            ],
            conditionsEquation: "!$0",
          },
        },
      },
    ],
  };
};
export { config as detailsTabsConfig };
