import React from "react";
import imag from "../../assets/images/Checklist.jpg";
import { Dialog, Button } from "@material-ui/core";

export default function ConfirmModal({ modal, setModal, info, callBack }) {
  const toggle = () => setModal(!modal);
  return (
    <Dialog
      open={modal}
      onClose={toggle}
      classes={{ paper: "shadow-sm-dark rounded" }}
    >
      <div className="text-center" style={{ padding: "2.5rem" }}>
        {/* <div className="d-inline-flex justify-content-center p-0">
          <img
            src={imag}
            alt="...."
            style={{ width: "200px", height: "130px" }}
          />
        </div> */}
        <h4 className="font-weight-bold mt-4">{info.question}</h4>
        <p className="mb-0 text-black-50">{info.message}</p>
        <div className="pt-4">
          <Button
            onClick={() => {
              callBack();
              toggle();
            }}
            style={{ marginRight: "20px" }}
            className="btn btn-primary "
          >
            Yes
          </Button>
          <Button
            onClick={toggle}
            className="btn-secondary"
            style={{ border: " 1px solid #0066cc" }}
          >
            <span className="btn-wrapper--label">No</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
