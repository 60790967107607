import { useMemo, useState } from "react";
import {
  InputLabel,
  Checkbox,
  ListItem,
  Grid,
  ListItemText,
  List,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { getValue } from "utils/GetObjectValues";

const days = [
  "Saturaday",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];
const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function SubmissionDate({ data, formik }) {
  const [targetDay, setTargetDay] = useState(days[0]);
  const [targetMonth, setTargetMonth] = useState("January");
  const [finalValues, setFinalValues] = useState({});

  const handleDayChange = (value) => {
    let values = { ...finalValues };
    values[targetDay] = value;
    setFinalValues(values);
  };

  const handleMonthChange = (value) => {
    let values = { ...finalValues };
    values[targetMonth] = value;
    setFinalValues(values);
  };

  const getContent = useMemo(() => {
    let content;
    let dateName;
    let limitName;
    switch (data.period) {
      case "daily":
        dateName = "limitation.day";
        limitName = "limitation.limit";
        // content = (
        //   <Grid item xs={12}>
        //     <div>
        //       <FormControl fullWidth>
        //         <InputLabel id="demo-simple-select-outlined-label">
        //           Days
        //         </InputLabel>

        //         <Select
        //           required
        //           labelId="demo-simple-select-outlined-label"
        //           id="demo-simple-select-outlined"
        //           label="Days"
        //           name={dateName}
        //           value={getValue(formik.values, dateName)}
        //           onBlur={(e) => {
        //             formik.handleBlur(e);
        //           }}
        //           onChange={(e) => {
        //             formik.handleChange(e);

        //             setTargetDay(e.target.value);
        //           }}
        //         >
        //           {days.map((day) => (
        //             <MenuItem value={day}>{day}</MenuItem>
        //           ))}
        //         </Select>
        //       </FormControl>
        //       <FormControl fullWidth style={{ marginTop: 8 }}>
        //         <TextField
        //           required
        //           size="small"
        //           id="outlined-basic"
        //           label="limit/day"
        //           variant="outlined"
        //           type={"number"}
        //           name={limitName}
        //           value={getValue(formik.values, limitName)}
        //           onChange={(e) => {
        //             formik.handleChange(e);

        //             handleDayChange(e.target.value);
        //           }}
        //         />
        //       </FormControl>
        //     </div>
        //   </Grid>
        // );
        content = (
          <Grid item md={12}>
            <List className="pt-1 ml-1">
              {days.map((dayRow, index) => {
                return (
                  <Grid container key={index}>
                    <Grid item md={12}>
                      <ListItem className="pl-0 pt-0">
                        <Grid item container md={3}>
                          <Checkbox
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            className="p-0"
                            checked={
                              eval(
                                getValue(
                                  formik.values,
                                  `submissionLimit.${index}.checked`
                                )
                              ) || false
                            }
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            name={`submissionLimit.${index}.checked`}
                            onChange={(ev, value) => {
                              formik.handleChange(ev, value);
                              handleDayChange(index, value);
                            }}
                            style={{
                              color: "#181E4B",
                              transform: "scale(.7)",
                            }}
                          />
                          <ListItemText
                            primary={
                              <b
                                className={
                                  eval(
                                    getValue(
                                      formik.values,
                                      `submissionLimit.${index}.checked`
                                    )
                                  )
                                    ? "checked"
                                    : "unChecked"
                                }
                                style={{ fontSize: "18px" }}
                              >
                                {dayRow}
                              </b>
                            }
                          />
                        </Grid>
                        <Grid item container md={8}>
                          <Grid item md={5}>
                            <span
                              htmlFor="from"
                              className="mr-2 "
                              style={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#D1D1D1",
                              }}
                            >
                              Limitation
                            </span>
                            <TextField
                              type="number"
                              placeholder="limit"
                              name={dayRow}
                              value={
                                getValue(formik.values, dayRow) == null
                                  ? ""
                                  : getValue(formik.values, dayRow)
                              }
                              disabled={
                                getValue(
                                  formik.values,
                                  `submissionLimit.${index}.checked`
                                ) === "false" ||
                                getValue(
                                  formik.values,
                                  `submissionLimit.${index}.checked`
                                ) == undefined
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                },
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                              }}
                              onChange={(ev, value) => {
                                formik.handleChange(ev, value);
                                handleDayChange(index, value);
                              }}
                              style={{ width: "133px" }}
                              size="small"
                              className="mb-0"
                              variant="outlined"
                            ></TextField>

                            {getValue(formik.touched, dayRow) && (
                              <div
                                className="text-danger"
                                style={{ fontSize: "14px" }}
                              >
                                {getValue(formik.errors, dayRow)}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>
                  </Grid>
                );
              })}
            </List>
          </Grid>
        );
        return content;
        break;
      case "everyday":
        limitName = "limit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/day"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setFinalValues({ count: e.target.value });
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
        break;
      case "everymonth":
        limitName = "limit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/month"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setFinalValues({ count: e.target.value });
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
        break;
      case "everyweek":
        limitName = "limit";
        content = (
          <Grid item xs={12} sm={6}>
            <div>
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  id="outlined-basic"
                  label="limit/week"
                  variant="outlined"
                  type={"number"}
                  name={limitName}
                  value={getValue(formik.values, limitName)}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setFinalValues({ count: e.target.value });
                  }}
                />
              </FormControl>
            </div>
          </Grid>
        );
        return content;
        break;
    }
  }, [targetDay, targetMonth, formik]);
  return <>{getContent}</>;
}
