import SubmissionLimit from "./components/SubmissionLimit";
import WorkingDays from "./components/WorkingDays";
import CustomSettings from "./components/CustomSettings";

export default (props) => {
  return {
    WorkingDays: <WorkingDays {...props} />,
    SubmissionLimit: <SubmissionLimit {...props} />,
    CustomSettings: <CustomSettings {...props} />,
  };
};
