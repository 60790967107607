import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../../style.scss";
import { setAllAttachments, setAttachmentReviewed } from "reducers/details";
import ViewFile from "../ViewFile";
import Document from "../Document";
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import { Collapse, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getValue } from "utils/GetObjectValues";

const documentsConfig = require(`../../../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documents`);
const Documents = (props) => {
  let {
    attachmentsUnderReview,
    setAttachmentReviewed,
    allAttachments,
    setAllAttachments,
    selectedTab,
    sectionId,
    initialValues,
    setInitialValues,
    schemaInputs,
    setSchemaInputs,
    formik,
    task,
  } = props;
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [notification, setNotification] = useState(false);

  let documents = documentsConfig
    .config({})
    .find(
      (sectionContent) =>
        sectionContent.tabId === selectedTab.id &&
        sectionContent.sectionId === sectionId
    );
  useEffect(() => {
    if (allAttachments) {
      let filteredAttachments = [];
      let clonedAttachments = _.cloneDeep(allAttachments);
      clonedAttachments.forEach((attachment) => {
        let foundAttachment = documents.attachments.find(
          (configAttachment) =>
            configAttachment.equivalent_key ===
              attachment.document.equivalent_key &&
            !attachment.submision_modification_id
        );
        if (foundAttachment) {
          filteredAttachments.push({
            ...attachment,
            underRevision: foundAttachment.underRevision,
            reviewers: foundAttachment.reviewers,
            passWithRejection: foundAttachment.passWithRejection,
            uploadedBy: foundAttachment.uploadedBy,
            info: foundAttachment.info,
          });
        }
      });
      setNotification(filteredAttachments.length ? false : true);
      setAttachments(filteredAttachments || []);
    }
  }, [selectedTab, allAttachments]);
  const getAttachments = () => {
    return (
      <>
        {attachments.length == 0 ? (
          <Collapse in={notification} style={{ width: "100%" }}>
            <Alert
              className="my-4"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setNotification(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="warning"
            >
              <h5> Files Alert</h5>
              <b>There are no files yet</b>
            </Alert>
          </Collapse>
        ) : null}
        {attachments?.map((attachment, index) => {
          return (
            <React.Fragment key={index}>
              <Document
                attachment={attachment}
                setFile={setFile}
                setOpen={setOpen}
                allAttachments={allAttachments}
                setAllAttachments={setAllAttachments}
                attachmentsUnderReview={attachmentsUnderReview}
                setAttachmentReviewed={setAttachmentReviewed}
                selectedTab={selectedTab}
                sectionId={documents.sectionId}
                props={props}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                schemaInputs={schemaInputs}
                setSchemaInputs={setSchemaInputs}
                formik={formik}
                submission={task.submission}
              />
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={2}
                columns={12}
                className="mb-2"
              >
                {attachment.info &&
                  attachment.info.map((info, index) => {
                    let value = getValue(attachment, info.equivalent_key);
                    return (
                      <Grid xs={6} container key={index}>
                        <Grid
                          item
                          xs={info.style.gridSize}
                          className="title-label"
                        >
                          {info.label}
                        </Grid>
                        <Grid
                          item
                          xs={12 - info.style.gridSize}
                          className="data"
                        >
                          {value}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </React.Fragment>
          );
        })}
      </>
    );
  };
  return (
    <>
      <ViewFile file={file} open={open} setOpen={setOpen} />
      {getAttachments()}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    allAttachments: state.details.allAttachments,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAllAttachments: (attachments) =>
      dispatch(setAllAttachments(attachments)),
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
