import { useState } from "react";
import { AssignForm } from "pages/EdaDetails/components/Assign";
import { getTargetStepUsers } from "../api";
import { useCallback } from "react";
import { useEffect } from "react";

function Assign({ transition, submissionId, selectedUser, setSelectedUser }) {
  const [users, setUsers] = useState([]);

  const getAssignees = useCallback(
    async function () {
      const users = await getTargetStepUsers(
        transition.target_step_id,
        submissionId
      );
      setUsers(users);
    },
    [submissionId, transition.target_step_id]
  );

  useEffect(() => {
    getAssignees().catch(console.error);
  }, [getAssignees]);

  return (
    <div>
      <AssignForm
        users={users}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
    </div>
  );
}

export default Assign;
