import React, { useEffect, useState } from 'react'
import {
    TableCell,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import { useSelector } from "react-redux";
const Header = ({entity}) => {
  const [headers, setHeaders] = useState([]);
  const listData = useSelector((state) => state.layout?.columns)
  const show = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.list))
  useEffect(() => {
    if(listData){
    setHeaders(listData?.Admin?.filter(hd=>hd[entity]).flatMap((header)=> header[entity]?.Columns?.map(head => head.name)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listData])
    return (
    <TableHead>
      <TableRow>
      {headers?.flatMap( (header,index) =>(
        show[index]&&
      <TableCell 
        key = {index}
        className="bg-color-secondary text-white pt-2"
        style={{ textAlign: "center" }}
      >
        {header}
      </TableCell>
      ))}
    </TableRow>
  </TableHead>
  )
}

export default Header