import AutorenewIcon from "@material-ui/icons/Autorenew";
export const NATIONAL_ID_REGEX =
  /(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d{5}$|(٢|٣)[٠-٩][٠-٩][٠-١][٠-٩][٠-٣][٠-٩](٠١|٠٢|٠٣|٠٤|١١|١٢|١٣|١٤|١٥|١٦|١٧|١٨|١٩|٢١|٢٢|٢٣|٢٤|٢٥|٢٦|٢٧|٢٨|٢٩|٣١|٣٢|٣٣|٣٤|٣٥|٨٨)[٠-٩]{5}$/;
export const FILE_CODE_REGEX = /^(D|S)[\-]\d+$/;
export const LAYOUT_STRUCTURE = {
  0: {
    Columns: {
      shared: [
        {
          name: "ID",
          key: "file_code",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 2,
        },
        {
          name: "Service",
          key: "service.name",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 3,
        },

        {
          name: "Patient",
          key: "patient.name",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 5,
        },
        {
          name: "Application date",
          key: "submittedAt",
          itype: "date",
          inputLayout: { type: "date" },
          filter: true,
          sorting: true,
          order: 6,
        },
        {
          name: "Status",
          key: "status",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 7,
        },
        {
          name: "Type",
          key: "type",
          itype: "string",
          inputLayout: { type: "text" },
          order: 8,
        },
      ],
      Hospital: [],
      "Pre approval reviewer": [],
      "Patient Affairs manager": [],
      "Beneficiary Affairs manger": [],
      "C A B D Reviewer": [],
      "Committe Member": [],
      "Administrator of C A B D": [],
      "Head of C A B D (Signer)": [],
    },
    Filters: [
      {
        field: "$Submission.form_id$",
        value: 1,
      },
    ],
    name: "طلب خدمة",
  },
  1: {
    Columns: {
      shared: [
        {
          name: "ID",
          key: "file_code",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 2,
        },
        {
          name: "Diagnosis",
          key: "diagnosis.name",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 3,
        },
        {
          name: "Drug",
          key: "drug.name",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 4,
        },

        {
          name: "Patient",
          key: "patient.name",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 5,
        },
        {
          name: "Application date",
          key: "submittedAt",
          itype: "date",
          inputLayout: { type: "date" },
          filter: true,
          sorting: true,
          order: 6,
        },
        {
          name: "Status",
          key: "status",
          itype: "string",
          inputLayout: { type: "text" },
          filter: true,
          sorting: true,
          order: 7,
        },
        {
          name: "Type",
          key: "type",
          itype: "string",
          inputLayout: { type: "text" },
          order: 8,
        },
      ],
      Hospital: [
        {
          name: "Renew Request",
          inputLayout: { type: "button" },
          icon: AutorenewIcon,
          style: { position: "sticky", left: 0, zIndex: 1000 },
          function: "handleRenewRequest",
          order: 1,
          key: "renewRequest",
          constraints: (row) => ({
            disable: {
              conditions: [
                {
                  checkValue: "Approve",
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: row?.status,
                },
                {
                  checkValue: 2,
                  operator: "equal",
                  functionName: "applyMathOperators",
                  actualValue: row?.form_id,
                },
              ],
              conditionsEquation: "!$0 ||!$1 ",
            },
          }),
        },
      ],
      "Pre approval reviewer": [],
      "Patient Affairs manager": [],
      "Beneficiary Affairs manger": [],
      "C A B D Reviewer": [],
      "Committe Member": [],
      "Administrator of C A B D": [],
      "Head of C A B D (Signer)": [],
    },
    Filters: [
      {
        field: "$Submission.form_id$",
        value: 2,
      },
    ],
    name: "طلب دواء",
  },
};
