import React from "react";

import { Grid } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

const {
  infoText,
  primaryTitle,
  secondaryTitle,
  loginOptions,
  logo,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/pages/landing`);

const Landing = ({ location }) => {
  const history = useHistory();

  const originalPath = location.state?.from;

  const goToLogin = (option) => {
    history.push({ pathname: option.loginPath, state: { from: originalPath } });
  };

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        lg={6}
        md={12}
        className={`${styles.container__textblock} d-flex flex-column align-items-center`}
      >
        <div
          className={`${styles.container__textblock__logoblock} d-flex flex-column justify-content-center p-lg-5`}
        >
          <img src={logo} className={styles.container__textblock__logo} />
        </div>

        <div
          className={`${styles.container__textblock__text} d-flex flex-column align-items-center`}
        >
          <h2 className={`w-50 ${styles.container__title1}`}>{primaryTitle}</h2>
          <h2 className={styles.container__title2}>{secondaryTitle}</h2>

          <p className={`${styles.container__infotext}`}>
            {infoText}
          </p>
        </div>

        <div
          className={`${styles.container__footer} d-flex flex-column align-items-center justify-content-end`}
        ></div>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        className={`${styles.container__buttonsblock} d-flex flex-column align-items-center justify-content-center`}
      >
        {loginOptions.map((option,index) => (
          <div
            key={index}
            className={`${styles.container__buttonsblock__buttons} d-flex align-items-center justify-content-around`}
            onClick={() => goToLogin(option)}
          >
            <div>
              <span>{option.loginText}</span>
            </div>
            <div>
              <ChevronRight />
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default Landing;
