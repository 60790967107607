import React, { useState } from "react";
import {
  FormControlLabel,
  Checkbox,
  Input,
  Grid,
  TextField,
} from "@material-ui/core";
import _ from "lodash";
import "./style.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";

const FilterInput = ({ column, options, setitem, filters, setFilters }) => {
  options = options && options.map((option) => option.name);
  const handleChange = (field, event, value, operator, layout) => {
    addFilterData(field, event, value, operator, layout);
  };

  const addFilterData = (field, event, value, operator, layout) => {
    let filterObject = {
      field: field,
      value: value,
      operator: operator,
      filterType: layout?.filterType
    };
    let newFilters = [...filters];
    let filterIndex = filters.findIndex((filter) => filter.field === field);
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObject;
    } else {
      newFilters.push(filterObject);
    }
    setFilters([...newFilters]);
  };
  const handleCheck = (field, event, item, value) => {
    if (item) {
      let newValue = [];
      const saveCheckedValues = filters.find(
        ({ field }) => field === column.key
      )
        ? filters.find(({ field }) => field === column.key).value
        : [];
      if (value) {
        newValue = [...saveCheckedValues, item];
        addFilterData(field, event, newValue);
      } else {
        newValue = saveCheckedValues.filter((value) => value !== item);
        addFilterData(field, event, newValue);
      }
    }
  };
  const handelDateChange = (field, event, Dates, key) => {
    const valuesOfDates = filters.find(({ field }) => field === column.key)
      ? filters.find(({ field }) => field === column.key).value
      : [new Date(), new Date()];
    if (key === "from") {
      valuesOfDates[0] = Dates;
    } else if (key === "to") {
      valuesOfDates[1] = Dates;
    }
    addFilterData(field, event, valuesOfDates, "between", {
      filterType: "date",
    });
  };

  const inputs = {
    number: (
      <>
        <Grid className="NumberStyle">
          <span className="spanStyle">{column.name}</span>
          <Input
            style={{ width: "30px" }}
            className="inputStyle"
            name={column.key}
            id={column.key}
            type="number"
            value={
              filters.find(({ field }) => field === column.key)
                ? filters.find(({ field }) => field === column.key).value
                : ""
            }
            inputProps={{ min: 0 }}
            onChange={(event) => {
              addFilterData(
                column.key, 
                event, 
                event.target.value, 
                undefined, 
                column.inputLayout
                );
            }}
          />
        </Grid>
      </>
    ),
    date: (
      <Grid container className="dateStyle" style={{ width: "98%" }}>
        <Grid item xs={6}>
          <span className="spanStyle">From</span>
          <TextField
            id="date"
            type="date"
            value={
              filters.find(({ field }) => field === column.key)
                ? filters.find(({ field }) => field === column.key).value[0]
                : new Date()
            }
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();

              handelDateChange(column.key, event, event.target.value, "from");
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <span className="spanStyle">To</span>
          <TextField
            id="date"
            type="date"
            value={
              filters.find(({ field }) => field === column.key)
                ? filters.find(({ field }) => field === column.key).value[1]
                : new Date()
            }
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event, date) => {
              event.persist();
              handelDateChange(column.key, event, event.target.value, "to");
            }}
          />
        </Grid>
      </Grid>
    ),
    enum: (
      <Grid
        container
        className="dateStyle"
        style={{ width: "95%", height: "100%" }}
      >
        {column &&
          column.enum &&
          column.enum.map((item, index) => (
            <Grid key={index} container>
              <FormControlLabel
                xs={12}
                control={
                  <Checkbox
                    onChange={(event, value) => {
                      event.persist();
                      handleCheck(column.key, event, event.target.name, value, column.inputLayout);
                    }}
                    color="default"
                    name={item}
                  />
                }
                label={item}
                checked={
                  filters.find(({ field }) => field === column.key)
                    ? filters
                        .find(({ field }) => field === column.key)
                        .value.find((val) => val === item)
                      ? true
                      : false
                    : false
                }
              />
            </Grid>
          ))}
      </Grid>
    ),
    string: (
      <Autocomplete
        style={{ width: "95%" }}
        multiple
        id="tags-outlined"
        options={options ? options : []}
        renderOption={(o) => o}
        getOptionLabel={(o) => (typeof o === "string" ? o : "")}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={column.name}
            placeholder="search"
          />
        )}
        onChange={(event, value) => 
          handleChange(column.key, event, value, undefined, column.inputLayout)
        }
        filterSelectedOptions
        value={
          filters.find(({ field }) => field === column.key)
            ? filters.find(({ field }) => field === column.key).value
            : []
        }

      />
    ),
  };
  return (
    <React.Fragment key={column.key}>
      <Grid item style={column.input_layout || {}} className="pl-0">
        <div style={{ marginTop: "4%", marginLeft: "2%" }}>
          {inputs[String(column.inputLayout?.filterType)]}
        </div>
      </Grid>
    </React.Fragment>
  );
};
export default FilterInput;
