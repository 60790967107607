import merge from "deepmerge";
export { default as release } from "./release/index";
export { default as reviewer } from "./reviewer/index";
export { default as checklist_samples } from "./checklist_samples/index";

const {
  initialValuesOfCertificates,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/certificates/initialValuesOfCertificates`);
const config = (letter, values, templateName) => {
  const initialAttrsValue = initialValuesOfCertificates(letter, values)[
    templateName
  ]();

  if (initialAttrsValue.type === "const") {
    return initialAttrsValue.state;
  }

  return merge(
    initialAttrsValue.state["shared"](),
    initialAttrsValue.state[letter.values.product_type]()
  );
};

export default config;
export { default as checklist_cpmp } from "./checklist_cpmp/index";
export { default as distributionFormCPMP } from "./distributionFormCPMP/index";
export { default as acceptNameLetter } from "./acceptNameLetter/index";
export { default as acceptanceLetter } from "./acceptance_letter_of_the_UHIA/index";
export { default as sampleAnalysisReport } from "./sampleAnalysisReport/index";
