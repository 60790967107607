import React, { useState } from "react";

import { Button, Grid, InputAdornment, TextField } from "@material-ui/core";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
export default function SearchBar(props) {
  const [error, setError] = useState(true);

  const handleChange = function (event) {
    console.log(event.target.value);

    const value = event.target.value;
    props.setSearchValue(value);

    if (value) {
      props.isValid(value) ? setError(false) : setError(true);
    }
  };

  return (
    <>
      <Grid
        direction="row"
        justifyContent="center"
        align="center"
        container
        item
        sm={5}
        spacing={1}
      >
        <Grid item sm={11}>
          <TextField
            variant="outlined"
            size="small"
            id="input-with-icon-textfield1"
            className="w-100"
            InputProps={{
              style: {
                background: "#FFFFFF",
              },
              className: "rounded-lg",
              placeholder: "الرقم القومى للمريض",
              endAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            value={props.searchValue || ""}
            // error={error}
          />
        </Grid>
        <Grid item sm={1}>
          <Button
            variant="contained"
            disabled={error}
            className=" mb-4 rounded-lg btn-secondary"
            onClick={() => props.searchCB(props.searchValue)}
          >
            <span className="btn-wrapper--label">بحث</span>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
