import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Components from "./Components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion({ title, workflow, formik }) {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          style={{ fontSize: 16, fontWeight: "bold" }}
          className={classes.heading}
        >
          {title} setting :
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={4}
          style={{ justifyContent: "space-between", padding: 30 }}
        >
          {workflow?.components.map((setting) => (
            <>
              <Divider />
              <Grid item>{Components({ id: workflow.id })[setting]}</Grid>
            </>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
