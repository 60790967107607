import lodash from "lodash";

export const getInputKey = (input) => {
  let newKey = input.key;
  const regex = /[[][0-9]*[\]]/g;
  const numParts = input.key.match(regex);
  if (numParts)
    numParts.forEach((element) => {
      newKey = input.key.replace("[i]", element);
    });

  return newKey;
};
export const lastInputPart = (input) => input.split(".").pop();
export const getConditionsWithValues = (conditions, args, checkInputs) => {
  return conditions.map((condition, i) => {
    const newKey = getInputKey(condition);
    return {
      ...condition,
      key: newKey,
      actualValue:
        args[checkInputs.findIndex((input) => input === lastInputPart(newKey))],
    };
  });
};
export const TryFillDataFromContext = (conditions, context) => {
  for (const condition of conditions) {
    if (lodash.isNil(condition.actualValue))
      condition.actualValue = lodash.get(context, condition.key);
  }
};

export const substituteByActualIndex = (numParts, condition, isMany) =>
  numParts.reduce(
    (newKey, element) =>
      (newKey || (isMany ? condition.key : condition.single_key)).replace(
        ".[i].",
        typeof element === "number" ? `.${element}.` : element
      ),
    ""
  );
