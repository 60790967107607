import { Card, CardContent, Grid, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DetailsContent from "../DetailsContent";
import DetailsTabsHeaders from "../DetailsTabsHeaders";
import { connect } from "react-redux";
import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";
import "./style.css";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { setSchema } from "utils/setValidationSchema";
import { handleClick } from "pages/EdaDetails/utils";
import {
  saveSubmissionValues,
  setAttachmentReviewed,
  setUploadedAttachments,
} from "reducers/details";
import { apiConfig } from "api/utilities";
import _ from "lodash";
const {
  detailsTabsConfig,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/detailsTabs`);
const {
  documentsConfig,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/details/documents`);
function DetailsTabs({
  task,
  partIndex,
  currentUser,
  allAttachments,
  setTakeActionFlag,
  setAttachmentReviewed,
  attachmentsUnderReview,
  setUploadedAttachments,
  saveSubmissionValues,
}) {
  const [selectedTab, setSelectedTab] = useState();
  const [allTabs, setAllTabs] = useState([]);
  const [actions, setActions] = useState([]);
  let formik;
  const InitiateFormik = () => {
    let schemaInputs = [];
    let initialValues = {};
    const prepareAttachment = (attachment) => ({
      ...attachment,
      fileObject: `${apiConfig.baseUrl}attachment/download?id=${attachment.id}`,
    });
    if (task?.step?.edit_form) {
      allAttachments.forEach((attachment) => {
        if (!attachment.is_reviewed && !attachment.document.isComment) {
          schemaInputs.push({
            ...attachment.document,
            key: attachment.document.equivalent_key,
          });
          if (attachment.document.multiple)
            initialValues = {
              ...initialValues,
              [attachment.document.equivalent_key]: initialValues[
                attachment.document.equivalent_key
              ]
                ? [
                    ...initialValues[attachment.document.equivalent_key],
                    prepareAttachment(attachment),
                  ]
                : [prepareAttachment(attachment)],
            };
          else
            initialValues = {
              ...initialValues,
              [attachment.document.equivalent_key]:
                prepareAttachment(attachment),
            };
        }
      });
    }
    formik = useFormik({
      validateOnChange: true,
      validationOnBlur: true,
      enableReinitialize: true,
      validationSchema: Yup.object().shape(setSchema(schemaInputs)),
      initialValues: initialValues,
    });
  };
  InitiateFormik();
  useEffect(() => {
    if (allTabs?.length) {
      setSelectedTab(allTabs[0]);
    }
  }, [allTabs]);
  let mapper = { currentUser, task, taskUsers: task?.users };
  let configs = detailsTabsConfig(mapper);
  useEffect(() => {
    if (selectedTab)
      setActions(
        configs.actions
          .filter((action) => {
            return action.tabs ? action.tabs.includes(selectedTab.id) : true;
          })
          .map((action) => {
            return {
              ...action,

              constraints: applyDetailsConstraints(action.constraints, mapper),
            };
          })
      );
  }, [selectedTab]);
  useEffect(() => {
    let tabs = configs.tabs.filter((tab) => {
      if (!tab.constraints) return true;
      let result = applyDetailsConstraints(tab.constraints);
      return result.display;
    });
    setActions(
      configs.actions.map((action) => {
        return {
          ...action,
          constraints: applyDetailsConstraints(action.constraints, mapper),
        };
      })
    );
    setAllTabs(tabs);
  }, [task]);
  useEffect(() => {
    if (!_.isEmpty(formik.values)) setUploadedAttachments(formik.values);
  }, [formik.values]);
  useEffect(() => {
    if (allAttachments.length > 0) {
      let tabsUnderRevision = configs.tabs
        .filter(
          (tab) => tab.underRevision && tab.tabContent.includes("Documents")
        )
        .map((tab) => tab.id);
      let documentsUnderRevision = [];
      if (tabsUnderRevision.length > 0) {
        tabsUnderRevision.forEach((tab) => {
          let tabContent = documentsConfig().find(
            (config) => config.tabId === tab
          );
          documentsUnderRevision = [
            ...documentsUnderRevision,
            ...(tabContent.attachments?.filter(
              (attachment) => attachment.underRevision
            ) || []),
          ];
        });
      }
      let attachments = [];
      let userUploadedAttachments = [];
      allAttachments.forEach((attachment) => {
        let foundAttachment = documentsUnderRevision.find(
          (attachmentUnderRevision) => {
            if (
              attachmentUnderRevision.equivalent_key ===
                attachment.document.equivalent_key &&
              currentUser.roles.some(
                (role) => attachmentUnderRevision.uploadedBy === role.name
              )
            ) {
              userUploadedAttachments.push({
                ...attachment,
                passWithRejection: attachmentUnderRevision.passWithRejection,
                uploadedBy: attachmentUnderRevision.uploadedBy,
              });
            }
            return (
              attachmentUnderRevision.equivalent_key ===
                attachment.document.equivalent_key &&
              currentUser.roles.some(
                (role) =>
                  attachmentUnderRevision.reviewers.includes(role.name) ||
                  attachmentUnderRevision.uploadedBy === role.name
              )
            );
          }
        );

        if (foundAttachment) {
          attachments.push({
            ...attachment,
            passWithRejection: foundAttachment.passWithRejection,
            uploadedBy: foundAttachment.uploadedBy,
          });
        }
      });
      if (
        task.step.edit_form &&
        userUploadedAttachments.every((attachment) => attachment.is_reviewed)
      ) {
        setTakeActionFlag(true);
      }
      setAttachmentReviewed(attachments);
    }
  }, [allAttachments]);
  const actionProperties = {
    handleSubmit: () => {
      let values = {};
      for (const key in formik.values) {
        if (!formik.values[key].hasOwnProperty("is_reviewed")) {
          values[key] = formik.values[key];
        }
      }
      let inputs = Object.keys(values)
        .map((value) => values[value]?.document?.id || values[value].input_id)
        .filter((value) => value != null);

      let attachmentReviewed = attachmentsUnderReview.filter((attachment) => {
        return attachment.is_dirty;
      });
      if (!_.isEmpty(values) || attachmentReviewed.length > 0) {
        saveSubmissionValues({
          submission_id: task.submission.id,
          task_id: task.id,
          submission_edit: values,
          attachmentReviewed: attachmentReviewed,
          inputs,
        }).then((res) => {
          if (res.value.status === 200) {
            Swal.fire({
              title: "Your application has been saved successfully!",
              icon: "success",
              confirmButtonColor: "#0066cc",
            }).then(() => {
              let filteredUploadedAttachments = Object.entries(formik.values);
              filteredUploadedAttachments = filteredUploadedAttachments.filter(
                ([key, value]) => !("document" in value)
              );
              filteredUploadedAttachments = Object.fromEntries(
                filteredUploadedAttachments
              );
              if (
                task.step.edit_form &&
                attachmentsUnderReview
                  .filter((attachment) => !attachment.is_reviewed)
                  .every(
                    (element) =>
                      element.document.equivalent_key in
                      filteredUploadedAttachments
                  )
              ) {
                setTakeActionFlag(true);
              }
              if (!task.step.edit_form) {
                setTakeActionFlag(true);
              }
            });
          }
        });
      }
    },
  };

  return (
    <Grid
      container
      className="edaDetails"
      direction="row"
      spacing={4}
      key={partIndex}
    >
      <Grid item xs={3}>
        <DetailsTabsHeaders tabs={allTabs} setSelectedTab={setSelectedTab} />
      </Grid>
      <Grid item xs={9} align="left">
        <Card className="pt-3 pb-3">
          {actions.map((action, index) => {
            let ButtonIcon = action.icon;
            return (
              <React.Fragment key={index}>
                {action.constraints?.display && (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={action.classes}
                    onClick={(e) => handleClick(e, action, actionProperties)}
                  >
                    <ButtonIcon className="mr-1" />
                    {action.label}
                  </Button>
                )}
              </React.Fragment>
            );
          })}

          <CardContent>
            {task && selectedTab && (
              <DetailsContent
                tabs={allTabs}
                task={task}
                selectedTab={selectedTab}
                formik={formik}
                setTakeActionFlag={setTakeActionFlag}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    allAttachments: state.details.allAttachments,
    attachmentsUnderReview: state.details.attachmentsUnderReview,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAttachmentReviewed: (attachments) =>
      dispatch(setAttachmentReviewed(attachments)),
    setUploadedAttachments: (attachments) =>
      dispatch(setUploadedAttachments(attachments)),

    saveSubmissionValues: (body) => dispatch(saveSubmissionValues(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTabs);
