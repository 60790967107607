import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  List,
  ListItem,
  Divider,
  DialogActions,
  IconButton,
  ListItemSecondaryAction,
} from "@material-ui/core";
import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import { connect } from "react-redux";
import { fetchFilterDataModel, fetchWorklist } from "reducers/list";
import "./style.scss";
import FilterInput from "./FilterInput";
const FilterModal = (props) => {
  let {
    getFilterModelData,
    modelData,
    getWorklist,
    currentTab,
    filters,
    setFilters,
    removeFromFilter,
    removeAllFilter,
  } = props;
  const FilterAltOutlinedIcon = createSvgIcon(
    <path
      d="M20 3H4a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L9 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 13 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L5 6.59V5h14.001l.002 1.583l-5.71 5.71z"
      fill="currentColor"
    />,
    "FilterAltOutlinedIcon"
  );
  const [selectedItem, setSelectedItem] = useState({
    value: "",
    index: "",
  });
  const [item, setitem] = useState("");
  const [options, setOptions] = useState([]);
  useEffect(() => {
    setOptions(modelData.rows);
  }, [modelData]);
  const [filterItem, setFilterItem] = useState([]);
  const handleSelected = (item, index) => {
    if (item.modelName) {
      getFilterModelData({ model: item.modelName, include: item.include , where:item.where});
    }
    setSelectedItem({ ...selectedItem, value: item, index: index });
    if (!(filterItem.filter((e) => e === item.name).length > 0)) {
      setFilterItem([...filterItem, item.name]);
    }
  };

  const applyFilters = () => {
      const newFilters = filters.map((filter) => {
           if (filter.filterType === "date") {
             // update 'to' date

             let toDate = new Date(filter.value[1]);
           toDate.setDate(toDate.getDate() + 1);
              toDate = toDate.toISOString().split("T")[0];
              return { ...filter, value: [filter.value[0], toDate] };
            }
            return filter;
         });
         getWorklist({ filters: newFilters, currentTab }).catch((err) => {});
    props.setOpen(false);
  };
  let filterLayout = props.filterLayout.flat();
  return (
    <div>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth={"lg"}
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="form-dialog-title2"
        style={{ borderRadius: "6px", width: "70%", margin: "auto" }}
      >
        <DialogTitle>
          <span className="titleModal">
            <FilterAltOutlinedIcon />
            Filter Results
          </span>
        </DialogTitle>
        <DialogContent
          className="p-0"
          style={{ overflowY: "hidden", background: "#FAFAFA" }}
        >
          <Grid container>
            <Grid item xs={4}>
              <List
                style={{
                  padding: "5px",
                  margin: "2%",
                  height: "80%",
                  overflowX: "auto",
                }}
              >
                {filterLayout.flatMap(
                  (item, index) =>
                    item.filter && (
                      <React.Fragment key={index}>
                        <Grid
                          key={index}
                          className={
                            selectedItem.index === index ? "listItemActive" : ""
                          }
                        >
                          <ListItem
                            button
                            onClick={() => handleSelected(item, index)}
                          >
                            <span
                              className={
                                selectedItem.index === index ? "" : "Unactive"
                              }
                            >
                              {item.name}
                            </span>
                            {filters.findIndex(
                              (filter) => filter.field === item.key
                            ) > -1 && (
                              <ListItemSecondaryAction
                                onClick={() => removeFromFilter(item)}
                              >
                                <IconButton
                                  style={{
                                    color: "darkgoldenrod",
                                    fontSize: "revert",
                                    textDecoration: "underline",
                                  }}
                                >
                                  clear
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        </Grid>
                        <Divider style={{ width: "100%" }} />
                      </React.Fragment>
                    )
                )}
              </List>
            </Grid>
            <Grid item xs={8}>
              <FilterInput
                column={selectedItem.value}
                options={options}
                setitem={setitem}
                filters={filters}
                setFilters={setFilters}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="p-3">
          {filters.length > 0 && (
            <button className="clearbtn" onClick={() => removeAllFilter(item)}>
              <b>clear all</b>
            </button>
          )}
          <Button
            className="applyFilter pt-1 pb-1 pl-2 pl-2 pr-2"
            onClick={applyFilters}
            autoFocus
            size="small"
          >
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    modelData: state.filterData.modelData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFilterModelData: (params) => dispatch(fetchFilterDataModel(params)),
    getWorklist: (params) => dispatch(fetchWorklist(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
