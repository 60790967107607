import React from "react";
import { convertDigitIn } from "../utils/utils";

const Title = ({ letter, todayDate }) => {
  return (
    <div>
      <div className="general_div containing_div title_main_div">
        <div className="vertical_div">
          <div className="general_div  arabic_div title_date-issueNo_div">
            <span className="span_general span_arabic">
              {convertDigitIn(letter.submittedAt.split("T")[0])}
              {/* ٩/اغسطس/٢٠٢٢ */}
            </span>
            <span className="span_general span_arabic">
              : تاريخ إرسال الطلب
            </span>
          </div>
          <div className="general_div  arabic_div title_date-issueNo_div">
            <span className="span_general span_arabic">
              {convertDigitIn(todayDate?.split("T")[0])}
            </span>
            <span className="span_general span_arabic">
              : تاريخ انتهاء الطلب
            </span>
          </div>
        </div>
        {/* <div className="general_div arabic_div title_date-issueNo_div">
          
        </div> */}
        <div className="general_div arabic_div containing_div title_title_div">
          <span className="span_general title_span">
            {letter.form.id !== 2 ? " (إجراء خدمة) " : " (صرف دواء) "}
          </span>
          &nbsp;
          <span className="span_general title_span">
           نموذج موافقة مسبقة 
          </span>
        </div>
        <div className="general_div arabic_div title_date-issueNo_div">
          <span className="span_general span_arabic">{letter.file_code}</span>
          <span className="span_general span_arabic">: رقم صادر</span>
        </div>
      </div>
      <div className="general_div arabic_div containing_div"></div>
    </div>
  );
};

export default Title;
