import React from "react";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fontawesome from "@fortawesome/fontawesome";
import { Button, Tooltip } from "@material-ui/core";
import { faDotCircle, faArrowsAltH } from "@fortawesome/free-solid-svg-icons";

import { connect } from "react-redux";

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../reducers/theme/themeActions";
import { NavLink } from "react-router-dom";
const {
  projectLogo,
} = require(`weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/general`);

fontawesome.library.add(faDotCircle, faArrowsAltH);

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    user,
    sidebarToggle,
    setSidebarToggle,
  } = props;

  return (
    <>
      {/* <div className="app-sidebar-logo">
          <NavLink
            to="/"
            title="بيت الزكاه و الصدقات المصرى"
            className="app-sidebar-logo">
            <div className="app-sidebar-logo--text">
              <b>بيت الزكاه و الصدقات </b>
            </div>
          </NavLink>
        </div> */}

      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/"
            title="Bamburgh React Admin Dashboard with Material-UI PRO"
            className="app-sidebar-logo"
          >
            <div className="app-sidebar-logo--icon">
              <img src={projectLogo} />
            </div>
            <div className="app-sidebar-logo--text">
              <b className="font-size-sm">{user?.username}</b>

              <span className="mt-2 font-size-xxs">
                {user?.roles.map((role) => role.details).join(",")}
              </span>
            </div>
          </NavLink>
        </div>

        <Tooltip title="Collapse sidebar" placement="left" arrow>
          <Button
            onClick={toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn"
          >
            <FontAwesomeIcon icon={faDotCircle} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx(
            "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
            { "is-active": sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="left" arrow>
          <Button
            onClick={toggleSidebar}
            className="expand-sidebar-btn btn btn-sm"
          >
            <FontAwesomeIcon icon={faArrowsAltH} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.theme.sidebarToggle,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
