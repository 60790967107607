const initialValues = (letter, values) => {
  return {
    ReviewerReport: () => {
      return {
        state: {
          info: "",
          conclusion: "",
          manufacturer_product:
            letter?.drugs.Manufacturer_of_finished_product ||
            letter?.values?.manufacturer_product,
          manufacturer_responsible:
            letter.drugs.Manufacturer_Responsible_for_Primary_packaging ||
            letter?.values?.manufacturer_responsible,
          full_sampling_value: "",
          reduced_sampling_value: "",
          marketing_authorization:
            letter?.drugs?.MarketingAuthorizationHolder ||
            letter?.values?.marketing_authorization,
          reviewer_report: "",
          sampling: "",
        },
        type: "const",
      };
    },
    checklistSamples: () => {
      return {
        state: {},
        type: "const",
      };
    },
    checklistCPMP: () => {
      return {
        state: {},
        type: "const",
      };
    },
    distributionCPMP: () => {
      return {
        state: {},
        type: "const",
      };
    },
    sampleAnalysisReport: () => {
      return {
        state: {},
        type: "const",
      };
    },
    letters: () => {
      return {
        state: {
          shared: () => {
            return {
              registered: letter.drugs?.registration_status === "Registered",
              notRegistered: letter.drugs?.registration_status === "Expired",
              tableInputs: [
                {
                  title: "Product Type",
                  name: "Product Type",
                  value: letter.drugs.generics,
                  isRequired: false,
                },
                {
                  title:
                    "Batch number of the product/Batch number of the diluents(if present)",
                  name: "Batch number of the product/Batch number of the diluents(if present)",
                  value: letter.values.batch_number,
                  isRequired: false,
                },
                {
                  title: "Type of container/doses",
                  name: "Type of container/doses",
                  value: letter.values?.dosage_type || "",
                  isRequired: false,
                },
                {
                  title: "Manufacturer address/Site(s) of manufacturing",
                  name: "manufacturer_address",
                  value: letter.drugs.manufacturer_address,
                  isRequired: true,
                },
                {
                  title: "Storage condition:",
                  name: "storage_condition",
                  value: letter.drugs.storage_condition,
                  isRequired: true,
                },
                {
                  title: "Manufacturing date:",
                  name: "Manufacturing date:",
                  value: letter.values.manufacture_date || "",
                  isRequired: false,
                },
                {
                  title: "Expiry date:",
                  name: "Expiry date:",
                  value: letter.values.expiry_date || "",
                  isRequired: false,
                },

                {
                  title: "Shelf life:",
                  name: "Shelf_life:",
                  value:
                    letter.drugs.shelf_life !== undefined
                      ? letter.drugs.shelf_life + ""
                      : "",
                  isRequired: false,
                },
                {
                  title: "Quantity of Shipment:",
                  name: "Quantity of Shipment:",
                  value: letter.values.quantity || null,
                  isRequired: false,
                },
                { title: " Agent:", name: "Agent:", value: letter?.org?.name },
                {
                  title: "Distributer:",
                  name: "Distributer",
                  value: letter.user.username,
                  isRequired: false,
                },
                {
                  title: "Code number:",
                  name: "Code number:",
                  value: letter.values.code_number,
                  isRequired: false,
                },
              ],
              descriptionText: "",
              numberProp: {
                title: `${
                  values.is_compliance ? "Certificate number:" : "Notice number"
                }`,
                name: `${
                  values.is_compliance ? "Certificate number:" : "Notice number"
                }`,
                value: "",
                isRequired: true,
              },
            };
          },
          "Products prepared by recombinant DNA technology": () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate for products prepared by rDNA Technology"
                : "Notice of Non-Compliance for products prepared by rDNA Technology",
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements of the products prepared by rDNA Technology No. (        ), EMEA guidelines, and the above marketing authorization file and has been approved for release."
                : "‎The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements of the products prepared by rDNA Technology No. (        ), EMEA guidelines, and the above marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
          "Extractable products": () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate for Extractable products"
                : "Notice of Non-Compliance for Extractable products",

              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
          "Plasma-derived medicinal products": () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate for plasma derived medicinal products"
                : "Notice of Non-Compliance for plasma derived medicinal products",
              tableInputs: [
                {
                  title: "Source of plasma:",
                  name: "source_of_plasma",
                  value: "",
                  isRequired: false,
                },
                {
                  title: "VVM grade:",
                  name: "VVM_grade",
                  value: letter.values.vvm_grade,
                  isRequired: false,
                },
              ],
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the Requirements for this product according to EDQM and/or CFR, the requirements of this product that are given in the general monograph ‘Human plasma for fractionation (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, the Requirements for this product according to EDQM and/or CFR, the requirements of this product that are given in the general monograph ‘Human plasma for fractionation (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },

          "Anti-sera of animal origin": () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate for anti-sera of animal origin"
                : "Notice of Non-Compliance for anti-sera of animal origin",
              tableInputs: [
                {
                  title: "VVM grade:",
                  name: "VVM_grade",
                  value: letter.values.vvm_grade,
                  isRequired: false,
                },
              ],
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements for biological substance no. (        ) and the relevant specification mentioned in the marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements for biological substance no. (….) and the relevant specification mentioned in the marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
          Vaccines: () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate for Vaccines"
                : "Notice of Non - Compliance for Vaccines",
              tableInputs: [
                {
                  title: "VVM grade:",
                  name: "VVM_grade",
                  value: letter.values.vvm_grade,
                  isRequired: false,
                },
              ],
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements for biological substance no. (        ) and the relevant specification mentioned in  the marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, the WHO Requirements for biological substance no. (….) and the relevant specification mentioned in the marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
          Others: () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate "
                : "Notice of Non - Compliance ",
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
          Variation: () => {
            return {
              header: values.is_compliance
                ? "Batch Release Certificate "
                : "Notice of Non - Compliance ",
              descriptionText: values.is_compliance
                ? "The batch mentioned above is in compliance with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and has been approved for release."
                : "The batch mentioned above is NOT in compliance with the acceptance criteria for the release in Egypt, with the acceptance criteria for the release in Egypt, the requirements of this product are given in the general monograph  (-----) of the ------- Pharmacopoeia and in the specific monograph no (-----) and the above marketing authorization file and cannot be released. Technical details of this non-compliance are available on request.",
            };
          },
        },
      };
    },
    type: "type dependent",
  };
};
export { initialValues as initialValuesOfCertificates };
