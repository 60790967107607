import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const config = (mapper) => {
  let qrcodeIcon = () => {
    return <FontAwesomeIcon icon={faQrcode} className="ml-2" />;
  };
  return {
    sections: [
      {
        id: 1,
        attributes: [
          {
            name: "ID",
            key: "submission.file_code",
            type: "integer",
            style: { gridSize: 1, display: true },
          },

          {
            name: "Application Date",
            key: "submission.submittedAt",
            type: "date",
            style: { gridSize: 2, display: true },
          },

          {
            name: "End Date",
            key: "taskCreationDate",
            type: "date",
            style: { gridSize: 2, display: true },
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: ["Approve", "Reject"],
                    operator: "reverse includes",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.task?.submission.status,
                  },
                ],
                conditionsEquation: "$0",
              },
            },
          },
          {
            name: "Status",
            key: "submission.status",
            type: "string",
            style: { gridSize: 1, display: true },
            contentClass: `${mapper.task?.submission?.status} w-159`,
          },
          {
            name: "Current Step",
            key: "step.name",
            type: "string",
            style: { gridSize: 2, display: true },
          },
          {
            name: "Days Remaining",
            key: "remaining.number",
            type: "string",
            style: { gridSize: 1 },
            contentClass: mapper.task?.submission?.status,
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "notEqual",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: 1,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                ],
                conditionsEquation: "!$1 ||(!$0 && $1) ",
              },
            },
          },
        ],
        actions: [
          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "handleAssignClick",
            },
            label: "Assign myself",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "notEqual",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser?.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "$0 && $1",
              },
            },
          },
          {
            gridSize: 2,
            classes: "takeActionButton",
            onClick: {
              functionName: "openTakeActionModal",
            },
            label: "Take action",
            constraints: {
              display: {
                conditions: [
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.is_assigned,
                  },
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                ],
                conditionsEquation: "$0 && $1 ",
              },
              disable: {
                conditions: [
                  // $0
                  {
                    checkValue: false,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.takeActionFlag,
                  },

                  // $1
                  {
                    checkValue: 71,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.step.id,
                  },
                  //$2
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.stepInputFlag,
                  },
                  // $3
                  {
                    key: "task_id",
                    checkValue: mapper.task?.id,
                    operator: "every",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.attachmentsUnderReview.filter(
                      (attachment) => !attachment.is_reviewed
                    ),
                  },
                  // $4
                  {
                    key: "is_reviewed",
                    checkValue: true,
                    operator: "every",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.attachmentsUnderReview,
                  },
                  //$5
                  {
                    key: "name",
                    checkValue: "Branch",
                    operator: "some",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.currentUser.roles,
                  },
                  //$6
                  {
                    key: "document.equivalent_key",
                    checkValue: mapper.uploadedAttachments,
                    operator: "everyObjectInArray",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.attachmentsUnderReview.filter(
                      (attachment) => !attachment.is_reviewed
                    ),
                  },
                  //$7
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.saveSubmissionsValuesLoader,
                  },
                  //$8
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task.step.edit_form,
                  },
                  //$9
                  {
                    operator: "checkEmpty",
                    functionName: "applyObjectMethods",
                    actualValue: mapper.uploadedAttachments,
                  },
                ],
                conditionsEquation:
                  "($2)||  $0 ||(!$0  && $5 && (!$9 && !$6) && $8 && !$4 ) || $7 ",
              },
            },
          },
          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "openEditForm",
            },
            label: "Edit",
            constraints: {
              display: {
                conditions: [
                  {
                    key: "id",
                    checkValue: mapper.currentUser.id,
                    operator: "find",
                    functionName: "applyArrayMethods",
                    actualValue: mapper.taskUsers,
                  },
                  {
                    checkValue: "Reject submission",
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.submission.values.rejection_type,
                  },
                  {
                    checkValue: true,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.is_assigned,
                  },
                  {
                    checkValue: 32,
                    operator: "equal",
                    functionName: "applyMathOperators",
                    actualValue: mapper.task?.step.id,
                  },
                ],
                conditionsEquation: "$0 && $1 && $2 && $3",
              },
            },
          },
          // {
          //   gridSize: 3,
          //   type: "component",
          //   name: "assign",
          //   constraints: {
          //     display: {
          //       conditions: [
          //         {
          //           key: "id",
          //           checkValue: 3,
          //           operator: "find",
          //           functionName: "applyArrayMethods",
          //           actualValue: mapper.currentUser.roles,
          //         },

          //         {
          //           key: "id",
          //           checkValue: mapper.task?.step.id,
          //           operator: "includes",
          //           functionName: "applyArrayMethods",
          //           actualValue: [5],
          //         },
          //       ],
          //       conditionsEquation: "$0 && $1 && ",
          //     },
          //   },
          // },

          {
            gridSize: 3,
            classes: "takeActionButton",
            onClick: {
              functionName: "downloadQrCode",
            },
            label: "QR Code",
            icon: qrcodeIcon,
            constraints: {
              display: {
                conditions: [
                  {
                    key: "id",
                    checkValue: mapper.task?.step.id,
                    operator: "includes",
                    functionName: "applyArrayMethods",
                    actualValue: [1, 41],
                  },
                ],
                conditionsEquation: "$0",
              },
            },
          },
        ],
      },
    ],
  };
};
