import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  DialogTitle,
} from "@material-ui/core";
import { useFormik } from "formik";
import { GetStepContent } from "components/FormSteps/getStepContent";
import client from "api/apiAuth/guestClient";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { setSchema } from "utils/setValidationSchema";
import _ from "lodash";
import { enableSubmitButton } from "reducers/form/formActions";
import { connect } from "react-redux";

const StepInputsModal = ({
  open,
  setOpen,
  section,
  submission,
  initialValues,
  task,
  setInputfalg,
  enableSubmitButton,
}) => {
  const [loading, setLoading] = useState(false);
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [errorMessage, setErrorMesssage] = useState("");
  const formik = useFormik({
    validationSchema: Yup.object().shape(setSchema(section.inputs)),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask();
    },
  });
  useEffect(() => {
    setErrorMesssage("");
  }, [formik.values]);
  useEffect(() => {
    if (!_.isEmpty(formik.errors)) {
      setInputfalg(false);
    }
  }, [formik.errors]);
  const handleSubmitTask = async () => {
    if (!_.isEmpty(formik.values)) {
      setErrorMesssage("");

      setLoading(true);
      let rules = [];
      task.step.groups.forEach((group) => {
        rules.push(group.rules);
      });
      rules = rules.flat();

      await client.post("submission/updateSubmissionValues", {
        submission_id: submission.id,
        section_id: section.id,
        rules: rules,
        task_id: task.id,
        submission_edit: formik.values,
      });
      setInputfalg(true);
      // window.location.reload();

      setLoading(false);
      setOpen(false);
    } else {
      setErrorMesssage("Please fill data before submit");
    }
  };
  return (
    <Dialog
      classes={{ paper: "modal-content" }}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title2"
      style={{
        borderRadius: "6px",
        width: "60%",
        margin: "auto",
        height: "120%",
      }}
    >
      <DialogTitle>
        <span className="titleModal">{section.name}</span>
      </DialogTitle>
      <DialogContent>
        <Grid style={{ padding: "0px", justifyContent: "flex-start" }}>
          {GetStepContent({
            formik,
            activeStep: 0,
            steps: [section],
            sectionNameFlags,
            submission,
            setSectionNameFlags,
          })}
        </Grid>
        {errorMessage && (
          <div className="text-danger ml-3 mb-2">
            <small>{errorMessage}</small>
          </div>
        )}
        <Grid
          style={{
            display: "flex",
            alignContent: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "5%",
          }}
        >
          <Button
            className="mx-1 px-5 py-2"
            size="large"
            color="primary"
            variant="contained"
            style={{ borderRadius: "5px" }}
            onClick={(e) => {
              enableSubmitButton();
              formik.handleSubmit(e);
            }}
            disabled={
              (!_.isEmpty(formik.values) &&
                section.inputs.some((e) => !e.disabled) &&
                !(formik.dirty && formik.isValid)) ||
              loading
            }
          >
            <span className="btn-wrapper--label ml-2 font-size">Confirm</span>
            {loading && <ClipLoader color={"var(--primary)"} loading={true} />}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton()),
  };
};

export default connect(null, mapDispatchToProps)(StepInputsModal);
