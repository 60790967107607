import client from "api/apiAuth/guestClient";

export const fetchSubmissions = async function (include, limit, page, filters) {
  return await client.get("submission/find", {
    params: {
      model: "Submission",
      include,
      limit,
      skip: page * limit,
      filters,
    },
  });
};

export const getPatient = async function (id) {
  return await client.get("generic/findOne", {
    params: {
      model: "Person",
      where: { national_id: id },
      relatedAssociations: [{ association: "patientProfile" }],
    },
  });
};
