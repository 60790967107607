import { useEffect, useState } from "react";
import client from "api/apiAuth/guestClient";
import SubmissionType from "./components/SubmissionType";
import SubmissionDate from "./components/SubmissionDate";
import { Button, Grid } from "@material-ui/core";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import _ from "lodash";

const data = [
  {
    workflow_id: 1,
    submission_type: true,
    values: [
      {
        type: "f-toll",
        Saturaday: 10,
        Monday: 12,
        submissionLimit: [
          {
            checked: true,
          },
          {
            checked: false,
          },
          {
            checked: true,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
        ],
      },
      {
        type: "toll",
        Saturaday: 15,
        sunday: 20,
        submissionLimit: [
          {
            checked: true,
          },
          {
            checked: true,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
          {
            checked: false,
          },
        ],
      },
    ],
    period: "daily",
  },
  {
    workflow_id: 2,
    submission_type: true,
    values: [
      {
        type: "f-toll",
        limit: 10,
      },
      {
        type: "toll",
        limit: 99,
      },
    ],
    period: "everyweek",
  },
  {
    workflow_id: 3,
    submission_type: false,
    values: [
      {
        limit: 78146,
      },
    ],
    period: "everyweek",
  },
  {
    workflow_id: 4,
    submission_type: true,
    values: [
      {
        limit: 875,
      },
    ],
    period: "everyday",
  },
];

export default function SubmissionLimit({ id }) {
  const targeData = data.find((cur) => cur.workflow_id === id);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [submissionType, setSubmissionType] = useState();
  let formik;
  formik = useFormik({
    validationSchema: yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  useEffect(() => {
    switch (targeData.period) {
      case "daily":
        if (targeData.submission_type) {
          setValidationSchema({
            type: yup.string().required(),
            Saturaday: yup.number().when("submissionLimit[0].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Sunday: yup.number().when("submissionLimit[1].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Monday: yup.number().when("submissionLimit[2].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Tuesday: yup.number().when("submissionLimit[3].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Wednesday: yup.number().when("submissionLimit[4].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Thursday: yup.number().when("submissionLimit[5].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Friday: yup.number().when("submissionLimit[6].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            submissionLimit: yup
              .array()
              .of(
                yup.object().shape({
                  checked: yup.boolean().nullable(),
                })
              )
              .required(),
          });
        } else {
          setValidationSchema({
            Saturaday: yup.number().when("submissionLimit[0].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Sunday: yup.number().when("submissionLimit[1].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Monday: yup.number().when("submissionLimit[2].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Tuesday: yup.number().when("submissionLimit[3].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Wednesday: yup.number().when("submissionLimit[4].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Thursday: yup.number().when("submissionLimit[5].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            Friday: yup.number().when("submissionLimit[6].checked", {
              is: true,
              then: yup
                .number()
                .typeError("Limit must be a number")
                .required("submission limit is required"),
              otherwise: yup.number().notRequired().nullable(),
            }),
            submissionLimit: yup
              .array()
              .of(
                yup.object().shape({
                  checked: yup.boolean().nullable(),
                })
              )
              .required(),
          });
        }
        setInitialValues(targeData.values[0]);

        break;
      case "everyday":
      case "everymonth":
      case "everyweek":
        if (targeData.submission_type) {
          setValidationSchema({
            type: yup.string().required(),
            limit: yup.number().required(),
          });
        } else {
          setValidationSchema({
            limit: yup.number().required(),
          });
        }
        setInitialValues(targeData.values[0]);

        break;
    }
  }, []);

  useEffect(() => {
    if (submissionType) {
      const values = targeData.values.find((cur) => cur.type == submissionType);
      setInitialValues(values);
    }
  }, [submissionType]);

  const handleSubmit = async () => {
    const res = await formik.validateForm();

    if (!_.isEmpty(res) || _.isEmpty(formik.values)) {
      Swal.fire({
        title: "fields are either required or invalid",
        icon: "error",
        confirmButtonColor: "#d36467",
        confirmButtonText: "Try Again",
        width: "50%",
      });
    } else {
      // handleSubmit();
      client
        .post("/settings/submitSettings", {
          workflow_id: targeData.workflow_id,
          values: formik.values,
        })
        .then(() => {
          Swal.fire({
            title: "Your Settings has been saved successfully!",
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#003655",
            confirmButtonText: "Ok",
          });
        });
    }

  };
  const handleTypeChange = (value) => {
    setSubmissionType(value);
  };
  return (
    <>
      <FormikProvider value={formik}>
        <h6
          style={{
            paddingBottom: 15,
            textShadow: "2px 2px 8px rgba(0,0,0,.2)",
            color: "#003655",
          }}
        >
          Submission Limit :
        </h6>
        <Grid container spacing={4}>
          {targeData.submission_type ? (
            <Grid item xs={12} sm={6}>
              <SubmissionType
                submissionType={submissionType}
                handleTypeChange={handleTypeChange}
                data={targeData}
                formik={formik}
              />
            </Grid>
          ) : null}
          <SubmissionDate data={targeData} formik={formik} />
          <Grid item xs={12} style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              className="mr-5"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </FormikProvider>
    </>
  );
}
