import React from 'react'
import {
  TableBody,
  TableCell,
  TableRow,
  Button
} from "@material-ui/core";

import signatureLogo from "../../../../assets/images/signature_2.svg"
import stampLogo from "../../../../assets/images/stamp.svg"
import editLogo from "../../../../assets/images/general_edit2.svg"
import deleteLogo from "../../../../assets/images/general_delete2.svg"
import editUserLogo from "../../../../assets/images/user-edit.svg"
import deleteUserLogo from "../../../../assets/images/user-delete.svg"
import { useSelector } from 'react-redux';

const Body = ({ entities, entity, rowsPerPage, page, viewSignature, handleEditEntity, DeleteEntity }) => {
  const listData = useSelector((state) => state.layout?.columns)
  const keys = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.key))
  const name = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.name))
  const show = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.list))
  const type = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.type))
  const title = listData?.Admin?.filter((a)=>a[entity]).flatMap((A) => A[entity]?.Columns?.map(a => a.title))
  function renderAdminTable(param, key,type,title, i, index,listing,name) {
    if(listing)
    switch (type) {
      case 'array':
        return (<TableCell style={{ textAlign: "center" }} key={i + index}>
        {param[key][0]?.[title] ? `${param[key][0]?.[title]}` : "N/A"}
      </TableCell>) ;
      case 'object':
        return (<TableCell style={{ textAlign: "center" }} key={i + index}>
        {param[key]?.[title] ? `${param[key]?.[title]}` : "N/A"}
      </TableCell>);
      case 'signature':
        return (param[key] ? <TableCell style={{ textAlign: "center" }} key={i + index}>
          <Button
            style={{
              color: "#FFF",

              padding: "5px"
            }}

            onClick={() => {
              viewSignature((index + page * rowsPerPage),name,key);
            }}
          >
            <img
              alt="..."
              style={{ width: "50px", marginRight: "", marginBottom: "" }}
              align="center"
              src={key === "Stamp"? stampLogo :signatureLogo}
            />
          </Button>
        </TableCell>
          :
          <TableCell style={{ textAlign: "center" }} key={i + index}></TableCell>
        );
      case 'edit':
        return (
          <TableCell style={{ textAlign: "center" }} key={i + index}>
            <Button
              style={{ color: "#0b0b0c" }}
              onClick={() => {
                handleEditEntity(index + page * rowsPerPage);
              }}
            >
              <img
                alt="..."
                style={{ width: "50px", marginRight: "", marginBottom: "" }}
                align="center"
                src={entity==="users"? editUserLogo : editLogo}
              />
            </Button>
          </TableCell>
        );
      case 'delete':
        return (
          <TableCell style={{ textAlign: "center" }} key={i + index}>
            <Button
              style={{ color: "#0b0b0c" }}
              onClick={() => {
                DeleteEntity(index + page * rowsPerPage);
              }}
            >
              <img
                alt="..."
                style={{ width: "50px", marginRight: "", marginBottom: "" }}
                align="center"
                src={entity==="users"? deleteUserLogo : deleteLogo}
              />
            </Button>
          </TableCell>
        );
      default:
        return(
          <TableCell style={{ textAlign: "center" }} key={i + index}>
                {param[key]}
              </TableCell>
        ) ;
    }
  }

  return (
    <TableBody>
      {entities?.length > 0 ? (
        (rowsPerPage > 0
          ? entities.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
          : entities
        )?.map((item, index) => (
          <TableRow style={{ direction: "ltr" }} key={index}>
            {keys?.map((key, i) => (
                renderAdminTable(item, key,type[i],title[i], i, index,show[i],name[i])
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell align="center" colSpan="9">
            <h5 className="m-top">No results</h5>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default Body