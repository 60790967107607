import { List, ListItem } from "@material-ui/core";
import React from "react";

function TableTabs({ menus, currentTab, setCurrentTab }) {
  const handleMenu = (menuIndex) => {
    if (currentTab !== menuIndex) setCurrentTab(menuIndex);
  };
  return (
    <List className="nav-tabs nav-tabs-primary d-flex p-0 tabs-list">
      {menus.map((menu, i) => (
        <ListItem
          primary="Single-line item"
          key={menu.name}
          button
          disableRipple
          selected={currentTab === i}
          className="m-0 text-center"
          style={{ flexDirection: "column", flexWrap: "wrap" }}
          onClick={() => {
            handleMenu(i);
          }}
        >
          {menu.name}
        </ListItem>
      ))}
    </List>
  );
}

export default TableTabs;
