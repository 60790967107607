import React, { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import "./style.css";
// import client from 'api/apiAuth/guestClient';
import Swal from "sweetalert2";
import { Button, Checkbox } from "@material-ui/core";
import SimpleModal from "../SimpleModal";

const redirectToEdit = (e, row, history) => {
  e.stopPropagation();
  history.push(`/programs/${row.form_id}/submissions/${row.id}/edit`);
};

function ColumnsRepresentation({
  cellType,
  index,
  value,
  color,
  history,
  row,
  column,
  selectedRows,
  setSelectedRows,
  setSelectedCheckbox,
  selectedCheckbox,
  rowIndex,
  currentTab,
  styleClass,
  propsFunctions,
}) {
  // const approveTask = (e, row, history, setSubmitTaskLoader) => {
  //   e.stopPropagation();
  //   setSubmitTaskLoader(true);
  //   client
  //     .post('workflow/submittask', {
  //       transition_id: 5,
  //       task_id: row.taskId,
  //       comment: ''
  //     })
  //     .then((res) => {
  //       setSubmitTaskLoader(false);
  //       Swal.fire({
  //         text: 'تم الموافقة على الطلب',
  //         icon: 'success'
  //       }).then(function (result) {
  //         history.push('/worklist');
  //       });
  //     });
  // };

  const [openModal, setOpenModal] = useState(false);

  const toggleCheckboxValue = (e, selectedIndex) => {
    e.stopPropagation();
    let selectedTasks = [...selectedRows];
    if (selectedCheckbox[selectedIndex]) {
      //when uncheck row,remove it from selected rows
      selectedTasks = selectedTasks.filter(
        (task) => task.taskId !== row.taskId
      );
      setSelectedRows([...selectedTasks]);
    } else {
      if (selectedTasks.length >= 5) {
        Swal.fire({
          text: "Sorry, The maximum number of selections is 5 requests",
          icon: "warning",
        });
        return;
      }
      selectedTasks.push({
        taskId: row.taskId,
        taskStepId: row.step_id,
        submissionId: row.submission_id,
      });
      setSelectedRows([...selectedTasks]);
      setOpenModal(true);
    }

    setSelectedCheckbox([
      ...selectedCheckbox.map((item, checkboxIndex) =>
        checkboxIndex === selectedIndex ? !item : item
      ),
    ]);
  };
  const handleClickButton = (event, row) => {
    event.stopPropagation();
    propsFunctions[column.function](row);
  };

  const ButtonIcon = column.icon;

  try {
    if (column.itype === "date") {
      const DATE_OPTIONS = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      value = value
        ? new Date(value).toLocaleDateString("en-US", DATE_OPTIONS)
        : value;
    }
  } catch (err) {
    value = null;
  }

  const cells = {
    text: (
      <TableCell key={index} style={column.style || {}}>
        <div
          className={`d-flex  ${color ? color : ""} ${
            row["status"] === value || row["remaining.number"] === value
              ? row.task_status
              : ""
          }`}
        >
          <div className="align-center">{value || "NA"}</div>
        </div>
      </TableCell>
    ),
    number: (
      <TableCell key={index} style={column.style || {}}>
        <div className={`d-flex  ${color ? color : ""}`}>
          <div className="align-center">#{value}</div>
        </div>
      </TableCell>
    ),

    image: (
      <TableCell key={index} className="text-center" style={column.style || {}}>
        <div
          className="avatar-icon-wrapper avatar-icon-sm"
          title="Lili Pemberton"
        >
          <div className="avatar-icon">
            <img alt="..." src={value} />
          </div>
        </div>
      </TableCell>
    ),
    badge: (
      <TableCell key={index} className="text-center" style={column.style || {}}>
        <span className={`badge light bg-${styleClass} opacity-75 text-light`}>
          {value}
        </span>
      </TableCell>
    ),
    date: (
      <TableCell key={index} className="text-center">
        <div className="align-center">{value}</div>
      </TableCell>
    ),
    editIcon: (
      <TableCell
        className="text-center"
        key={index}
        onClick={(e) => redirectToEdit(e, row, history)}
        style={column.style || {}}
      >
        <EditIcon className="edit-icon" />
      </TableCell>
    ),
    button: (
      <TableCell className="text-center" key={index} style={column.style || {}}>
        <Button
          variant="contained"
          className="rounded-lg"
          onClick={(event) => handleClickButton(event, row)}
          color={column?.color}
          disabled={column.constraints.disable}
        >
          {column?.text}
          {column?.icon && (
            <span>
              <ButtonIcon />
            </span>
          )}
        </Button>
      </TableCell>
    ),

    approveIcon: (
      <TableCell
        className="text-center"
        key={index}
        style={column.style || {}}
        // onClick={(e) => approveTask(e, row, history, setSubmitTaskLoader)}
      >
        <CheckIcon />
      </TableCell>
    ),

    checkIcon: (
      <TableCell className="text-center" key={index}>
        <Checkbox
          // name={}
          checked={selectedCheckbox[rowIndex] || false}
          onClick={(e) => toggleCheckboxValue(e, rowIndex)}
        />
      </TableCell>
    ),
  };
  return <>{cells[cellType]}</>;
}
export default ColumnsRepresentation;
