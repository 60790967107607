import {
  FETCH_WORKLST,
  FETCH_WORKLST_LAYOUT,
  GET_SUBMISSIONS_IDS,
  FETCH_FORMS,
  FETCH_FILTER_DATA,
  FETCH_FILTER_DATA_MODEL,
  FETCH_TIMESLOTS_SCHEDULE,
  FETCH_USER_DATA,
  FETCH_ENTITY_DATA_AUX_1,
  FETCH_ENTITY_DATA_AUX_2,
  FETCH_MODEL_DATA,
  UPDATE_USER_DATA,
  DELETE_USER_DATA,
  CREATE_USER_DATA,
  UPDATE_ENTITY_DATA,
  DELETE_ENTITY_DATA,
  CREATE_ENTITY_DATA
} from "./listActionTypes";
import client from "api/apiAuth/guestClient";

export const fetchWorklist = function (params) {
  return {
    type: FETCH_WORKLST,
    payload: client.get("tasks/find", { params }),
  };
};

export const fetchWorklistLayout = function (params) {
  return {
    type: FETCH_WORKLST_LAYOUT,
    payload: client.get("app/getTableData", { params }),
  };
};
export const fetchFilterData = function (params) {
  return {
    type: FETCH_FILTER_DATA,
    payload: client.get("app/getFilterData", { params }),
  };
};

export const fetchForms = function (params) {
  return {
    type: FETCH_FORMS,
    payload: client.get("generic/find", { params }),
  };
};
export const fetchFilterDataModel = function (params) {
  return {
    type: FETCH_FILTER_DATA_MODEL,
    payload: client.get("generic/find", { params }),
  };
};
// export const AddFilter = function (params) {
//   return {
//     type: FETCH_WORKLST_LAYOUT,
//     payload: client.get("app/getTableData", { params }),
//   };
// };
export const getSubmissionIDs = function (params) {
  return {
    type: GET_SUBMISSIONS_IDS,
    payload: client.get("workflow/getCompleteReqTasks", {
      params,
    }),
  };
};
export const fetchUserData = function () {
  return {
    type: FETCH_USER_DATA,
    payload: client.get("User/getUsers"),
  };
};
export const fetchEntityDataAux1 = function (params) {
  return {
    type: FETCH_ENTITY_DATA_AUX_1,
    payload: client.get("generic/find", { params }),
  };
};
export const fetchEntityDataAux2 = function (params) {
  return {
    type: FETCH_ENTITY_DATA_AUX_2,
    payload: client.get("generic/find", { params }),
  };
};
export const fetchModelData = function (params) {
  return {
    type: FETCH_MODEL_DATA,
    payload: client.get("generic/find", { params }),
  };
};
export const updateUserData = function (body) {
  return {
    type: UPDATE_USER_DATA,
    payload: client.post("user/updateUser", body),
  };
};
export const deleteUserData = function (id) {
  return {
    type: DELETE_USER_DATA,
    payload: client.delete(`user/${id}`),
  };
};
export const createUserData = function (userData) {
  return {
    type: CREATE_USER_DATA,
    payload: client.post(`user/createUser`,userData),
  };
};
export const updateEntityData = function (body) {
  return {
    type: UPDATE_ENTITY_DATA,
    payload: client.post("generic/updateInstance", body),
  };
};
export const deleteEntityData = function (params) {
  return {
    type: DELETE_ENTITY_DATA,
    payload: client.delete(`generic/destroy`, {params}),
  };
};
export const createEntityData = function (params) {
  return {
    type: CREATE_ENTITY_DATA,
    payload: client.post(`generic/createInstance`,{params}),
  };
};
export const fetchTimeSlotsShedule = function (params) {
  return {
    type: FETCH_TIMESLOTS_SCHEDULE,
    payload: client.get("timeSlots/getTimeSlotsShedule", { params }),
  };
};
