/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { GetStepContent } from "components/FormSteps/getStepContent";
import * as certificates from "certificates/index";
import {
  FormControl,
  TextareaAutosize,
  Button,
  Grid,
  Card,
  List,
  ListItem,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormik } from "formik";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import _ from "lodash";
import { enableSubmitButton } from "reducers/form/formActions";
import { getAttachments } from "reducers/details";
import clsx from "clsx";
import { submitTask } from "../api";
import Assign from "./Assign";
import { determineDisplayingForSections } from "components/FormSteps/utils";

const TransitionForm = ({
  validationSchema,
  selectedTransition,
  attachmentsUnderReview,
  submission,
  initialValues,
  setOpen,
  task_id,
  enableSubmitButton,
  user,
  getTaskDetails,
  getTimeline,
  databaseReviewedAttachment,
  getAttachments,
  attachmentsLoader,
  edit_form,
}) => {
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkListFormFlag, setCheckListFormFlag] = useState(true);
  const [submitFlag, setSubmitFlag] = useState(true);
  const [warningFlag, setWarningFlag] = useState(false);
  //ToFix: needs to be more generic
  const [values, setValues] = useState({});
  const [hiddenSections, setHiddenSections] = useState([]);
  const [certificateActiveTab, setCertificateActiveTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState();

  let [divRefs, setDivRefs] = useState(null);

  const prevTransitionRef = useRef();
  const displaySectionObj = useRef(null);

  const formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask();
    },
  });

  const toggle = (tab) => {
    if (certificateActiveTab !== tab) setCertificateActiveTab(tab);
  };
  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  const setInputKey = (section) => (section.isMany ? "multi_key" : "key");

  useEffect(() => {
    prevTransitionRef.current = selectedTransition;
  });

  const prevTransition = prevTransitionRef.current;

  useEffect(() => {
    if (selectedTransition) {
      setHiddenSections(
        selectedTransition?.sections?.filter(
          (section) => section?.hidden === true
        )
      );
      if (
        selectedTransition.sections?.length &&
        prevTransition?.sections?.length
      )
        prevTransition.sections.forEach((section) => {
          const foundSection = selectedTransition.sections.find(
            (sec) => sec.id === section.id
          );
          if (foundSection) {
            section.inputs.forEach((input) => {
              const searchKey = setInputKey(section);
              const foundInput = foundSection.inputs.find(
                (ele) => ele[searchKey] === input[searchKey]
              );

              if (!foundInput)
                formik.setFieldValue(input[searchKey], undefined);
            });
          } else {
            section.inputs.forEach((input) => {
              const searchKey = setInputKey(section);
              formik.setFieldValue(input[searchKey], undefined);
            });
          }
        });
      else formik.resetForm();
    }
    setCheckListFormFlag(true);
  }, [selectedTransition]);
  useEffect(() => {
    //ToFix:needs to be more generic
    if (
      !edit_form &&
      !_.isEmpty(selectedTransition) &&
      !selectedTransition.is_rejection
    ) {
      let filteredAttachments = attachmentsUnderReview.filter(
        (attachment) => !attachment.passWithRejection
      );

      getDatabaseReviewedAttachments(filteredAttachments);
    } else {
      setSubmitFlag(true);
      setWarningFlag(false);
    }
  }, [selectedTransition, attachmentsUnderReview]);

  useEffect(() => {
    let result = databaseReviewedAttachment.every(
      (attachment) => attachment.is_reviewed
    );
    let filteredAttachments = attachmentsUnderReview.filter(
      (attachment) => !attachment.passWithRejection
    );
    if (result) {
      let currentFilesApproved = filteredAttachments.some(
        (attachment) => !attachment.is_reviewed
      );
      if (currentFilesApproved) {
        setWarningFlag(true);
      } else setWarningFlag(false);
      setSubmitFlag(true);
    } else {
      let currentFilesApproved = filteredAttachments.every(
        (attachment) => attachment.is_reviewed
      );
      if (currentFilesApproved) {
        setWarningFlag(true);
      } else setWarningFlag(false);
      setSubmitFlag(false);
    }
  }, [databaseReviewedAttachment, attachmentsUnderReview]);

  const renderCertificates = () => {
    const templates = selectedTransition.template_name?.template.split(",");
    const templatesName = selectedTransition.template_name?.name.split(",");
    return (
      <>
        {selectedTransition.template_name && (
          <Card className="shadow-xxl p-3">
            <List component="div" className="nav-line d-flex nav-tabs-primary">
              {templatesName.map((template, i) => (
                <ListItem
                  key={i}
                  button
                  disableRipple
                  selected={certificateActiveTab === i}
                  onClick={() => {
                    toggle(i);
                  }}
                >
                  <span>{template}</span>
                  <div className="divider" />
                </ListItem>
              ))}
            </List>
            {templates.map((template, i) => {
              const Template = certificates[template];
              return (
                <div
                  key={i}
                  className={clsx("tab-item-wrapper", {
                    active: certificateActiveTab === i,
                  })}
                  index={0}
                >
                  <Template
                    drug={submission.drugs}
                    letter={submission}
                    values={values}
                    formValues={formik.values}
                    setValues={setValues}
                    divRef={(element) => {
                      return (divRefs[template] = element);
                    }}
                    sections={hiddenSections}
                    setDivRef={setDivRefs}
                    form={formik}
                    user={user}
                    setCheckListFormFlag={setCheckListFormFlag}
                  />
                </div>
              );
            })}
          </Card>
        )}
      </>
    );
  };
  const getDatabaseReviewedAttachments = async (attachmentsReviewed) => {
    let attachments = attachmentsReviewed.map((attachment) => attachment.id);
    getAttachments({ attachments });
  };

  const handleSubmitTask = async () => {
    if (warningFlag) {
      setOpen(false);
      Swal.fire({
        title: "This procedure cannot be performed",
        text: "Please save your approval on the files",
        confirmButtonColor: "#d36467",
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    let meta;
    if (divRefs && !_.isEmpty(divRefs)) {
      Object.values(divRefs).forEach((divRef) => {
        const el = divRef?.querySelectorAll("textarea");
        const radioButtons = divRef?.querySelectorAll(
          'input[type="radio"][name="sampling"]:checked'
        );

        let selectInput = divRef?.querySelector("select");
        if (selectInput) {
          selectInput.options[selectInput.selectedIndex].setAttribute(
            "selected",
            "selected"
          );
        }
        if (radioButtons) {
          divRef
            ?.querySelectorAll('input[type="radio"][name="sampling"]:checked')
            .forEach((input) => {
              input.setAttribute("checked", "checked");
            });
        }
        if (el) {
          el.forEach((item) => {
            item.style.borderColor = "Transparent";
            item.style.borderStyle = "none";
            item.style.resize = " none";
          });
        }
      });
      meta = Object.fromEntries(
        Object.entries(divRefs).map(([key, value]) => [key, value.innerHTML])
      );
    }
    if (submitFlag) {
      setLoading(true);
      try {
        await submitTask(
          selectedTransition.id,
          submission.id,
          submission.form_id,
          task_id,
          comment,
          formik.values,
          meta,
          selectedUser
        );

        setLoading(false);
        getTaskDetails({ submission_id: submission.id });
        getTimeline({ submission_id: submission.id });
        setOpen(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setOpen(false);
      Swal.fire({
        title: "This procedure cannot be performed",
        text: "You must agree to all attachments to take this procedure",
        confirmButtonColor: "#d36467",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  useEffect(() => {
    if (selectedTransition?.sections)
      displaySectionObj.current = determineDisplayingForSections(
        selectedTransition?.sections
      );
  }, [selectedTransition]);
  return (
    <>
      <div>
        {selectedTransition?.sections?.length && displaySectionObj.current && (
          <>
            <Grid
              container
              style={{
                justifyContent: "flex-end !important",
                alignItems: "left",
              }}
              spacing={2}
            >
              <Grid item md={12}>
                {selectedTransition.sections.map((section, index) => {
                  return (
                    <React.Fragment key={index}>
                      {!section.hidden &&
                        GetStepContent({
                          formik,
                          activeStep: 0,
                          steps: [section],
                          submission,
                          displaySectionObj: displaySectionObj.current,
                        })}
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}
        {selectedTransition.with_assign && (
          <Assign
            transition={selectedTransition}
            submissionId={submission.id}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        )}
        {renderCertificates()}
        <span className="heading-4 d-block mt-4 comment">
          Write Your Comment
        </span>
        <FormControl>
          <TextareaAutosize
            value={comment}
            onChange={handleSetComment}
            minRows={"5"}
            cols={"125"}
            className="txt-area"
          />
        </FormControl>
      </div>
      <div className="pt-3">
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Button
              className="mx-1 px-5 py-2"
              size="large"
              color="primary"
              variant="contained"
              style={{ borderRadius: "5px" }}
              onClick={(e) => {
                enableSubmitButton();
                formik.handleSubmit(e);
              }}
              disabled={
                !selectedTransition.id ||
                !checkListFormFlag ||
                attachmentsLoader ||
                (selectedTransition?.sections?.some((section) =>
                  section?.inputs.some((e) => !e.disabled)
                ) &&
                  selectedTransition?.sections?.some((section) =>
                    section?.inputs.some((e) => e.required)
                  ) &&
                  !(formik.dirty && formik.isValid)) ||
                loading ||
                (selectedTransition.with_assign && !selectedUser)
              }
            >
              <span className="btn-wrapper--label ml-2 font-size">Submit</span>
              {loading && (
                <ClipLoader color={"var(--primary)"} loading={true} />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    databaseReviewedAttachment: state.details.databaseReviewedAttachment,
    attachmentsLoader: state.details.attachmentsLoader,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    getAttachments: (params) => dispatch(getAttachments(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionForm);
