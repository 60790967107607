import React, { useState, useEffect } from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Grid, Card, Button, makeStyles, Tooltip } from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
const USE_OLD = false;

const Program = function ({ form, companyAllowedSubmissionCount }) {
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    cardStyle: {
      minHeight: "5vh",
      justifyContent: "center",
      "&:hover": {
        borderColor: form?.style.hoverColor,
        border: "1px solid",
        cursor: "pointer",
      },
    },
  }));
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item xs={12} md={3} lg={3} sm={6} className="gridStyle">
        <Card className={classes.cardStyle + " align-items-center"}>
          <Tooltip
            title={
              companyAllowedSubmissionCount != null &&
              companyAllowedSubmissionCount <= 0
                ? "You have exceeded the submission limit "
                : ""
            }
            arrow
            placement="right"
          >
            <Link
              to={
                companyAllowedSubmissionCount != null &&
                companyAllowedSubmissionCount <= 0
                  ? "#"
                  : `/programs/${form.id}`
              }
            >
              <Button
                className="d-block  border-0  buttonStyle"
                disabled={
                  companyAllowedSubmissionCount != null &&
                  companyAllowedSubmissionCount <= 0
                }
              >
                <span className="d-block iconStyle">
                  <FontAwesomeIcon icon={["fa", form?.style.icon]} />
                </span>
                <span
                  className={`text-uppercase textStyle d-block h5 ${
                    form.name.length > 11 && "textSize"
                  }`}
                >
                  {form.name}
                </span>
                <p className="discFontSize">{form.style.description}</p>
              </Button>
            </Link>
          </Tooltip>
        </Card>
      </Grid>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
    user: state.auth.user,
    companyAllowedSubmissionCount:
      state.settings.settings.companyAllowedSubmissionCount,
  };
};

export default connect(mapStateToProps)(Program);
