import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
} from "@material-ui/core";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { createUserData, updateUserData,createEntityData,updateEntityData } from "reducers/list";
import EditInput from "./Components/EditInput";
import ChangePassModal from "./Components/ChangePassModal";
import uploadFile from "pages/AdminPanel/AdminPanel/Utils/uploadFile";
import Signature from "./Components/Signature";
import validationSchema from "pages/AdminPanel/AdminPanel/Utils/validationSchema";
import { useSelector } from "react-redux";
import { getValue } from "utils/GetObjectValues";
import {  Done, Clear } from "@material-ui/icons";


const UserModal = (props) => {
  const {
    isEdit,
    entity,
    entityIndex,
    open,
    setModalOpen,
    // eslint-disable-next-line no-unused-vars
    entityDataAux1,
    entityDataAux2,
    onEntityEdited,
    onEntityCreated,
    model,
    entityName
  } = props;
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({});
  const [submitFlag, setSubmitFlag] = useState(false);
  const [validImage, setValidImage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState({});
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [inputEntity, setInputEntity] = useState({});
  
  useEffect(() => {
    if (entity) {
      setInitialValues({ ...entity });
      setInputEntity({ ...entity });
    }
  }, [entityDataAux2, open, entity]);
  
  useEffect(() => {
    _.isEqual(entity, inputEntity) ? setSubmitFlag(false) : setSubmitFlag(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputEntity]);
  
  useEffect(() => {
    if (validImage) {
      if (!_.isEmpty(file) && entity) {
        uploadFile(file.file, file.fileName,setProgress,dispatch).then((result) => {
          setInputEntity({ ...entity, ...inputEntity, [file.fileName]: result.data });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validImage, file, dispatch]);
  
  const formik = useFormik({
    validationSchema,
    initialValues,
    validateOnChange: true,
    validationOnBlur: true,
  });
  useEffect(() => {
    if (!getValue(formik.errors, "email") && 
    !getValue(formik.errors, "username") && 
    getValue(formik.values, "email") && 
    getValue(formik.values, "username"))
    {
      setSubmitFlag(true)
    } else {
      setSubmitFlag(false)
    }
  }, [formik.errors,formik.values])
  console.log(formik.errors)
  // console.log(inputEntity)
  const onEditInputChange = (ev,field,all) =>  {
    let entity = [] || {} || "";
    if(all){
    for (const one of all) {
      if (ev.target.value === one.name) {
        if( field === "roles"){
          entity.push(one)
        }else{
        entity= one;
        break;
      }
    }
  }
}else{
  entity = ev.target.value
}
    setInputEntity(inputEntity => ({
      ...inputEntity,
      [field]:entity
    }));
    setSubmitFlag(true);
  }
  const handleChangePassword = () =>{
    setModalPreviewOpen(true)
  }
  const setImages = (event) => {
    event.persist();
    let fileName = event.target.name,
      file = event.target.files[0];
    var img = document.createElement("img");
    let width, height;
    img.onload = function () {
      file.width = this.width;
      file.height = this.height;
      width = this.width >= 50 && this.width <= 1000;
      height = this.height >= 50 && this.height <= 1000;
      if (width && height) {
        setFile({ file, fileName });
        setValidImage(true);
      } else {
        setSubmitFlag(false);
        setValidImage(false);
      }
      formik.setFieldValue(event.target.name, file);
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleClose = () => {
    setInputEntity({});
    formik.resetForm();
    setSubmitFlag(false);
    setModalOpen(false);
  };

  const handleSubmit = () => {
      try {
        
          let modelData = {
            ...inputEntity
          };
          (entityName==="users"? (isEdit? dispatch(updateUserData(modelData)) : dispatch(createUserData(modelData))): (isEdit? dispatch(updateEntityData({model,modelBody:modelData})) : dispatch(createEntityData({model,modelData}))))
          .then((response) => {
            if (response.value.status === 200) {
              Swal.fire({
                title: entityName==="users"? (isEdit? "Update changed" : "User Created") : (isEdit? "Update changed" : `${model} Created`),
                text: entityName==="users"? (isEdit? "User saved" : "New User has arrived"): (isEdit? `${model} saved` : `New ${model} has been created`),
                icon: "success",
                confirmButtonColor: "#003655",
                confirmButtonText: "back",
              }).then(function () {
                isEdit? 
                onEntityEdited(entityIndex, inputEntity)
                :(entityName==="users"? onEntityCreated(inputEntity,response?.value?.data?.user?.id)
                : 
                 onEntityCreated(inputEntity,response?.value?.data?.newInstance?.id));
                setInputEntity({});
                formik.resetForm();
                setModalOpen(false);
              });
            } else {
              
              Swal.fire({
                title: entityName==="users"? (isEdit? "Update Failed" : "Could not create a User"): (isEdit? "Update Failed" : `Could not create a ${model}`) ,
                icon: "error",
                confirmButtonColor: "#d36467",
                confirmButtonText: "Try Again",
              }).then(function () {
                setModalOpen(false);
              });
            }
          });
      } catch (err) {
        console.log(err);
      }
  };
  
  const getPropsFromStore = (state, param) => {
    return (
      state.layout?.columns?.Admin?.filter(
        (a)=>a[entityName]).flatMap(
          (A) => A[entityName]?.Columns?.map(
            a => a?.[param]))
          )
  }
  const keys = useSelector((state) => getPropsFromStore(state,"key"))
  const rowName = useSelector((state) => getPropsFromStore(state,"name"))
  const edit = useSelector((state) => getPropsFromStore(state,"edit"))
  const type = useSelector((state) => getPropsFromStore(state,"type"))
  const title = useSelector((state) => getPropsFromStore(state,"title"))
  const data = useSelector((state) => getPropsFromStore(state,"data"))

  function renderAdminTable(key,type,title,i,listing,entdata,name) {
    if(listing)
    switch (type) {
      case 'array':
        return(
          <EditInput 
          key={i}
          title= {`${name}:`} 
          value={inputEntity[key]?.length> 0 ? inputEntity[key][0]?.[title] : ""}
          onChange={(ev)=> {onEditInputChange(ev,`${key}`,props[entdata])}}
          menuItems= {props[entdata]}
/> 
        )
      case 'object':
        return(
          <EditInput 
          key={i}
          title={`${name}:`}
          value={inputEntity[key]?.[title] || ""}
          onChange={(ev)=> {onEditInputChange(ev,`${key}`,props[entdata])}}
          menuItems={props[entdata]}
          
/>
        )
      case 'signature':
        return(
          <Signature 
          key={i}
          formik={formik}
          setImages={setImages}
          progress={progress}
          rowName={name}
          name={key}
        />
        )
      case 'password':
        return(
        <ChangePassModal
        key={i}
        open={modalPreviewOpen}
        setOpen={setModalPreviewOpen}
        handleChangePassword={handleChangePassword}
        setSubmitFlag={setSubmitFlag}
        formik={formik}
        setInputEntity={setInputEntity}
        isEdit={isEdit}
        />
        )
      default:
        return(
          <>
          <EditInput 
          key={i}
          title={`${name}:`} 
          value={inputEntity?.[key] || ""}
          onChange={(ev)=> {onEditInputChange(ev,`${key}`)}}
          
/>
{getValue(formik.values, `${inputEntity?.[key]}`) && (
  <div
    className={
      getValue(formik.errors, `${inputEntity?.[key]}`)
        ? "text-danger small"
        : "text-success small"
    }
  >
    {getValue(formik.errors, `${inputEntity?.[key]}`)
      ? <>
        <Clear />
        {getValue(formik.errors, `${inputEntity?.[key]}`)}
      </>
      : <> <Done /> Good To GO!</>
    }
  </div>
        )}
        </>
        
        ) ;
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="text-center">
        {isEdit ? `Edit ${model}` : `Add ${model}`}
      </DialogTitle>
      <DialogContent className="p-5">
        <Grid container spacing={3}>
        
        {keys?.map((k, i) => (
                renderAdminTable(k,type[i],title[i], i, edit[i],data[i],rowName[i])
            ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          <Button
            onClick={handleClose}
            color="secondary"
            size="medium"
            variant="contained"
            className="ml-4 mx-4"
            style={{ backgroundColor: "#0b0b0c" }}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="medium"
            disabled={!submitFlag}
            variant="contained"
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
