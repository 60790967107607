import {
  FETCH_USER_DATA_PENDING,
  FETCH_USER_DATA_FULFILLED,
  FETCH_USER_DATA_REJECTED,
  FETCH_ENTITY_DATA_AUX_1_PENDING,
  FETCH_ENTITY_DATA_AUX_1_FULFILLED,
  FETCH_ENTITY_DATA_AUX_1_REJECTED,
  FETCH_ENTITY_DATA_AUX_2_PENDING,
  FETCH_ENTITY_DATA_AUX_2_FULFILLED,
  FETCH_ENTITY_DATA_AUX_2_REJECTED,
  FETCH_MODEL_DATA_PENDING,
  FETCH_MODEL_DATA_FULFILLED,
  FETCH_MODEL_DATA_REJECTED,
  UPDATE_USER_DATA_PENDING,
  UPDATE_USER_DATA_FULFILLED,
  UPDATE_USER_DATA_REJECTED,
  DELETE_USER_DATA_PENDING,
  DELETE_USER_DATA_FULFILLED,
  DELETE_USER_DATA_REJECTED,
  CREATE_USER_DATA_PENDING,
  CREATE_USER_DATA_FULFILLED,
  CREATE_USER_DATA_REJECTED,
  UPDATE_ENTITY_DATA_PENDING,
  UPDATE_ENTITY_DATA_FULFILLED,
  UPDATE_ENTITY_DATA_REJECTED,
  DELETE_ENTITY_DATA_PENDING,
  DELETE_ENTITY_DATA_FULFILLED,
  DELETE_ENTITY_DATA_REJECTED,
  CREATE_ENTITY_DATA_PENDING,
  CREATE_ENTITY_DATA_FULFILLED,
  CREATE_ENTITY_DATA_REJECTED,
} from "./listActionTypes";
const initialState = {
  error: null,
  User: [],
  EntityDataAux1: [],
  EntityDataAux2: [],
  Model:[],
  loading: false,
  Response: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_USER_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        User: action.payload.data.users,
      };
    }
    case FETCH_USER_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_ENTITY_DATA_AUX_1_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ENTITY_DATA_AUX_1_FULFILLED: {
      return {
        ...state,
        loading: false,
        EntityDataAux1: action.payload.data.rows,
      };
    }
    case FETCH_ENTITY_DATA_AUX_1_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_ENTITY_DATA_AUX_2_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_ENTITY_DATA_AUX_2_FULFILLED: {
      return {
        ...state,
        loading: false,
        EntityDataAux2: action.payload.data.rows,
      };
    }
    case FETCH_ENTITY_DATA_AUX_2_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_MODEL_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_MODEL_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        Model: action.payload.data.rows,
      };
    }
    case FETCH_MODEL_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case UPDATE_USER_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_USER_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        // Response: action.payload,
      };
    }
    case UPDATE_USER_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DELETE_USER_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_USER_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        Response: action.payload,
      };
    }
    case DELETE_USER_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CREATE_USER_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_USER_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        Response: action.payload,
      };
    }
    case CREATE_USER_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case UPDATE_ENTITY_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_ENTITY_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        // Response: action.payload,
      };
    }
    case UPDATE_ENTITY_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DELETE_ENTITY_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_ENTITY_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        Response: action.payload,
      };
    }
    case DELETE_ENTITY_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case CREATE_ENTITY_DATA_PENDING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CREATE_ENTITY_DATA_FULFILLED: {
      return {
        ...state,
        loading: false,
        Response: action.payload,
      };
    }
    case CREATE_ENTITY_DATA_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
