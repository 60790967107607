import React, { useState, useEffect } from "react";
import { Grid, StylesProvider, jssPreset, Card } from "@material-ui/core";
import "./style.css";
import { create } from "jss";
import rtl from "jss-rtl";
import { connect } from "react-redux";
import FormSteps from "components/FormSteps";
import getSchema from "utils/setValidationSchema";

import {
  getDraft,
  getFormLayout,
  setFormName,
  handleDraftAssignment,
} from "reducers/form/formActions";
import { useLocation } from "react-router-dom";
import SpinnerLoader from "components/SpinnerLoader";
import client from "api/apiAuth/guestClient";
import sanatizeObject from "utils/SanitizeObject";
import { handleError } from "api/utilities";
import { Alert } from "@material-ui/lab";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function ContinueSubmission(props) {
  const {
    getLayout,
    getDraft,
    form,
    match,
    history,
    form_layout_loading,
    setFormName,
    task,
  } = props;
  const [validationSchema, setValidationSchema] = useState({});
  const [draftValidationSchema, setDraftvalidationSchema] = useState({});
  // state of confirm modal
  const { formId, id } = match.params;
  const [initialValues, setInitialValues] = useState({});
  const [remainingTime, setRemainingTime] = useState();

  const getRemainingTime = async () => {
    let { data } = await client.get(`/submission/getRemainingTime`, {
      params: { sub_id: id, formId },
    });
    setRemainingTime(data.remaining_time);
  };
  useEffect(() => {
    if (id) getRemainingTime();
    setInitialValues({});
    getLayout({ formId });
    setFormName("");
  }, []);
  useEffect(() => {
    if (form) {
      setValidationSchema(getSchema(false));
      setDraftvalidationSchema(getSchema(true));
    }
  }, [form]);

  return (
    <StylesProvider jss={jss}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} className="form-container ">
          {remainingTime ? (
            <Alert style={{ marginBottom: 5 }} severity="warning">
              The remaining time is {remainingTime}
            </Alert>
          ) : null}
          <Card className="card-box form-container">
            <div className="card-body p-0 ">
              {form_layout_loading ? (
                <SpinnerLoader />
              ) : (
                <>
                  <FormSteps
                    form={form.sections}
                    formId={formId}
                    validationSchema={validationSchema}
                    draftValidationSchema={draftValidationSchema}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    submissionId={id}
                    history={history}
                    config={{
                      continue: {
                        file_code: task.submission.file_code,
                        sub_id: task.submission.id,
                      },
                    }}
                  ></FormSteps>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </StylesProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    task: state.details.task,
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
    user: state.auth.user,
    users: state.details.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
    getDraft: (params) => dispatch(getDraft(params)),
    setFormName: (name) => dispatch(setFormName(name)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContinueSubmission);
