import React from "react";
import { List, ListItem, Badge } from "@material-ui/core";
import "./style.css";
import { withStyles } from "@material-ui/core/styles";
export default function TableTabs({
  setFilters,
  setSort,
  setCurrentTab,
  setLayoutData,
  currentTab,
  menus,
}) {
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -11,
      top: 3,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }))(Badge);

  const handleMenu = (menu) => {
    if (currentTab !== menu.id) {
      setLayoutData([]);
      setCurrentTab(menu.id);
      setSort({ type: "", order: "none", orderBy: "" });
      setFilters([]);
    }
  };

  return (
    <>
      <List className="nav-tabs nav-tabs-primary d-flex p-0 tabs-list">
        {menus.map((menu) => {
          return (
            <ListItem
              primary="Single-line item"
              key={menu.id}
              defaultValue={menu[1]}
              button
              disableRipple
              selected={currentTab === menu.id}
              className="m-0 text-center"
              style={{ flexDirection: "column", flexWrap: "wrap" }}
              onClick={() => {
                handleMenu(menu);
              }}
            >
              {menu.badge ? (
                <StyledBadge badgeContent={menu.badge} max={99} color="primary">
                  {menu.name}
                </StyledBadge>
              ) : (
                menu.name
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
