import {
  FETCH_WORKLST_LAYOUT_PENDING,
  FETCH_WORKLST_LAYOUT_FULFILLED,
  FETCH_WORKLST_LAYOUT_REJECTED
} from './listActionTypes';

const initialState = {
  error: null,
  columns: [],
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKLST_LAYOUT_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_WORKLST_LAYOUT_FULFILLED: {
      return {
        ...state,
        loading: false,
        columns: action.payload.data.columns
      };
    }
    case FETCH_WORKLST_LAYOUT_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
}
