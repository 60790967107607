import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import SocketClient from "socket/socketClient";
import socketMiddleware from "socket/middleware";


const initialState = {
  auth: { user: JSON.parse(localStorage.getItem("user")), socketUpdate: null },
};
const socketClient = new SocketClient(initialState.auth.user);
socketClient.connect(
  process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : `${window.location.protocol}//${window.location.host}/api/`
);
const store = configureStore({
  reducer: rootReducer,
  middleware: [promise, thunk, socketMiddleware(socketClient)],
  devTools: true,
  preloadedState: initialState,
});
store.subscribe(() => {
  const {
    auth: { user },
  } = store.getState();
  let currentUser = JSON.stringify(user);
  currentUser != "null" &&
    currentUser !== undefined &&
    localStorage.setItem("user", currentUser);
});
export default store;
