import React, { useState, useEffect } from "react";
import { Card, Grid, Divider, CardHeader } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./index.module.css";
import client from "api/apiAuth/guestClient";
import DonutsChart from "../../components/Dashboard/DonutsChart";
import SpinnerLoader from "components/SpinnerLoader";
import { connect } from "react-redux";

const useStyles = makeStyles({
  rootCard: {
    backgroundColor: "#F4F9FF",
    width: " 87%",
    boxShadow: "none !important",
    height: "100%",
  },
});

const Dashboard = ({ forms }) => {
  const classes = useStyles();
  const [statCards, setStatCards] = useState([]);
  const [programsStatistics, setProgramsStatistics] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    const fetchStatistics = async () => {
      try {
        const response = await client.get("/dashboard/getDetails");
        if (isMounted) {
          setStatCards(response.data.statistics);
          setProgramsStatistics(response.data.programsStatistics);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchStatistics();
    return () => {
      isMounted = false;
    };
  }, []);

  const renderStatCards = (cards) => {
    return cards.map((card, i) => (
      <Grid container item md={6} lg={3} key={i}>
        <Card classes={{ root: classes.rootCard }}>
          <Grid container item sm={12} style={{ position: "relative" }}>
            <Grid
              item
              xs={8}
              md={7}
              className="p-2 "
              style={{ textAlign: "right !important" }}
            >
              <div className={styles["statcard__title"]}> {card.title} </div>
              <div className={styles["statcard__number"]}> {card.count} </div>
            </Grid>
            <Grid
              item
              xs={4}
              md={5}
              className="text-center"
              style={{ position: "absolute", bottom: 0, left: 0 }}
            >
              <div>
                <BarChartIcon className={styles["statcard__icon"]} />
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    ));
  };

  return (
    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Grid container>
          <Card className={`p-3 ${styles["statcard"]}`}>
            <h3> الاحصائيات </h3>
            <Divider />
            <Grid container item md={12} className="mt-2" spacing={2}>
              {renderStatCards(statCards)}
            </Grid>

            <h3 className="mt-3"> البرامج </h3>
            <Divider />
            <Grid container item md={12} className="mt-2 mr-2" spacing={2}>
              {forms.map((form) => {
                return (
                  <Grid item md={6} className="mt-2" key={form["name"]}>
                    <Card classes={{ root: classes.rootCard }} className="mb-2">
                      <CardHeader title={form["name"]} align="center" />
                      <DonutsChart
                        submissions={
                          programsStatistics.find(
                            (program) => program["form.name"] === form.name
                          )?.submissions
                        }
                      />
                      <h5 className="mr-3">
                        {"اجمالى الطلبات"}{" "}
                        {programsStatistics.find(
                          (program) => program["form.name"] === form.name
                        )?.Count || 0}
                      </h5>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Card>
        </Grid>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
  };
};
export default connect(mapStateToProps, null)(Dashboard);
