import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import client from "api/apiAuth/guestClient";
import { Link, useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const ScientificCommittee = ({ match }) => {
  const [expanded, setExpanded] = useState("panel1");
  const [scientificCommittee, setScientificCommittee] = useState({});
  const history = useHistory();
  // const formik = useFormik({
  //   validateOnChange: false,
  //   validationOnBlur: true,
  //   enableReinitialize: true,
  //   // validationSchema: yup.object().shape(validationSchema),
  //   initialValues: {},

  //   onSubmit: () => {
  //     handleSubmitTask();
  //   },
  // });

  const handleSubmitTask = async () => {
    await client.post("workflow/submittask", {
      transition_id:
        scientificCommittee?.submissions?.[0]?.tasks?.[0]?.step.transitions[0]
          .id,
      submission_id: scientificCommittee?.submissions?.[0].id,
      form_id: scientificCommittee?.submissions?.[0].form_id,
      tasks: [
        ...scientificCommittee?.submissions?.[0]?.tasks.map((task) => {
          return {
            taskId: task.id,
            comment: "",
            // submission_edit: formik.values,
            attachments: [],
          };
        }),
      ],
      assignTasksBeforeSubmit: true,
    });
    window.location.reload();
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getScientificCommittee = async () => {
    const result = await client.get(
      `submission/getScientificCommittee?id=${match.params.id}`
    );
    setScientificCommittee(result.data.scientificCommittee);
  };
  useEffect(() => {
    getScientificCommittee();
  }, []);

  return (
    <>
      <div>
        {scientificCommittee.submissions?.map((submission) => {
          return (
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Submission {submission.id}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p>File_number:{submission.file_number}</p>
                  {/* <InputWidget
                    formik={formik}
                    input={{
                      ...input,
                      key: input.key.replace("[i]", `${i}`),
                    }}
                    enableOnSubmit={enableOnSubmit}
                    setSectionNameFlags={setSectionNameFlags}
                  /> */}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
      {scientificCommittee.viewOnly ? (
        <></>
      ) : (
        <Grid
          style={{
            paddingTop: "15px",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            xs={12}
            className={"scientificButton"}
            disabled={false}
            onClick={handleSubmitTask}
          >
            submit Scientific Committee
          </Button>
        </Grid>
      )}
    </>
  );
};

export default ScientificCommittee;
