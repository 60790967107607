import client from "api/apiAuth/guestClient";

export const getWorklistTransitions = async function (stepsIds) {
  console.log(stepsIds);
  return (
    await client.post("workflow/fetchWorklistTransitions", {
      stepsIds,
    })
  ).data;
};

export const submitTask = async function (tasks, transition_id) {
  await client.post("workflow/submittask", {
    transition_id,
    tasks,
    assignTasksBeforeSubmit: true,
  });
};
