import React, { useState, useEffect } from "react";
import SysInputs from "./SysInputs";

import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  InputLabel,
  Input,
  Select,
  Button,
} from "@material-ui/core";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";

import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import { getSettingValidationSchema } from "utils/setValidationSchema";

const CustomSettings = ({ id }) => {
  const [validationSchema, setValidationSchema] = useState(null);
  const [allInputs, setAllInputs] = useState();
  const [initialValues, setInitialValues] = useState({});
  let formik;
  formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });

  let history = createBrowserHistory({ forceRefresh: true });
  const usetyles = makeStyles({
    list: {
      overflowY: "auto",
      margin: 0,
      padding: 0,
      listStyle: "none",
      height: "100%",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D5DBE0",
        borderRadius: 5,
      },
    },
  });
  const classes = usetyles();

  const getSettingInputs = async () => {
    const response = await client.get("settings/getSysSettingInputs", {
      params: {
        workflow_id: id,
      },
    });
    if (response.data.inputs) {
      setValidationSchema(
        getSettingValidationSchema(response.data.inputs, false)
      );
      setAllInputs(response.data.inputs);
    }
  };

  useEffect(() => {
    getSettingInputs();
  }, []);

  const submitSettings = async () => {
    const res = await formik.validateForm();

    if (!_.isEmpty(res) || _.isEmpty(formik.values)) {
      Swal.fire({
        title: "fields are either required or invalid",
        icon: "error",
        confirmButtonColor: "#d36467",
        confirmButtonText: "Try Again",
        width: "50%",
      });
    } else {
      // handleSubmit();
      client
        .post("/settings/submitSettings", {
          values: formik.values,
        })
        .then(() => {
          Swal.fire({
            title: "Your Settings has been saved successfully!",
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#003655",
            confirmButtonText: "Ok",
          }).then(() => history.push("/"));
        });
    }
  };

  return (
    <>
      <h6
        style={{
          paddingBottom: 15,
          textShadow: "2px 2px 8px rgba(0,0,0,.2)",
          color: "#003655",
        }}
      >
        Other Settings :
      </h6>
      <Grid Container>
        {allInputs ? (
          <>
            <FormikProvider value={formik}>
              <Grid
                container
                spacing={4}
                style={{ justifyContent: "space-between" }}
              >
                {allInputs.map((input) => (
                  <SysInputs formik={formik} input={input} />
                ))}
              </Grid>
            </FormikProvider>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="primary"
                className="m-5"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure you want to submit your Settings? ",
                    showConfirmButton: true,
                    titleHeight: 200,
                    showCancelButton: true,
                    confirmButtonClass: "submit",
                    cancelButtonClass: "cancel",
                    titleClass: "fontSize",
                    customClass: "swal-wide",
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
                    closeOnConfirm: false,
                  }).then(function (isConfirm) {
                    submitSettings();
                  });
                }}
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <div>
            <p>Loading</p>
          </div>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomSettings);
