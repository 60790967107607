import { createReducer } from "@reduxjs/toolkit";
import { getEventInputs } from "components/FormSteps/utils";
import { groupBy } from "lodash";

import {
  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_FULFILLED,
  SAVE_VALUES,
  ENABLE_FORM_SUBMIT_BUTTON,
  SAVE_FILES,
  GET_FORM_LAYOUT_PENDING,
  GET_FORM_LAYOUT_FULFILLED,
  SET_SUBMISSION,
  SAVE_DRAFT_PENDING,
  SAVE_DRAFT_FULFILLED,
  GET_DRAFT_PENDING,
  GET_DRAFT_FULFILLED,
  START_ENGINE_FULFILLED,
  START_ENGINE_PENDING,
  UPDATE_SUBMISSION_PENDING,
  UPDATE_SUBMISSION_FULFILLED,
  SET_FORM_NAME,
  HANDLE_DRAFT_ASSIGNMENT_PENDING,
  HANDLE_DRAFT_ASSIGNMENT_FULFILLED,
  DELETE_FILE_FULFILLED,
  DELETE_FILE_PENDING,
  SET_EVENTS_INPUTS,
  GET_SUBMISSION_PENDING,
  GET_SUBMISSION_FULFILLED,
  SUBMIT_UPDATE_TASK_PENDING,
  SUBMIT_UPDATE_TASK_FULFILLED,
  CHECKLIST_INPUTS,
  SET_DEPENDENT_INPUT_OPTIONS,
  ADD_TO_INITIAL_VALUES,
  SET_VIEW_SELECT_INPUTS,
  RESET_FORM,
} from "./formActionTypes";

const initialState = {
  form: { name: "", sections: [] },
  fileUploadError: null,
  submission: { attachments: [] },
  nationalIds: [],
  requestDetails: [{}, {}, {}, {}, {}],
  loading: false,
  form_layout_loading: false,
  enableSubmit: false,
  files: [],
  draftValues: null,
  submissionValues: {},
  validationSchema: {},
  inputEvents: [],
  viewSelectInputs: [],
  initialValueInputEvents: [],
  manyEntities: {},
  isRenew: false,
};

function setValue(obj, path, value) {
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexepaths to propertis
  path = path.replace(/^\./, ""); // strip a leading dot
  path = path.split(".");
  while (path.length - 1) {
    let n = path.shift();
    if (!(n in obj)) obj[n] = {};
    obj = obj[n];
  }
  obj[path[0]] = value;
}

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(UPLOAD_FILE_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(UPLOAD_FILE_FULFILLED, (state, action) => {
    state.loading = false;
    if (action.meta.document_id) {
      if (!state.submission) state.submission = {};
      if (!state.submission.attachments) state.submission.attachments = [];
      const oldAttachment = state.submission?.attachments?.find(
        (e) => e.name === action.meta.name
      );
      if (
        state.submission?.attachments &&
        Array.isArray(state.submission?.attachments)
      ) {
        state.submission.attachments = [
          ...state.submission?.attachments?.filter(
            (e) => e.name !== action.meta.name
          ),
          {
            ...action.payload.data.results,
            document_id: action.meta.document_id,
            name: action.meta.name,
            contact_id: action.meta.contact_id,
            original_file_name: action.meta.original_file_name,
            section: action.meta.section,
            sectionName: action.meta.sectionName,
            id: oldAttachment?.id,
            metadata: {
              ...action.payload.data.results.metadata,
              type: action.meta.type,
              preview: action.meta.preview,
            },
          },
        ];
      }
    } else {
      setValue(
        state.submission,
        action.meta.name,
        action.payload.data.results?.avatarFd
      );
    }
  });

  builder.addCase(SAVE_VALUES, (state, action) => {
    state.submission = { ...state.submission, ...action.payload };
  });
  builder.addCase(ENABLE_FORM_SUBMIT_BUTTON, (state, action) => {
    state.enableSubmit = true;
  });
  builder.addCase(SAVE_FILES, (state, action) => {
    state.files = [
      // ...state.files.filter(
      //   (e) => e.checklist_document_id !== action.payload.checklist_document_id
      // ),
      {
        file: action.payload.file,
        name: action.payload.name,
      },
    ];
  });

  builder.addCase(START_ENGINE_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(START_ENGINE_FULFILLED, (state, action) => {
    state.loading = false;
    state.submission = null;
    state.submissionValues = {};
    state.draftValues = null;
    state.enableSubmit = false;
  });

  builder.addCase(GET_FORM_LAYOUT_PENDING, (state, action) => {
    state.form_layout_loading = true;
  });
  builder.addCase(GET_FORM_LAYOUT_FULFILLED, (state, action) => {
    state.form_layout_loading = false;
    const inputEvents = [],
      viewSelectInputs = [];

    const groupChildSections = (sections) =>
      sections.map((section) => {
        let { child_sections, ...newSection } = section;
        section = { ...newSection, ...groupBy(child_sections, "type") };
        section.group = section.group && groupChildSections(section.group);
        section.step = section.step && groupChildSections(section.step);
        return section;
      });

    const addInputsEvents = (sections) => {
      sections.forEach((section) => {
        if (section.child_sections.length)
          addInputsEvents(section.child_sections);
        else
          section.inputs.forEach((input) => {
            if (input.events.length) inputEvents.push(input);
          });
      });
    };

    const addViewSelectInputs = (sections) => {
      sections.forEach((section) => {
        if (section.child_sections.length)
          addViewSelectInputs(section.child_sections);
        else
          section.inputs.forEach((input) => {
            if (input.input_layout.type === "view_select")
              viewSelectInputs.push(input);
          });
      });
    };

    state.form.sections = groupChildSections(action.payload.data.sections);
    addInputsEvents(action.payload.data.sections);
    addViewSelectInputs(action.payload.data.sections);
    state.inputEvents = inputEvents;
    state.viewSelectInputs = viewSelectInputs;

    state.initialValueInputEvents = getEventInputs(
      inputEvents,
      "onInitInitialValues"
    );

    // state.form.sections = action.payload.data.sections.map((section) => {
    //   let { child_sections, ...newSection } = section;
    //   section = { ...newSection, ...groupBy(child_sections, "type") };
    //   return section;
    // });
    state.form.name = action.payload.data.name;
  });

  builder.addCase(SET_SUBMISSION, (state, action) => {
    state.submission = action.payload;
  });
  builder.addCase(GET_DRAFT_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(GET_DRAFT_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = action.payload.data.draftValues;
  });
  builder.addCase(SAVE_DRAFT_PENDING, (state, action) => {
    state.loading = true;
    state.submission = {};
  });
  builder.addCase(SAVE_DRAFT_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = null;
  });

  builder.addCase(UPDATE_SUBMISSION_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(UPDATE_SUBMISSION_FULFILLED, (state, action) => {
    state.loading = false;
    state.draftValues = null;
  });
  builder.addCase(SET_FORM_NAME, (state, action) => {
    state.form.name = action.payload;
  });

  builder.addCase(HANDLE_DRAFT_ASSIGNMENT_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(HANDLE_DRAFT_ASSIGNMENT_FULFILLED, (state, action) => {
    state.loading = false;
  });
  builder.addCase(DELETE_FILE_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(DELETE_FILE_FULFILLED, (state, action) => {
    state.loading = false;
  });
  builder.addCase(SET_EVENTS_INPUTS, (state, action) => {
    const { input } = action.payload;
    const index = state.inputEvents.map((e) => e.id).indexOf(input.id);
    state.inputEvents[index] = input;
  });

  builder.addCase(SET_VIEW_SELECT_INPUTS, (state, action) => {
    const { input } = action.payload;
    const index = state.viewSelectInputs.map((e) => e.id).indexOf(input.id);
    state.viewSelectInputs[index] = input;
  });

  builder.addCase(GET_SUBMISSION_PENDING, (state, action) => {
    state.loading = true;
    state.isRenew = false;
  });
  builder.addCase(GET_SUBMISSION_FULFILLED, (state, action) => {
    state.loading = false;
    state.submissionValues = action.payload.data.draftValues;
    state.isRenew = action.meta.isRenew;
  });

  builder.addCase(SUBMIT_UPDATE_TASK_PENDING, (state, action) => {
    state.loading = true;
  });
  builder.addCase(SUBMIT_UPDATE_TASK_FULFILLED, (state, action) => {
    state.loading = false;
    state.submissionValues = {};
    state.enableSubmit = false;
  });

  builder.addCase(CHECKLIST_INPUTS, (state, action) => {
    const sectionIdx = state.form.sections.findIndex(
      (sec) => sec.id === action.payload.sectionId
    );
    let sectionInputs = [
      ...action.payload.inputs,
      ...state.form.sections[sectionIdx]?.inputs,
    ];
    sectionInputs = sectionInputs.filter(
      (item, index, array) =>
        index === array.findIndex((foundItem) => item.id === foundItem.id)
    );
    state.form.sections[sectionIdx].inputs = sectionInputs;
  });

  builder.addCase(SET_DEPENDENT_INPUT_OPTIONS, (state, action) => {
    const { dependentInput, sectionId, dependency, options } = action.payload;
    const indexes = [];
    const findInput = (sections, idxs, type) => {
      for (const [idx, sec] of sections.entries()) {
        if (sec.id === sectionId) {
          indexes.push(...idxs, type ? { type, idx } : idx);
        } else {
          if (sec.group?.length > 0)
            findInput(sec.group, [...idxs, idx], "group");
          if (sec.step?.length > 0)
            findInput(sec.group, [...idxs, idx], "step");
        }
      }
    };
    findInput(state.form.sections, []);
    let secRef = state.form.sections;
    indexes.forEach((index) => {
      secRef =
        typeof index === "number"
          ? secRef[index]
          : secRef[index.type][index.idx];
    });
    const inputIdx = secRef.inputs.findIndex(
      (input) => input.id === dependentInput.id
    );

    const checkExistance = (arr, ele) => arr.some((e) => e.id === ele.id);

    if (!checkExistance(secRef.inputs[inputIdx].dependency, dependency)) {
      secRef.inputs[inputIdx].dependency.push(dependency);
      secRef.inputs[inputIdx].options.push(...options);
    }
  });

  builder.addCase(ADD_TO_INITIAL_VALUES, (state, action) => {
    const { key, value } = action.payload;
    setValue(state.submissionValues, key, value);
  });

  builder.addCase(RESET_FORM, (state, action) => {
    Object.assign(state, initialState);
  });
});
export default reducer;
