import React, { useState, useEffect, useRef } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";
import { NavLink, useParams } from "react-router-dom";
import { setSidebarToggleMobile } from "../../reducers/theme/themeActions";
import { fetchForms } from "reducers/list";
import { useSelector } from "react-redux";
const {
  sideMenuItems,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/sideMenuItems`);
const IconComponent = (props) => {
  return (
    <>
      <props.menuItem.Icon className="svg-icons" />
    </>
  );
};

const SidebarMenuItem = (props) => {
  const { menuItem, index, setSidebarToggleMobile, sidebarToggle } = props;
  const [headerOpen, setHeaderOpen] = useState({
    dashboardOpen: false,
    projectOpen: false,
    programOpen: false,
    InitiativesOpen: false,
  });

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const toggleHeader = (event, toggledItem) => {
    headerOpen[toggledItem]
      ? setHeaderOpen({ ...headerOpen, [toggledItem]: false })
      : setHeaderOpen({ ...headerOpen, [toggledItem]: true });

    event.preventDefault();
  };
  return (
    <li key={menuItem.main} className="px-4 py-2">
      <span
        className="font-weight-bold font-size-xs sidebar-item-label"
        style={{ color: "black" }}
      >
        {menuItem.main}
      </span>

      {menuItem.links?.map((link) => (
        <NavLink
          key={link.label}
          activeClassName={`${
            index == 1
              ? "active"
              : clsx({ active: headerOpen[link.toggledItem] })
          }`}
          onClick={toggleSidebarMobile}
          className="nav-link-simple"
          to={link?.url || "/"}
        >
          <span className="sidebar-icon">
            <IconComponent menuItem={link} />
          </span>

          <span className="sidebar-item-label mr-2 font-size-sm">
            {link.label}
          </span>
        </NavLink>
      ))}
    </li>
  );
};

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarToggle, getForms, forms, user } =
    props;

  const [menuItems, setMenuItems] = useState({});
  const [url, setUrl] = useState(false);
  const getPropsFromStore = (state, param) => {
    return (
      state.layout?.columns?.Admin?.map
      ((entity) => Object.entries(entity)?.flatMap
      (entity => entity[1]?.[param])).flatMap
      (entity=>entity)
          )
  }
  
  const urls = useSelector((state) => getPropsFromStore(state,"url"))
  const labels = useSelector((state) => getPropsFromStore(state,"label"))
  const icons = useSelector(state => getPropsFromStore(state,"icon"))
  let userTypes = [];
  if (user && Object.keys(user).length) {
    userTypes = user.roles.map((role) => role.name);
  }
  // useEffect(() => {
    //   getForms({ model: "Form", }).catch((err) => {});
    // }, []);
    useEffect(() => {
      setMenuItems(sideMenuItems(labels,urls,icons));
    }, [forms,url]);
    useEffect(() => {
      if(labels){
        setUrl (true) 
          }
  //     else if (menuItems){
  //   setUrl(false)
  //   setUrl(true)
  // }
  },[labels,menuItems]);
  let checkList = [];

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation ">
          <ul className="pr-0">
            {userTypes.map((userType) => {
              return menuItems[userType]?.map((menuItem, i) => {
                if (!checkList.includes(menuItem.label)) {
                  checkList.push(menuItem.main);
                  return (
                    <SidebarMenuItem
                      key={menuItem?.main}
                      menuItem={menuItem}
                      sidebarToggle={sidebarToggle}
                      // index={i}
                      setSidebarToggleMobile={setSidebarToggleMobile}
                    />
                  );
                }
              });
            })}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  forms: state.formList.forms,
  sidebarToggleMobile: state.theme.sidebarToggleMobile,
  user: state.auth.user,
  sidebarToggle: state.theme.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  getForms: (params) => dispatch(fetchForms(params)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
