export const LAYOUT_STRUCTURE = {
  Columns: [
    {
      name: "ID",
      key: "file_code",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },
    {
      name: "Diagnosis",
      key: "diagnosis.name",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },
    {
      name: "Drug",
      key: "drug.name",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },
    {
      name: "Service",
      key: "service.name",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },
    {
      name: "Sub service",
      key: "subService.name",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },

    {
      name: "Application date",
      key: "submittedAt",
      itype: "date",
      inputLayout: { type: "date" },
      filter: true,
      sorting: true,
    },
    {
      name: "Status",
      key: "status",
      itype: "string",
      inputLayout: { type: "text" },
      filter: true,
      sorting: true,
    },
  ],
};
