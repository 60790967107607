import React from "react";
import "./index.css";
import { StepContent, StepLabel, Step, Stepper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import PropTypes from "prop-types";

const RenderStepper = ({
  steps,
  activeStep,
  handelActiveStep,
  subActiveStep,
}) => {
  const matches = useMediaQuery("(max-width:600px)");
  function StepIcon(props) {
    const { active, completed } = props;

    return (
      <div
        className={clsx(
          "d-40 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded-circle",
          {
            "d-40 bg-color-primary text-white shadow-primary-sm":
              active || completed,
          }
        )}
      >
        {String(props.icon)}
      </div>
    );
  }

  StepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };
  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className={`stepper ${matches && "d-none"} stepper`}
    >
      {steps?.map((step, stepIndex) => (
        <Step key={step.name} classes={{ root: "step" }}>
          <StepLabel
            StepIconComponent={StepIcon}
            StepIconProps={{
              classes: { root: "icon" },
            }}
            onClick={() => {
              handelActiveStep(stepIndex, 0, false);
            }}
          >
            <span className="color-primary">{step.name}</span>
          </StepLabel>

          <StepContent>
            <Stepper
              className="stepper p-0"
              activeStep={subActiveStep}
              connector={<></>}
              orientation="vertical"
            >
              {/* FIXME: change step "sub step" name*/}
              {step?.step?.map((childStep, childStepIndex) => {
                return (
                  <Step key={childStep.name}>
                    <StepLabel
                      icon={<div></div>}
                      classes={{
                        active: "active-step",
                        completed: "active-step",
                      }}
                      onClick={() => {
                        handelActiveStep(stepIndex, childStepIndex, true);
                      }}
                    >
                      {childStep.name}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export { RenderStepper };
