import axios from 'axios';

import { apiConfig } from '../utilities';
import { reqInterceptor, resInterceptor } from 'api/interceptors/generalIntercptors';

const client = axios.create({
  baseURL: apiConfig.baseUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
});
// Intercept all requests
client.interceptors.request.use(reqInterceptor.resolve, reqInterceptor.reject);
// Intercept all responses
client.interceptors.response.use(resInterceptor.resolve, resInterceptor.reject);

export default client;
