import React from "react";
import { convertDigitIn } from "../utils/utils";

const Footer = ({ org }) => {
  return (
    <div id="div_footer">
      <hr className="acceptance_hr" />
      <div className="general_div  containing_div">
        <div>
          <ul className="footer_ul">
            <li>{org.Email} : البريد الالكتروني</li>
          </ul>
        </div>
        <div>
          <ul className="footer_ul">
            <li>العنوان : {org.Address}</li>
            <li>التليفون : {convertDigitIn(org.Phone)}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
