import React from "react";

const Header = ({ UHIA_Logo, user }) => {
  return (
    <>
      <div className="general_div containing_div">
        <div className="general_div vertical_div">
          <span className="span_general span_english">
            Universal Health Insurance Authority
          </span>
          <span className="span_general span_english">{user.org.name}</span>
          <span className="span_general span_english">
          { user.org.id === 1? "Central Administration of Beneficiary" : "Administration of Beneficiary" }
          </span>
          <span className="span_general span_english">Department</span>
          <span className="span_general span_english">
            Pre-approval Section
          </span>
        </div>

        <div className="general_div vertical_div">
          <img src={UHIA_Logo} id="header_img" alt="UHIA_Logo" />
        </div>

        <div className="general_div vertical_div arabic_div">
          <span className="span_general span_arabic">
            الهيئة العامة للتأمين الصحي الشامل
          </span>
          <span className="span_general span_arabic">
            {user.org.ArabicName}
          </span>
          <span className="span_general span_arabic">
            { user.org.id === 1? "الإدارة المركزية لشئون المستفيدين": "الإدارة الفرعية لشئون المستفيدين"}
          </span>
          <span className="span_general span_arabic">
            إدارة الموافقات المسبقة
          </span>
        </div>
      </div>

      <hr className="acceptance_hr" />
    </>
  );
};

export default Header;
