import client from "api/apiAuth/guestClient";

export const fetchEmailInfo = async function (taskId, transitionId) {
  const { data } = await client.get("workflow/emailResponseView", {
    params: {
      taskId,
      transitionId,
    },
  });
  return data;
};

export const submitTask = async function (
  taskId,
  transitionId,
  token,
  comment,
  formId,
  submissionEdit
) {
  return await client.post("workflow/submitTaskEmail", {
    taskId,
    transitionId,
    token,
    comment,
    formId,
    submissionEdit,
  });
};
