import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import SpinnerLoader from "components/SpinnerLoader";
import { getFormLayout, setFormName } from "reducers/form/formActions";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import FormSteps from "components/FormSteps";
import { getModificationSchema } from "utils/setValidationSchema";
const Modifications = (props) => {
  let { getLayout, form_layout_loading, form } = props;
  const [modificationId, setModificationId] = useState(1);
  const [allModification, setAllModification] = useState([]);
  const [validationSchema, setValidationSchema] = useState(null);
  const [submissionId, setSubmissionId] = useState(null);
  const [hasForm, setHasForm] = useState(false);
  let history = createBrowserHistory({ forceRefresh: true });
  const usetyles = makeStyles({
    list: {
      overflowY: "auto",
      margin: 0,
      padding: 0,
      listStyle: "none",
      height: "100%",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D5DBE0",
        borderRadius: 5,
      },
    },
  });
  const classes = usetyles();

  const getModificationForm = async () => {
    if (!allModification.length) return;
    const target_modification = allModification.find(
      (mod) => mod.id == modificationId
    );
    if (target_modification.form_id) {
      setHasForm(true);
      getLayout({ formId: target_modification.form_id });
    }
  };

  const getAllModifications = async () => {
    const { data } = await client.get("modification/getAllModifications");
    setAllModification(data.AllModifications);
  };

  const submitModification = async () => {
    const response = await client.post("workflow/modifySubmission", {
      submission_id: submissionId,
      modification_id: modificationId,
      data: {},
    });
    Swal.fire({
      title: "Your modification has been sent successfully!",
      html: "",
      confirmButtonColor: "#B68537",
      confirmButtonText: "Ok",
    }).then(() => history.push("/"));
  };

  useEffect(() => {
    getAllModifications();
  }, []);

  useEffect(() => {
    getModificationForm();
  }, [allModification, modificationId]);
  useEffect(() => {
    if (form) {
      setValidationSchema(getModificationSchema(form, false));
    }
  }, [form]);
  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "20px 20px 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
      }}
    >
      <CardContent className="pb-1 font-weight-bold">
        <h6 className="mb-4 ml-3 mt-3">
          <b>Modifications:</b>
        </h6>
        <Grid
          container
          style={{ justifyContent: "space-around" }}
          className="ml-3 mb-3"
        >
          <Grid item md={5}>
            <TextField
              fullWidth
              required
              id="outlined-required"
              label="Submission_id"
              variant="outlined"
              onChange={(e) => {
                setSubmissionId(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={5} className="ml-3 mb-3">
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel>Modifications</InputLabel>
              <Select
                native
                value={modificationId}
                onChange={(e) => {
                  setModificationId(e.target.value);
                }}
                label="Modifications"
                // inputProps={{
                //   name: "age",
                //   id: "outlined-age-native-simple",
                // }}
              >
                <option aria-label="None" value="" />
                {allModification.map((modification) => {
                  return (
                    <option value={modification.id} key={modification.id}>
                      {modification.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        {allModification?.find((mod) => mod.id == modificationId)?.form_id && (
          <Grid container>
            {form_layout_loading ? (
              <SpinnerLoader />
            ) : (
              <FormSteps
                form={form.sections}
                validationSchema={validationSchema}
                initialValues={{}}
                config={{
                  showDraftButton: false,
                  modification: { modificationId, submissionId },
                }}
                history={history}
              ></FormSteps>
            )}
          </Grid>
        )}
        {!hasForm && (
          <Button
            variant="contained"
            color="primary"
            className="m-5"
            onClick={() => {
              Swal.fire({
                title: "Are you sure you want to submit your application? ",
                showConfirmButton: true,
                titleHeight: 200,
                showCancelButton: true,
                confirmButtonClass: "submit",
                cancelButtonClass: "cancel",
                titleClass: "fontSize",
                customClass: "swal-wide",
                confirmButtonText: "Submit",
                cancelButtonText: "Cancel",
                closeOnConfirm: false,
              }).then(function (isConfirm) {
                submitModification();
              });
            }}
          >
            Submit
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    allNotifications: state.socket.messages,
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modifications);
