import { applyDetailsConstraints } from "utils/LayoutConstraints/ConstraintsService";

export const applyConstraintsOnActions = (attribute, row) => {
  if (attribute.constraints) {
    return {
      ...attribute,
      constraints: applyDetailsConstraints(attribute.constraints(row)),
    };
  }
  return {
    ...attribute,
    constraints: {
      display: true,
      disable: false,
    },
  };
};
