import { Grid } from "@material-ui/core";
import React from "react";
import { getValue } from "utils/GetObjectValues";

function setInputKey(input, element) {
  const inputKey = input.key;
  return typeof element != "undefined"
    ? inputKey.replaceAll("[i]", `[${element}]`)
    : input.key;
}
function setInputKeyWithChild(input, parentIndex, childIndex) {
  const inputKey = input.key;
  let replaced;
  if (typeof parentIndex != "undefined" && typeof childIndex != "undefined") {
    replaced = inputKey.replace("[i]", `[${parentIndex}]`);
    return replaced.replaceAll("[i]", `[${childIndex}]`);
  } else {
    return input.key;
  }
}
function Attributes({ attribute, task, groupIndex, group, childIndex }) {
  let value;
  let newKeys;
  if (group) {
    if (childIndex) {
      newKeys = setInputKeyWithChild(attribute, groupIndex, childIndex);
    } else {
      newKeys = setInputKey(attribute, groupIndex);
    }
  } else {
    newKeys = attribute.key;
  }
  value = getValue(task, newKeys, attribute.type);
  return (
    <>
      {value !== undefined && (
        <div>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            spacing={2}
            columns={12}
          >
            <Grid item xs={attribute.style.gridSize} className="title-label">
              {attribute.label}
            </Grid>
            <Grid item xs={6} className="data">
              {attribute.type === "html" ? (
                <div
                  dangerouslySetInnerHTML={{ __html: value }}
                  className="bg-light"
                />
              ) : (
                value
              )}
            </Grid>
            <br />
          </Grid>
        </div>
      )}
    </>
  );
}

export default Attributes;
