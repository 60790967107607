import React, { useState, useEffect } from "react";
import { Grid, StylesProvider, jssPreset, Card } from "@material-ui/core";
import "./style.css";
import { create } from "jss";
import rtl from "jss-rtl";
import { connect } from "react-redux";
import FormSteps from "components/FormSteps";
import getSchema from "utils/setValidationSchema";

import {
  getDraft,
  getFormLayout,
  setFormName,
  handleDraftAssignment,
  getSubmission,
} from "reducers/form/formActions";
import { useLocation } from "react-router-dom";
import SpinnerLoader from "components/SpinnerLoader";
import client from "api/apiAuth/guestClient";
import { Alert } from "@material-ui/lab";
import { excuteOnInitInitialValuesEvents } from "components/FormSteps/utils";
import _ from "lodash";
const {
  config: pageConfig,
} = require(`../../weflow_configs/${process.env.REACT_APP_PROJECT_NAME}/FormStepsConfig`);

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function CreateSubmission(props) {
  const {
    getLayout,
    getDraft,
    getSubmission,
    form,
    match,
    history,
    form_layout_loading,
    setFormName,
    draftValues,
    initialValueInputEvents,
    submissionValues,
  } = props;
  const [validationSchema, setValidationSchema] = useState({});
  const [draftValidationSchema, setDraftvalidationSchema] = useState({});
  // state of confirm modal
  const { formId, id, submissionId } = match.params;
  const [initialValues, setInitialValues] = useState({});
  const [remainingTime, setRemainingTime] = useState();
  const [manyEntities, setManyEntities] = useState({});
  const [isRenew, setIsRenew] = useState(false);

  const { search } = useLocation();
  const sub_id =
    search.includes("submission_id") && search?.split("?")[1]?.split("=")[1];

  const getRemainingTime = async () => {
    let { data } = await client.get(`/submission/getRemainingTime`, {
      params: { sub_id, formId },
    });
    setRemainingTime(data.remaining_time);
  };
  useEffect(() => {
    if (sub_id) {
      getRemainingTime();
    }
    setInitialValues({});
    setFormName("");
  }, []);

  useEffect(() => {
    if (form) {
      setValidationSchema(getSchema(false));
      setDraftvalidationSchema(getSchema(true));
    }
  }, [form]);

  useEffect(() => {
    if (id)
      if (match.path === "/programs/:formId/renew/:id") {
        setIsRenew(true);
        getSubmission({ formId, submissionId: id });
      } else getDraft({ id, formId });
  }, [id, match]);

  useEffect(() => {
    if (!_.isEmpty(initialValueInputEvents))
      if (!_.isEmpty(draftValues?.submission))
        excuteOnInitInitialValuesEvents(draftValues, initialValueInputEvents);
      else if (!_.isEmpty(submissionValues?.submission))
        excuteOnInitInitialValuesEvents(
          submissionValues,
          initialValueInputEvents
        );
  }, [draftValues, initialValueInputEvents, submissionValues]);

  useEffect(() => {
    if (submissionId) {
      getLayout({ formId, isEdit: true });
      getSubmission({ submissionId, formId });
    } else {
      getLayout({ formId });
    }
  }, [submissionId]);

  return (
    <StylesProvider jss={jss}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} className="form-container ">
          {remainingTime ? (
            <Alert style={{ marginBottom: 5 }} severity="warning">
              The remaining time is {remainingTime}
            </Alert>
          ) : null}
          <Card className="card-box form-container">
            <div className="card-body p-0 ">
              {form_layout_loading ? (
                <SpinnerLoader />
              ) : (
                <>
                  <FormSteps
                    form={form.sections}
                    formId={formId}
                    id={id}
                    isRenew={isRenew}
                    validationSchema={validationSchema}
                    draftValidationSchema={draftValidationSchema}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    submissionId={submissionId}
                    history={history}
                    config={pageConfig}
                    manyEntities={manyEntities}
                    setManyEntities={setManyEntities}
                  ></FormSteps>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </StylesProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    submissionValues: state.form.submissionValues,
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
    user: state.auth.user,
    users: state.details.users,
    initialValueInputEvents: state.form.initialValueInputEvents,
    draftValues: state.form.draftValues,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
    getDraft: (params) => dispatch(getDraft(params)),
    getSubmission: (params) => dispatch(getSubmission(params)),
    setFormName: (name) => dispatch(setFormName(name)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params)),
    getOldSubmission: (params) => dispatch(getSubmission(params, true)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSubmission);
