import EventsService from "utils/FormUtils/Events/EventsService";
import _ from "lodash";

export const excuteEvents = async (input, event, attributes, arrayIndexes) => {
  let result;
  for (const eventFunction of event.functions) {
    if (result) {
      result = await EventsService[eventFunction](
        { [eventFunction]: attributes[eventFunction], ...attributes },
        input,
        event,
        result,
        attributes.formik,
        arrayIndexes
      );
    } else {
      result = await EventsService[eventFunction](
        { [eventFunction]: attributes[eventFunction], ...attributes },
        input,
        event,
        {},
        attributes.formik,
        arrayIndexes
      );
    }
  }
  return result;
};
export const getInitialEvents = async (input) => {
  let event = input?.events?.find((event) => event.trigger === "onInit");
  if (event) {
    return await excuteEvents(input, event, event.attributes);
  }
};

export const getOnInitInitialValue = async (
  input,
  formik,
  value,
  arrayIndexes
) => {
  let event = input?.events?.find(
    (event) => event.trigger === "onInitInitialValues"
  );
  if (event) {
    event = { ...event, attributes: { ...event.attributes, value, formik } };
    return await excuteEvents(input, event, event.attributes, arrayIndexes);
  }
};

export const getLoadMoreEvents = async (input) => {
  let event = input?.events?.find((event) => event.trigger === "onLoadMore");
  if (event) {
    return await excuteEvents(input, event, event.attributes);
  }
};

export const getOnchangeEvents = async (input, formik, value, arrayIndexes) => {
  let events = input?.events?.filter((event) => event.trigger === "onChange");
  events = _.orderBy(events, ["ID"], ["asc"]);
  if (events.length > 0) {
    for (let event of events) {
      event = { ...event, attributes: { ...event.attributes, value, formik } };
      await excuteEvents(input, event, event.attributes, arrayIndexes);
    }
    // event.attributes.value = value;
  }
};
