import React from 'react'
import {
    Grid,
    Select,
    MenuItem,
    Input
  } from "@material-ui/core";
const EditInput = ({title,value, onChange, menuItems,name}) => {
  return (
    <>
    <Grid item sm={6}>
            {title}
          </Grid>
          <Grid item sm={6}>
            {menuItems ? 
             (<Select
              value={value || ""}
              onChange={onChange}
              style={{
                width: "100%",
                overflow: "hidden",
              }}
            >

            {menuItems.map((menuItem) => (
              <MenuItem key={menuItem.id} value={menuItem.name}>
                {menuItem.name}
                </MenuItem>
              ))}
            </Select>
             ): !title.includes("Password",0) ?(<Input 
                type='text'
                value={value || ""}
                onChange={onChange}
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
            /> ):
            (<Input 
              type='password'
              value={value || ""}
              onChange={onChange}
              autoComplete="off"
              name={name}
              style={{
                width: "100%",
                overflow: "hidden",
              }}
          /> )
            }
          </Grid>
          </>
  )
}

export default EditInput