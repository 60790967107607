import { Button, Grid } from "@material-ui/core";
import * as icons from "@material-ui/icons/index";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import StepInputsModal from "../StepInputsModal";
import _ from "lodash";
import { apiConfig } from "api/utilities";
const IconComponent = ({ section }) => {
  const Icon = icons[section.style.icon];
  return (
    <>
      <Icon className="svg-icons" />
    </>
  );
};

export default function StepInputs(props) {
  let { task, setStepInputFlag } = props;
  const [stepSection, setStepSection] = useState();
  const [Inputfalg, setInputfalg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const history = useHistory();

  const openStepInputs = (section) => {
    if (task?.step?.groups[0]?.rules[0]?.type === "table")
      history.push(`/tablerule/${task.submission.id}`);
    setStepSection(section);
    setOpenModal(true);
  };
  useEffect(() => {
    if (stepSection?.section) {
      let values = {};
      stepSection.section.inputs.forEach((input) => {
        if (input.itype === "document") {
          let attachment = task?.submission.attachments.find(
            (attachment) => attachment.document.key == input.key
          );
          values = {
            ...values,
            [input.key]: {
              ...attachment,
              fileObject: `${apiConfig}${
                attachment.avatarFd?.split("assets/")[1]
              }`,
            },
          };
        } else {
          values = {
            ...values,
            [input.key]: task?.submission.values[input.key],
          };
        }
      });
      setInitialValues(values);
    }
    if (stepSection?.inputs) {
      let values = {};
      stepSection.inputs.forEach((input) => {
        if (input.itype === "document") {
          let attachment = task?.submission.attachments.find(
            (attachment) => attachment.document.key == input.key
          );
          if (attachment) {
            values = {
              ...values,
              [input.key]: {
                ...attachment,
                fileObject: `${apiConfig.baseUrl}${
                  attachment?.avatarFd?.split("assets/")[1]
                }`,
              },
            };
          }
        } else {
          values = {
            ...values,
            [input.key]: task?.submission.values[input.key],
          };
        }
      });
      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );
      setInitialValues(values);
    }
  }, [stepSection]);
  useEffect(() => {
    if (Inputfalg) {
      setStepInputFlag(true);
    }else{
      setStepInputFlag(false);

    }
  }, [Inputfalg]);
  return (
    <>
      {task?.step?.sections?.map(
        (section,index) =>
          !_.isEmpty(section) && (
            <Grid
              item
              md={2}
              key={index}
              style={{
                paddingTop: "15px",
                marginBottom: "10px",
              }}
            >
              <Button
                variant="contained"
                className="stepButton"
                style={{ boxShadow: "none" }}
                onClick={() => openStepInputs(section)}
              >
                <span style={{ margin: "10px" }}>
                  {"style" in section && <IconComponent section={section} />}
                  <span>{section?.name}</span>
                </span>
              </Button>
            </Grid>
          )
      )}
      {stepSection && (
        <StepInputsModal
          open={openModal}
          setOpen={setOpenModal}
          section={stepSection}
          submission={task?.submission}
          initialValues={initialValues || {}}
          task={task}
          setInputfalg={setInputfalg}
        />
      )}
    </>
  );
}
