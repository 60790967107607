import Comments from "pages/EdaDetails/components/Comments";
import DetailsTabs from "pages/EdaDetails/components/DetailsTabs";
import OldSubmissions from "pages/EdaDetails/components/oldSubmissions";
import SubmissionInfo from "pages/EdaDetails/components/SubmissionInfo";
import TaskTimeLine from "pages/EdaDetails/components/TaskTimeLine";

const detailsConfig = (mapper) => [
  {
    component: SubmissionInfo,
    componentProps: [
      "task",
      "setModalInfo",
      "openModal",
      "setConfirmationModal",
      "setSlotsModal",
      "takeActionFlag",
      "setTakeActionFlag",
    ],
    name: "basic information",
    style: {
      cardGridSize: 12,
      attributes: { GridSize: 6 },
      actions: { GridSize: 6 },
      classes: "",
    },
  },
  {
    component: Comments,
    componentProps: [],
    name: "Comments",
    style: {
      cardGridSize: 12,
      attributes: { GridSize: 6 },
      actions: { GridSize: 6 },
      classes: "",
    },
  },
  {
    component: OldSubmissions,
    componentProps: ["task"],
    title: "Old Submission",
    style: {
      cardGridSize: 12,
      attributes: { GridSize: 6 },
      actions: { GridSize: 6 },
      classes: "edaDetails",
    },
    constraints: {
      display: {
        conditions: [
          {
            operator: "isExist",
            functionName: "applyMathOperators",
            actualValue: mapper.task.submission.oldSubmissionId,
          },
        ],
        conditionsEquation: "$0",
      },
    },
  },
  {
    component: DetailsTabs,
    componentProps: ["task", "setTakeActionFlag", "takeActionFlag"],
    title: "Details Tabs",
    style: {
      cardGridSize: 12,
      attributes: { GridSize: 6 },
      actions: { GridSize: 6 },
      classes: "edaDetails",
    },
  },
  {
    component: TaskTimeLine,
    componentProps: ["submission_id"],
    title: "Timeline",
    constraints: {
      display: {
        conditions: [
          {
            key: "name",
            checkValue: "Distributor",
            operator: "some",
            functionName: "applyArrayMethods",
            actualValue: mapper.currentUser.roles,
          },
          {
            key: "name",
            checkValue: "Inspector",
            operator: "some",
            functionName: "applyArrayMethods",
            actualValue: mapper.currentUser.roles,
          },
          {
            key: "name",
            checkValue: "Inspection Manager",
            operator: "some",
            functionName: "applyArrayMethods",
            actualValue: mapper.currentUser.roles,
          },
        ],
        conditionsEquation: "!$0 && !$1 && !$2",
      },
    },
  },
];
export { detailsConfig as Config };
