import {
  SEND,
  RECEIVE,
  NOTIFICATION_MASSEGE,
  GET_NOTIFICATIONS_FULFILLED,
} from "./actionTypes";
import _ from "lodash";
const initialState = {
  messages: [],
};

export default function socketReducer(state = initialState, action) {
  switch (action.type) {
    case SEND: {
      return {
        ...state,
        isSending: true,
      };
    }
    case RECEIVE: {
      return {
        ...state,
        isRecieved: true,
      };
    }
    case NOTIFICATION_MASSEGE: {
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    }
    case GET_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        messages: _.orderBy(
          action.payload.data.rows.flatMap((messages) => messages),
          ["id"],
          ["desc"]
        ),
      };
    }
    default: {
      return state;
    }
  }
}
