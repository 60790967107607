import React, { useState } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import BlockUi from "react-block-ui";
import { SyncLoader } from "react-spinners";
import Swal from "sweetalert2";
import { handleTaskAssignment } from "reducers/details/detailsAction";
import { connect } from "react-redux";

export const AssignForm = ({
  users,
  selectedUser,
  setSelectedUser,
  reAssignFlag,
}) => {
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  return (
    <Grid item md={6}>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel id="demo-simple-select-outlined-label">
          {reAssignFlag ? "Reassign" : "Assign"}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedUser}
          onChange={handleChange}
          className="mt-0"
          label={reAssignFlag ? "Reassign" : "Assign"}
        >
          {users?.map((user) => (
            <MenuItem value={user?.id} key={user.id}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const AssignModal = (props) => {
  const {
    openModal,
    setOpenModal,
    users,
    task,
    handleTaskAssignment,
    getTaskDetails,
    getTimeline,
    loader,
  } = props;
  const [selectedUser, setSelectedUser] = useState("");
  const handleAssign = () => {
    return handleTaskAssignment({
      filter: {
        step_id: task?.step.id,
        submission_id: task?.submission.id,
      },
      user_id: selectedUser,
      submission_id: task?.submission.id,
    }).then(() => {
      Swal.fire({
        title: "Done",
        text: "The task was assigned successfully",
        icon: "success",
        timer: 3000,
      }).then(() => {
        getTaskDetails();
        getTimeline();
      });
    });
  };

  return (
    <Grid item sm={6} container>
      <Dialog
        classes={{ paper: "modal-content" }}
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="form-dialog-title2"
        style={{
          borderRadius: "6px",
          width: "60%",
          margin: "auto",
          height: "120%",
        }}
      >
        <DialogTitle>
          <span className="titleModal">
            {task.is_assigned ? "Reassign" : "Assign"}
          </span>
        </DialogTitle>
        <DialogContent className="p-4">
          {/* <BlockUi
            className="block-loading-overlay-light"
            tag="div"
            blocking={loader}
            loader={<SyncLoader color="#4f55a28f" loading={loader} />}
          > */}
          <AssignForm
            users={users}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            reAssignFlag={task.is_assigned}
          />
          {/* </BlockUi> */}
        </DialogContent>
        <DialogActions className="p-3">
          <Button
            className="assignButton pt-1 pb-1 pl-2 pl-2 pr-2"
            onClick={handleAssign}
            autoFocus
            size="small"
            disabled={selectedUser ? false : true}
          >
            {task?.is_assigned ? "Reassign" : "Assign"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const Assign = (props) => {
  const {
    action,
    users,
    task,
    handleTaskAssignment,
    getTaskDetails,
    getTimeline,
    loader,
    success,
  } = props;
  const [openModal, setOpenModal] = useState(false);

  const OpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Grid
        item
        sm={action.gridSize}
        xs={12}
        container
        spacing={2}
        align="left"
        className="mt-1"
      >
        <AssignModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          users={users}
          task={task}
          handleTaskAssignment={handleTaskAssignment}
          getTaskDetails={getTaskDetails}
          getTimeline={getTimeline}
          loader={loader}
        />
        <Grid item sm={6}>
          <Button
            className="assignButton btn-transition-none mb-4  btn-lg md-4 float-left"
            variant="contained"
            onClick={OpenModal}
          >
            {task.is_assigned ? "Reassign" : "Assign"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    users: state.details.users,
    loader: state.details.assignmentLoader,
    success: state.details.assignmentSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assign);
