import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";
import clsx from "clsx";
import { Toolbar, Typography, Grid } from "@material-ui/core";
import { handleDraftAssignment } from "reducers/form/formActions";
import { getUsers } from "reducers/details/detailsAction";
import { connect } from "react-redux";
import { handleTaskAssignment } from "../../reducers/details/detailsAction";
import TakeAction from "./TakeAction";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
function TableToolbar(props) {
  const classes = useToolbarStyles();
  const { selectedRows, setSelectedRows } = props;
  let numSelected = selectedRows.length;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                You choose {numSelected}
              </Typography>
            </Grid>
            <TakeAction
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              getDataCb={props.getDataCb}
            />
          </Grid>
        </>
      )}
    </Toolbar>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.details.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(getUsers(params)),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableToolbar);
