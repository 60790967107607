import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Signatures from "./components/Signatures";
import Table from "./components/Table";
import Title from "./components/Title";
import { getTodayDate, getUserSignatures } from "./utils/utils";

const AcceptanceLetter = ({
  letter,
  values,
  formValues,
  setValues,
  setDivRef,
  user,
}) => {
  const [todayDate, setTodayDate] = useState();
  const [approvalMembers, setApprovalMembers] = useState([]);
  const [passedToCommittee, setPassedToCommittee] = useState(false);
  const { timelineTasks } = useSelector((state) => state.details);
  const { user: currentUser } = useSelector((state) => state.auth);

  // const timelineApprovals = timelineTasks.slice(1, timelineTasks.length);

  useEffect(() => {
    (async () => {
      const date = await getTodayDate();
      setTodayDate(date);
    })();

    setApprovalMembers(getUserSignatures(timelineTasks, currentUser));
  }, []);

  useEffect(() => {
    setValues((values) => ({
      ...values,
      decision: letter.oldSubmissionId
        ? "استكمال وصرف علاج "
        : "تم الموافقة على طلبك",
    }));
  }, [letter.oldSubmissionId]);

  useEffect(() => {
    setPassedToCommittee(timelineTasks.some((t) => t.step.id === 10));
  }, [timelineTasks]);

  // pass everything under div with id acceptance to divRefs check TransitionForm.js to understand how it works and focus on meta
  useEffect(() => {
    const AcceptanceLetter = document.getElementById("acceptance");
    setDivRef((divRefs) => ({ ...divRefs, AcceptanceLetter }));
  }, [values, formValues]);

  // style and logo were placed into the public folder and used by URL here to send less data size to the backend
  const style = `${process.env.REACT_APP_CLIENT_URL}/css/certificates/acceptance_letter_of_the_UHIA/index.css`;
  const UHIA_Logo = `${process.env.REACT_APP_CLIENT_URL}/imgs/certificates/acceptance_letter_of_the_UHIA/uhia_logo.png`;

  return (
    <div id="acceptance">
      <link href={style} rel="stylesheet" />
      <Header UHIA_Logo={UHIA_Logo} user={user} />
      <Title letter={letter} todayDate={todayDate} />

      <Table
        setValues={setValues}
        values={values}
        letter={letter}
        formValues={formValues}
        passedToCommittee={passedToCommittee}
      />
      <Signatures approvalMembers={approvalMembers} currentUser={currentUser} user={user} />
      <Footer org={currentUser.org} />
    </div>
  );
};

export default AcceptanceLetter;
