import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useState } from "react";

export default function SubmissionType({
  handleTypeChange,
  submissionType,
  data,
  formik,
}) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-outlined-label">
          Allowed Types
        </InputLabel>

        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={submissionType ? submissionType : data.values[0].type}
          label="Allowed Types"
          name="type"
          onChange={(e) => {
            formik.handleChange(e);
            handleTypeChange(e.target.value);
          }}
        >
          {data?.values?.map((value) => (
            <MenuItem value={value.type}>{value.type}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
