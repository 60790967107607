import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  InputLabel,
  Input,
  Select,
  Button,
} from "@material-ui/core";
import SimpleAccordion from "./accordion";
import _ from "lodash";
import SettingsIcon from "@material-ui/icons/Settings";
import * as Yup from "yup";
import { useFormik, FormikProvider } from "formik";
import Components from "./Components";

import client from "api/apiAuth/guestClient";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { createBrowserHistory } from "history";
import { getSettingValidationSchema } from "utils/setValidationSchema";
const SysSettings = ({ match }) => {
  const [validationSchema, setValidationSchema] = useState(null);
  const [groups, setGroups] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [allWorkFlowsSettings, setAllWorkFlowsSettings] = useState({});
  let formik;
  formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues,
  });
  let history = createBrowserHistory({ forceRefresh: true });
  const usetyles = makeStyles({
    list: {
      overflowY: "auto",
      margin: 0,
      padding: 0,
      listStyle: "none",
      height: "100%",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D5DBE0",
        borderRadius: 5,
      },
    },
  });
  const classes = usetyles();
  //   const submitModification = async () => {
  //     const response = await client.post("workflow/modifySubmission", {
  //       submission_id: submissionId,
  //       modification_id: modificationId,
  //       data: {},
  //     });
  //     Swal.fire({
  //       title: "Your modification has been sent successfully!",
  //       html: "",
  //       confirmButtonColor: "#B68537",
  //       confirmButtonText: "Ok",
  //     }).then(() => history.push("/"));
  //   };

  const getSettingInputs = async () => {
    const response = await client.get("settings/getSysSettingInputs");
    setGroups(response.data.groups);
  };
  const getSettingWorkFlows = async () => {
    const path = match.path;
    const type = path.split("/")[2];
    const response = await client.get("settings/getSysSettingWorkFlows", {
      params: {
        type,
      },
    });
    setAllWorkFlowsSettings(response.data.allWorkFlows);
    // setGroups(response.data.groups);
  };

  useEffect(() => {
    getSettingWorkFlows();
    // getSettingInputs();
  }, []);

  useEffect(() => {
    if (groups) {
      const inputs = groups.flatMap((group) => group.inputs);
      setValidationSchema(getSettingValidationSchema(inputs, false));
    }
  }, [groups]);

  const submitSettings = async () => {
    const res = await formik.validateForm();

    if (!_.isEmpty(res) || _.isEmpty(formik.values)) {
      Swal.fire({
        title: "fields are either required or invalid",
        icon: "error",
        confirmButtonColor: "#d36467",
        confirmButtonText: "Try Again",
        width: "50%",
      });
    } else {
      // handleSubmit();
      client
        .post("/settings/submitSettings", {
          values: formik.values,
        })
        .then(() => {
          Swal.fire({
            title: "Your Settings has been saved successfully!",
            imageWidth: 250,
            imageHeight: 200,
            imageAlt: "Custom image",
            confirmButtonColor: "#B68537",
            confirmButtonText: "Ok",
          }).then(() => history.push("/"));
        });
    }
  };

  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "20px 20px 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
      }}
    >
      <div
        style={{
          padding: "10px 30px",
          backgroundColor: "#171c30",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>SETTINGS</h3>
        <div style={{ color: "white" }}>
          <SettingsIcon style={{ width: 45, height: 45 }} />
        </div>
      </div>
      {Object.keys(allWorkFlowsSettings).map((workFlow) => {
        return (
          <SimpleAccordion
            title={workFlow}
            workflow={allWorkFlowsSettings[workFlow]}
          />
        );
      })}
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SysSettings);
