import React, { useEffect, useState } from "react";
import "./index.scss";
import UHIA_Logo from "assets/images/Asset_8.png";
import { fetchEmailInfo, submitTask } from "./api";
import { useCallback } from "react";
import { useRef } from "react";
import { determineDisplayingForSections } from "components/FormSteps/utils";
import { Button, FormControl, Grid, TextareaAutosize } from "@material-ui/core";
import { GetStepContent } from "components/FormSteps/getStepContent";
import { useFormik } from "formik";
import { setSchema } from "utils/setValidationSchema";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";

function Index({ match, submission }) {
  const { transitionId, taskId, token } = match.params;
  const [data, setData] = useState({});
  const [comment, setComment] = useState("");
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(false);
  const [taskSubmitted, setTaskSubmitted] = useState(false);

  const displaySectionObj = useRef(null);

  const getEmailInfo = useCallback(async (taskId, transitionId) => {
    const res = await fetchEmailInfo(taskId, transitionId);
    setData(res);
    res.status === "Completed" && setTaskSubmitted(true);
  }, []);

  const handleSubmitTask = useCallback(
    async (taskId, data, comment, values) => {
      setLoading(true);
      try {
        const res = await submitTask(
          taskId,
          data.transition.id,
          token,
          comment,
          data.submission.form_id,
          values
        );
        setLoading(false);
        if (res.status === 200) setTaskSubmitted(true);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (taskId && transitionId) getEmailInfo(taskId, transitionId);
  }, [taskId, transitionId, getEmailInfo]);

  useEffect(() => {
    if (data.transition?.sections) {
      displaySectionObj.current = determineDisplayingForSections(
        data.transition.sections
      );
      setValidationSchema(
        setSchema(
          data.transition.sections.map((section) => section.inputs).flat()
        )
      );
    }
  }, [data?.transition]);

  const formik = useFormik({
    validationSchema: Yup.object().shape(validationSchema),
    validateOnChange: true,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues: {},
  });

  return (
    <section>
      <Grid container className="container-fluid h-custom">
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="row d-flex h-120 padding-custom pt-3"
        >
          <Grid item md={6} sm={12} className="px-5">
            <img src={UHIA_Logo} className="img-fluid" alt="uhia" />
          </Grid>
          <Grid item md={5} sm={12} className="pt-3">
            {taskSubmitted ? (
              <p className="lead fw-normal me-3">
                Your request has successfully submitted
              </p>
            ) : (
              <>
                <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                  <p className="lead fw-normal me-3">
                    You are about to submit a '{data.transition?.name}' reply
                    for Request
                    <strong>#{data.submission?.file_code}</strong> for patient
                    &nbsp;
                    <strong>{data.submission?.patient.name}</strong>. To
                    confirm, click on Confirm button.
                  </p>
                </div>

                <br />
                <div className="form-outline">
                  {data.transition?.sections?.length &&
                    displaySectionObj.current && (
                      <>
                        <Grid
                          container
                          style={{
                            justifyContent: "flex-end !important",
                            alignItems: "left",
                          }}
                          spacing={2}
                        >
                          <Grid item md={12}>
                            {data.transition?.sections.map((section, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {!section.hidden &&
                                    GetStepContent({
                                      formik,
                                      activeStep: 0,
                                      steps: [section],
                                      submission,
                                      displaySectionObj:
                                        displaySectionObj.current,
                                    })}
                                </React.Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </>
                    )}

                  <span className="heading-4 d-block  comment">
                    Write Your Comment
                  </span>
                  <FormControl>
                    <TextareaAutosize
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      minRows={"5"}
                      cols={"125"}
                      className="txt-area"
                    />
                  </FormControl>
                </div>

                <div className="text-center text-lg-start mt-3 pt-2">
                  <Button
                    className="btn btn-lg"
                    color={"primary"}
                    variant="contained"
                    style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                    disabled={
                      !data.transition ||
                      (data.transition?.sections?.some((section) =>
                        section?.inputs.some((e) => !e.disabled)
                      ) &&
                        data.transition?.sections?.some((section) =>
                          section?.inputs.some((e) => e.required)
                        ) &&
                        !(formik.dirty && formik.isValid))
                    }
                    onClick={() =>
                      handleSubmitTask(taskId, data, comment, formik.values)
                    }
                  >
                    <ClipLoader color={"var(--primary)"} loading={loading} />
                    Submit
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className="d-flex fixed-bottom flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 bg-primary">
        {/* <!-- Copyright --> */}
        <div className="text-white">
          <p>
            All rights reserved &nbsp;
            <a className="text-white" href="http://everestminds.com">
              Everest Minds
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Index;
