import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import client from "api/apiAuth/guestClient";
import SpinnerLoader from "components/SpinnerLoader";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";

const AllScientificCommittees = (props) => {
  let {
    layoutStructure,
    layoutData,
    filters,
    setFilters,
    currentTab,
    setSort,
    setPage,
    tasksCount,
    limit,
    loading,
    user,
    setSubmitTaskLoader,
    page,
    filterColumns,
    redirectKey,
  } = props;
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAllScientific = async () => {
    const { data } = await client.get("submission/getAllScientificCommittees");
    setTableData(data);
  };
  useEffect(() => {
    getAllScientific();
  }, []);

  const TableHeader = ["id"];
  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "0 0 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
      }}
    >
      <div className="worklist-table-container">
        {loading ? (
          <>
            <SpinnerLoader />
          </>
        ) : (
          <>
            {/* {selectedRows.length > 0 && (
          <TableToolbar
            selectedRows={selectedRows}
            history={history}
            setSubmitTaskLoader={setSubmitTaskLoader}
            currentTab={currentTab}
            user={user}
          />
        )} */}
            <Table className="table table-alternate-spaced text-nowrap mb-0 tabelStyle">
              <TableHead className="font-size-sm">
                <TableRow>
                  {TableHeader.map((cur) => {
                    return (
                      <TableCell className="text-center bg-color-secondary pt-2">
                        {cur}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {layoutData && layoutData.length > 0 ? (
                  layoutData.map((row, index) => (
                    <TableRow
                      key={index}
                      onClick={(e) => redirectInstance(row)}
                    >
                      {extractRowData(row, index)}
                    </TableRow>
                  ))
                ) : ( */}

                {tableData.allScientificCommittees?.length ? (
                  tableData.allScientificCommittees.map((sc) => {
                    return (
                      <TableRow
                        key={sc.id}
                        onClick={() =>
                          history.push(`scientificCommittee/${sc.id}`)
                        }
                      >
                        <TableCell className="text-center">
                          <p>{sc.id}</p>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan="9">
                      <h5 className="m-top">No results</h5>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </div>
      <div
        className="p-4 d-flex "
        style={{ justifyContent: "flex-end", borderRadius: "20px" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={10}
          page={1}
          onPageChange={handleChangePage}
          //   rowsPerPage={rowsPerPage}
          labelRowsPerPage={""}
          //   onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {

  return {
    loading: state.list.loading,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps, null)(AllScientificCommittees);
