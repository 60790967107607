import { Box, Card, Divider, Grid, Popover } from "@material-ui/core";
import React, { useState } from "react";
import "./index.scss";
import { connect } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";

function TaskTimeline({ part, timeline, partIndex }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Grid container spacing={0} className="my-5" key={partIndex}>
      <Grid item md={12}>
        <Card className="card-box">
          <Grid item sm={12}>
            <span className="tab-card-title float-left comments-title">
              {part.title}
            </span>
          </Grid>

          <Divider />
          <div className="timeline-list timeline-list-horizontal timeline-list--primary mt-1">
            <div className="scroll-area-sm d-flex">
              <div className="white-space"></div>
              <PerfectScrollbar
                options={{
                  suppressScrollY: true,
                  useBothWheelAxes: true,
                }}
                className="displayScroll"
              >
                <ul className="w-100 d-flex p-0">
                  {Array.isArray(timeline) &&
                    timeline?.map((task) => (
                      <li key={task.id} className="timeline-item  p-0">
                        <div className="timeline-item--content ">
                          <div>
                            <div className="timeline-item--icon" />
                            <div className="line bg-color-secondary"></div>
                            <span className="timeline-item--label  font-weight-bold  mt-3">
                              {task?.step?.name}
                            </span>
                            <span className="font-weight-bold color-gray font-size-xxs ">
                              {new Date(task.updatedAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </span>
                            <div className="">
                              <strong>{task.users[0]?.username} </strong>
                            </div>
                            <div className="color-secondary">
                              <strong>
                                {task.users[0]?.roles[0]?.department?.name}
                              </strong>
                            </div>
                            <div>
                              {task.logData && (
                                <>
                                  <p onClick={handleClickPopover}>
                                    {task.logData?.slice(0, 60) + "..."}
                                  </p>

                                  <Popover
                                    classes={{
                                      paper: "bg-white",
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClosePopover}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "bottom",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Box
                                      className="p-4"
                                      style={{ color: "#7a7b97" }}
                                    >
                                      {task.logData}
                                    </Box>
                                  </Popover>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </PerfectScrollbar>
              <div className="white-space"></div>
            </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    timeline: state.details.timelineTasks,
  };
};
export default connect(mapStateToProps)(TaskTimeline);
