import io from "socket.io-client";
// Example conf. You can move this to your config file.

export default class SocketClient {
  socket;

  constructor(user) {
    this.user = user;
  }
  connect(url) {
    this.socket = io.connect(process.env.REACT_APP_SERVER_URL || "/", {
      transports: ["websocket"],
      path: process.env.NODE_ENV === "development" ? "/socket.io" : "/socket",
    });
    return new Promise((resolve, reject) => {
      this.socket.on("connect", () => {
        if (this.user?.id) {
          return this.emit("join", this.user.id);
        }
        return resolve();
      });
      this.socket.on("connect_error", (error) => {
        console.log(error);
        return reject(error);
      });
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.disconnect(() => {
        this.socket = null;
        resolve();
      });
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        return reject("No socket connection.");
      }

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        return resolve();
      });
    });
  }

  on(event, fun) {
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject("No socket connection.");
      this.socket.on(event, fun);
      resolve();
    });
  }
}
