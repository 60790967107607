import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  TextField,
  Button,
  Table,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import client from "api/apiAuth/guestClient";
import { connect } from "react-redux";
import clsx from "clsx";
import Swal from "sweetalert2";
import TablePagination from "@material-ui/core/TablePagination";
import { createBrowserHistory } from "history";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: theme.spacing(1.2),
  },
  avatar: { marginRight: theme.spacing(5) },
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
}));
const Notifications = (props) => {
  let { allNotifications, user } = props;
  const [messages, setMessages] = useState([]);
  let history = createBrowserHistory({ forceRefresh: true });
  const redirectInstance = async (message) => {
    await client.patch(
      `generic/update?model=NotificationUsers&id=${message.id}`,
      {
        attributes: { read: 1 },
        where: {
          notification_id: message.id,
        },
      }
    );
    history.push(`/details/${message.submissionId}`);
  };
  const classes = useStyles();
  const [filters, setFilters] = useState([]);
  const [dateValues, setDateValues] = useState({
    from: "",
    to: "",
  });

  const itemsPerPage = 10;
  const [page, setPage] = React.useState(0);
  const [noOfPages, setNoOfPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const addFilterData = (field, event, value, operator) => {
    let filterObject = {
      field: field,
      value: value,
      operator: operator,
    };
    let newFilters = [...filters];
    let filterIndex = filters.findIndex((filter) => filter.field === field);
    if (filterIndex > -1) {
      newFilters[filterIndex] = filterObject;
    } else {
      newFilters.push(filterObject);
    }
    setFilters([...newFilters]);
  };
  let DatesValue = [];
  var obj = {};
  const handelDateChange = (field, event, Date, key) => {
    obj = { ...dateValues, [key]: Date };
    DatesValue = Object.values(obj);
    setDateValues(obj);
    addFilterData(field, event, DatesValue, "between");
  };
  useEffect(() => {
    setMessages(allNotifications);
    setNoOfPages(allNotifications.length);
  }, [allNotifications]);
  const clearAllNotification = async (event, allNotifications) => {
    Swal.fire({
      title: "Are you sure you want to clear all notifications? ",
      showConfirmButton: true,
      titleHeight: 200,
      showCancelButton: true,
      confirmButtonClass: "submit",
      cancelButtonClass: "cancel",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
    }).then(async function (isConfirm) {
      if (isConfirm.isConfirmed) {
        client
          .post("/notification/deleteNotificationsUser", allNotifications)
          .then((result) => {
            if (result.data === "success") {
              setMessages([]);
            }
          });
      }
    });
  };
  const applyFilters = (event) => {
    event.preventDefault();
    if (filters?.length > 0) {
      client
        .post("generic/find", {
          include: [
            {
              association: "users",
              where: {
                id: user.id,
              },
            },
          ],
          model: "Notification",

          where: {
            created_at: {
              operator: "between",
              value: filters[0].value,
            },
          },
        })
        .then((res) => {
          setMessages(res.data.rows);
          setNoOfPages(res.data.rows.length);
          setPage(0);
        });
    } else {
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Card
      className="card-box mb-spacing-6-x2"
      style={{
        borderRadius: "20px 20px 20px 20px",
        boxShadow: "none",
        border: "none",
        marginTop: "-4px",
      }}
    >
      <CardContent className="pb-1 font-weight-bold">
        <Grid
          item
          md={11}
          lg={6}
          xs={11}
          style={{ marginLeft: "10px", marginTop: "10px" }}
        >
          <div className={clsx("align-items-center ")}>
            <Paper
              style={{ boxShadow: "none " }}
              component="form"
              className="w-100"
            >
              <Grid container>
                <Grid item xs={3} md={3}>
                  <span className="spanStyle">From</span>
                  <TextField
                    id="date"
                    type="date"
                    defaultValue={new Date()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event, date) => {
                      event.persist();

                      handelDateChange(
                        "created_at",
                        event,
                        event.target.value,
                        "from"
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={3} md={3}>
                  <span className="spanStyle">To</span>
                  <TextField
                    id="date"
                    type="date"
                    defaultValue={new Date()}
                    disabled={dateValues.from ? false : true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: dateValues.from,
                    }}
                    onChange={(event, date) => {
                      event.persist();
                      let currentDate = new Date(event.target.value);
                      currentDate.setDate(currentDate.getDate() + 1);
                      handelDateChange(
                        "created_at",
                        event,
                        currentDate.toISOString(),
                        "to"
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={applyFilters}
                    autoFocus
                  >
                    Apply Filters
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
        {messages.length > 0 && (
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            className="ml-4 mx-4"
            style={{ backgroundColor: "#0b0b0c", float: "right" }}
            onClick={(e) => clearAllNotification(e, messages)}
          >
            Clear All
          </Button>
        )}
        <h6 className="mb-4 ml-3 mt-3">
          <b>Notifications:</b>
        </h6>
        <Grid container className="ml-3 mb-3">
          <Grid item md={10}>
            <List>
              {messages?.length > 0 ? (
                (rowsPerPage > 0
                  ? messages.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : messages
                ).map((notification, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      className="notificationItem"
                      button
                      onClick={(e) => redirectInstance(notification)}
                    >
                      <Grid item md={2}>
                        <ListItemText
                          primary={new Date(
                            notification.created_at
                          ).toLocaleString()}
                        />
                      </Grid>

                      <ListItemIcon
                        className={
                          notification?.users[0]?.notification.read
                            ? "read"
                            : "iconNotRead"
                        }
                      >
                        <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText primary={notification.message} />
                    </ListItem>
                    <Divider style={{ width: "95%", marginLeft: "10px" }} />
                  </React.Fragment>
                ))
              ) : (
                <ListItem className="notificationItem">
                  <ListItemText primary="No results" />
                </ListItem>
              )}
            </List>
            <Table>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage=""
                    count={noOfPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{
                      border: "none",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      alignContent: "stretch",
                    }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    allNotifications: state.socket.messages,
  };
};

export default connect(mapStateToProps, null)(Notifications);
