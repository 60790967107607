import { Dialog, DialogContent } from "@material-ui/core";

import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { connect } from "react-redux";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ file, modal: open, setModal: setOpen }) {
  const toggle = () => setOpen(!open);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    // <div>
    <Dialog
      fullWidth
      style={{ width: "100vw", height: "100vh", overflowY: "hidden" }}
      open={open}
      onClose={toggle}
    >
      <DialogContent
        style={{
          width: "100%",
          overflowX: "hidden",
          padding: "0",
          overflowY: "hidden",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
          <div style={{ height: "550px", width: "100%" }}>
            <Viewer
              withCredentials
              fileUrl={
                file && typeof file === "object"
                  ? URL.createObjectURL(file)
                  : file
              }
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      </DialogContent>
    </Dialog>

    // </div>
  );
}
const mapStatToProps = (state) => {
  return {
    attachments: state?.details?.task?.submission?.attachments,
  };
};
export default connect(mapStatToProps)(PdfViewer);
