export const GET_TASK_DETAILS = "GET_TASK_DETAILS";
export const GET_TASK_DETAILS_PENDING = "GET_TASK_DETAILS_PENDING";
export const GET_TASK_DETAILS_FULFILLED = "GET_TASK_DETAILS_FULFILLED";
export const GET_TASK_DETAILS_REJECTED = "GET_TASK_DETAILS_REJECTED";

export const GET_TIMELINE = "GET_TIMELINE";
export const GET_TIMELINE_PENDING = "GET_TIMELINE_PENDING";
export const GET_TIMELINE_FULFILLED = "GET_TIMELINE_FULFILLED";
export const GET_TIMELINE_REJECTED = "GET_TIMELINE_REJECTED";

export const ASSIGN_TASK = "ASSIGN_TASK";
export const ASSIGN_TASK_PENDING = "ASSIGN_TASK_PENDING";
export const ASSIGN_TASK_FULFILLED = "ASSIGN_TASK_FULFILLED";
export const ASSIGN_TASK_REJECTED = "ASSIGN_TASK_REJECTED";
export const SUBMIT_TASK = "SUBMIT_TASK";
export const SUBMIT_TASK_PENDING = "SUBMIT_TASK_PENDING";
export const SUBMIT_TASK_FULFILLED = "SUBMIT_TASK_FULFILLED";
export const SUBMIT_TASK_REJECTED = "SUBMIT_TASK_REJECTED";
export const SAVE_CARD_ATTACHMENTS = "SAVE_CARD_ATTACHMENTS";

export const GET_TASK_USERS = "GET_TASK_USERS";
export const GET_TASK_USERS_PENDING = "GET_TASK_USERS_PENDING";
export const GET_TASK_USERS_FULFILLED = "GET_TASK_USERS_FULFILLED";
export const GET_TASK_USERS_REJECTED = "GET_TASK_USERS_REJECTED";

export const HANDLE_TASK_ASSIGNMENT = "HANDLE_TASK_ASSIGNMENT";
export const HANDLE_TASK_ASSIGNMENT_PENDING = "HANDLE_TASK_ASSIGNMENT_PENDING";
export const HANDLE_TASK_ASSIGNMENT_FULFILLED =
  "HANDLE_TASK_ASSIGNMENT_FULFILLED";
export const HANDLE_TASK_ASSIGNMENT_REJECTED =
  "HANDLE_TASK_ASSIGNMENT_REJECTED";

export const SET_ALL_ATTACHMENTS = "SET_ALL_ATTACHMENTS";
export const SET_ATTACHMENT_REVIEWED = "SET_ATTACHMENT_REVIEWED";

export const GET_AVAILABLE_SLOTS = "GET_AVAILABLE_SLOTS";
export const GET_AVAILABLE_SLOTS_PENDING = "GET_AVAILABLE_SLOTS_PENDING";
export const GET_AVAILABLE_SLOTS_FULFILLED = "GET_AVAILABLE_SLOTS_FULFILLED";
export const GET_AVAILABLE_SLOTS_REJECTED = "GET_AVAILABLE_SLOTS_REJECTED";

export const RESERVE_SLOT = "RESERVE_SLOT";
export const RESERVE_SLOT_PENDING = "RESERVE_SLOT_PENDING";
export const RESERVE_SLOT_FULFILLED = "RESERVE_SLOT_FULFILLED";
export const RESERVE_SLOT_REJECTED = "RESERVE_SLOT_REJECTED";

export const SEND_REQUEST_TO_MANAGER = "SEND_REQUEST_TO_MANAGER";

export const UPLOAD_ATTACHMENTS = "UPLOAD_ATTACHMENTS";

export const SAVE_SUBMISSION_VALUES = "SAVE_SUBMISSION_VALUES";
export const SAVE_SUBMISSION_VALUES_PENDING = "SAVE_SUBMISSION_VALUES_PENDING";
export const SAVE_SUBMISSION_VALUES_FULFILLED =
  "SAVE_SUBMISSION_VALUES_FULFILLED";
export const SAVE_SUBMISSION_VALUES_REJECTED =
  "SAVE_SUBMISSION_VALUES_REJECTED";

export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const GET_ATTACHMENTS_PENDING = "GET_ATTACHMENTS_PENDING";
export const GET_ATTACHMENTS_FULFILLED = "GET_ATTACHMENTS_FULFILLED";
export const GET_ATTACHMENTS_REJECTED = "GET_ATTACHMENTS_REJECTED";

export const SAVE_NAMING = "SAVE_NAMING";
export const SAVE_NAMING_Pending = "SAVE_NAMING_Pending";
export const SAVE_NAMING_FULFILLED = "SAVE_NAMING_FULFILLED";
export const SAVE_NAMING_REJECTED = "SAVE_NAMING_REJECTED";
