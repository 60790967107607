// reducers
import themeReducer from "./theme";
import errorReducer from "./error";
// import listDataReducer from './list';
import { combineReducers } from "redux";
import listDataReducer from "./list/listDataReducer";
import listLayoutReducer from "./list/listLayoutReducer";
import filterDataReducer from "./list/filterDataReducer";
import listFormsReducer from "./list/ListFormsReducer";
import formDataReducer from "./form";
import authReducer from "./auth";
import detailsReducer from "./details";
import socketReducer from "./socket/socketReducer";
import settingsReducer from "./settings";
import listTimeSlotsReducer from "./list/listTimeSlotsReducer";
import DataReducer from "./list/DataReducer";
import generalReducer from "./general";
// import formListReducer from './formlist';
/*export default combineReducers({
  theme: themeReducer,
  list: listDataReducer,
  layout: listLayoutReducer,
  error: errorReducer,
  form: formDataReducer,
  auth: authReducer,
  formList: listFormsReducer,
  details: detailsReducer
});*/
// version: (state = version) => state,

const appReducer = combineReducers({
  theme: themeReducer,
  list: listDataReducer,
  layout: listLayoutReducer,
  error: errorReducer,
  form: formDataReducer,
  auth: authReducer,
  formList: listFormsReducer,
  details: detailsReducer,
  socket: socketReducer,
  filterData: filterDataReducer,
  settings: settingsReducer,
  timeSlotsSchedule: listTimeSlotsReducer,
  data: DataReducer,
  general:generalReducer
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return appReducer(
      { auth: { isLoggedin: false, loginChecked: true } },
      action
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
