import _ from "lodash";
import { getValue } from "utils/GetObjectValues";

const operatorsFunctions = {
  applyMathOperators: (actualValue, checkValue, operator, name) => {
    switch (operator) {
      case "equal":
        return checkValue === actualValue;
      case "notEqual":
        return checkValue !== actualValue;
      case "greaterThan":
        return checkValue > actualValue;
      case "greaterThanOrEq":
        return checkValue >= actualValue;
      case "lessThan":
        return checkValue < actualValue;
      case "lessThanOrEq":
        return checkValue <= actualValue;
      case "isExist":
        return !!actualValue;
      default:
        break;
    }
  },
  applyObjectMethods: (actualValue, checkValue, operator) => {
    switch (operator) {
      case "find":
        return checkValue in actualValue;
      case "checkEmpty":
        return _.isEmpty(actualValue);
      default:
        break;
    }
  },
  applyArrayMethods: (actualValue, checkValue, operator, key) => {
    switch (operator) {
      case "find":
        return (
          actualValue &&
          !!actualValue.find((element) => element[key] === checkValue)
        );
      case "every":
        return (
          actualValue &&
          actualValue.every((element) => element[key] === checkValue)
        );
      case "some":
        return (
          actualValue &&
          actualValue.some((element) => element[key] === checkValue)
        );
      case "includes":
        return actualValue && actualValue.includes(checkValue);
      case "reverse includes":
        return checkValue && checkValue.includes(actualValue);
      case "compare2Arrays":
        return actualValue.some((element) =>
          checkValue?.includes(element[key])
        );
      case "everyObjectInArray":
        return actualValue.every((element) => {
          return getValue(element, key) in checkValue;
        });

      default:
        break;
    }
  },
};

const evalConditions = (conditions) =>
  conditions.map((condition) => {
    const { checkValue, actualValue } = condition;
    return operatorsFunctions[condition.functionName](
      actualValue,
      checkValue,
      condition.operator,
      condition.key
    );
  });

const applyEquation = (conditions, conditionsEquation) => {
  const regex = /[$][0-9]*/g;
  if (conditionsEquation) {
    const numParts = conditionsEquation.match(regex);
    let returnedEquation = conditionsEquation;

    numParts.forEach((element) => {
      returnedEquation = returnedEquation.replace(
        element,
        conditions[parseInt(element.replace("$", ""))]
      );
    });
    return returnedEquation;
  } else return;
};

export const applyDetailsConstraints = (constraints) =>
  Object.keys(constraints).reduce((obj, key) => {
    const conditions = evalConditions(constraints[key].conditions);
    const returnedEquation = applyEquation(
      conditions,
      constraints[key].conditionsEquation
    );
    return { ...obj, [key]: eval(returnedEquation) };
  }, {});

export const applyInputConstraints = (element, type) => {
  let returnedEquation;
  let result;
  const conditions = evalConditions(element.conditions);
  returnedEquation = applyEquation(conditions, element.conditionsEquation);
  result = eval(returnedEquation);

  // eslint-disable-next-line default-case
  switch (type) {
    case "validation":
      return result;
    case "layout":
      return element.constraints?.layout?.reduce(
        (acc, constraint) => ({ ...acc, [constraint]: result }),
        {}
      );
  }
};
