import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import "./index.scss";

const Info = ({ value, options, input }) => {
  const [selectedValue, setSelectedValue] = useState();

  const isObject = (variable) => {
    return (
      typeof variable === "object" &&
      !Array.isArray(variable) &&
      variable !== null
    );
  };
  useEffect(() => {
    let isArrayOfObject = options.every((option) => isObject(option));
    if (isArrayOfObject) {
      setSelectedValue(options.find((option) => option.id === value));
    } else {
      setSelectedValue(value);
    }
  }, [value, options]);

  return (
    <>
      {selectedValue &&
        (input.input_layout?.info
          ? input.input_layout?.info.length > 0
          : true) && (
          <div variant="outlined" sx={{ flexGrow: 1 }} className="CardDetail">
            <span className="card-title">{input.name}</span>

            {isObject(selectedValue) ? (
              input.input_layout?.info?.map((prop) => (
                <>
                  {
                    <div key={prop.key}>
                      <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        spacing={2}
                        columns={12}
                      >
                        <Grid item xs={6} className="lebal">
                          {prop.label}
                        </Grid>
                        <Grid item xs={6} className="data">
                          {selectedValue[prop.key]}
                        </Grid>
                        <br />
                      </Grid>
                    </div>
                  }
                </>
              )) ||
              Object.entries(selectedValue).map(([key, value], index) => {
                return (
                  <>
                    {key !== "id" && (
                      <div key={index}>
                        <Grid
                          container
                          direction="row"
                          alignItems="flex-start"
                          spacing={2}
                          columns={12}
                        >
                          <Grid item xs={6} className="lebal">
                            {key}
                          </Grid>
                          <Grid item xs={6} className="data">
                            {value}
                          </Grid>
                          <br />
                        </Grid>
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <div>{selectedValue}</div>
            )}
          </div>
        )}
    </>
  );
};

export default Info;
